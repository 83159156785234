import React from 'react';

import ReadOnlyRating from '../../components/general/ReadOnlyRating';
import { truncate } from '../../helpers/utils';

function ProductReviews({ productReviews }) {
    return (
        <>
            {productReviews?.length > 0 && <h2 className='mb-4'>Product Reviews</h2>}
            <ul className='list-unstyled p-0'>
                {productReviews?.length > 0 &&
                    productReviews?.map((review, index) => {
                        return (
                            <li className='bg-gray-900 rounded p-3 mb-2' key={index}>
                                <div className='d-flex'>
                                    <div className='user-avatar-pic-outer'>
                                        <div
                                            className='user-avatar-pic'
                                            style={{ backgroundImage: `url(${review?.raterProfile})` }}
                                        ></div>
                                    </div>
                                    <div className='ms-2 text-start'>
                                        <h6 className='mb-0'>{review?.raterName || 'App Visitor'}</h6>
                                        <p className='mb-0 small text-muted fw-normal'>
                                            {truncate(review?.raterAddress, 20)}
                                        </p>
                                        <div className='mb-3'>
                                            <ReadOnlyRating rating={review?.rate} />
                                        </div>
                                        <p className='text-gray-500 mb-0'>{review?.rateMsg}</p>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
            </ul>
        </>
    );
}

export default ProductReviews;
