import React, { useMemo } from 'react';
import useShop from '../../hooks/useShop';
import ShopCard from '../../components/general/ShopCard';

function TopRatedShops() {
    const { allShops } = useShop();

    const trustedShops = useMemo(() => {
        return allShops?.filter((shop) => shop?.isVerified)?.slice(0, 6);
    }, [allShops]);

    return (
        <>
            {trustedShops && trustedShops?.length > 0 && (
                <section className='pb-5'>
                    <div className='container pb-5'>
                        <header className='text-center mb-5'>
                            <div className='row'>
                                <div className='col-lg-6 mx-auto'>
                                    <h2 className='text-xl' data-aos='fade-up'>
                                        Our Trusted Shops
                                    </h2>
                                    <p className='text-muted' data-aos='fade-up' data-aos-delay='100'>
                                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero quod repellat
                                        quas ducimus voluptatem
                                    </p>
                                </div>
                            </div>
                        </header>

                        <div className='row justify-content-center g-3'>
                            {trustedShops?.map((shop, index) => {
                                return (
                                    <div
                                        className='col-xxl-3 col-xl-4 col-lg-6'
                                        data-aos='fade-up'
                                        data-aos-delay={index * 50}
                                        key={index}
                                    >
                                        <ShopCard {...shop} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default TopRatedShops;
