import React, { useMemo, useState } from 'react';

// COMPOENENTS
import ShopBlock from '../../components/general/ShopBlock';
import { appSettings } from '../../helpers/settings';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper';
import { HiShoppingBag } from 'react-icons/hi';
import Viewer from 'react-viewer';
import useApp from '../../hooks/useApp';
import ReadOnlyRating from '../../components/general/ReadOnlyRating';
import { Link } from 'react-router-dom';

function HeroSlide({
    productName,
    productCategory,
    productDescription,
    productShopId,
    productSlug,
    productGallery,
    productPrice,
    shippingPrice,
    productRating,
}) {
    const { themeMode } = useApp();

    const viewerImgs = useMemo(() => {
        return productGallery?.map((img, i) => ({ src: img, id: i }));
    }, [productGallery]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    return (
        <>
            <div className='hero-slide pt-5 overflow-hidden'>
                <div className='hero-slide-bg' style={{ backgroundImage: `url(${productGallery[0]})` }}></div>
                <div className='container z-index-20 pt-5 mt-5'>
                    <div className='row gy-5 align-items-center'>
                        <div className='col-lg-6 order-2 order-lg-1'>
                            <ul className='list-inline' data-aos='fade-right'>
                                <li className='list-inline-item'>
                                    <div className='badge bg-primary fw-normal rounded-0'>{productCategory}</div>
                                </li>
                            </ul>
                            <h2 className='h1 text-xxl text-shadow' data-aos='fade-right' data-aos-delay='100'>
                                {productName}
                            </h2>

                            <div className='mb-4'>
                                {' '}
                                <ReadOnlyRating rating={productRating} />
                            </div>

                            <p className={`${themeMode === 'dark' ? 'text-gray-500' : 'text-dark'} fw-light mb-4`}>
                                {productDescription}
                            </p>
                            <ul className='list-inline mb-4'>
                                <li className='list-inline-item me-4'>
                                    <p className='mb-0 text-muted h6 text-sm text-uppercase'>Product Price</p>
                                    <p className='h1 mb-0'>
                                        {productPrice}{' '}
                                        <span className='text-sm fw-normal text-primary'>{appSettings.currency}</span>
                                    </p>
                                </li>
                                <li className='list-inline-item'>
                                    <p className='mb-0 text-muted h6 text-sm text-uppercase'>Shipping Price</p>
                                    <p className='h1 mb-0'>
                                        {shippingPrice}{' '}
                                        <span className='text-sm fw-normal text-primary'>{appSettings.currency}</span>
                                    </p>
                                </li>
                            </ul>
                            <div className='mb-2'>
                                <Link className='btn btn-primary px-4' to={`/products/${productSlug}`}>
                                    <HiShoppingBag className='mb-1 me-2' size={20} />
                                    Purchase Now
                                </Link>
                            </div>
                            <div data-aos='fade-right' data-aos-delay='400' className='mb-4'>
                                <div className='d-inline-block bg-opac-secondary rounded p-3 mt-4'>
                                    <ShopBlock shopId={productShopId} productRating={productRating} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 ms-auto order-1 order-lg-2'>
                            <div className='px-lg-5 hero-gallery' data-aos='fade-left'>
                                <Swiper spaceBetween={0} slidesPerView={1} modules={[Navigation, Thumbs]} navigation>
                                    {productGallery?.map((img, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <div
                                                    className='product-gallery-slide cursor-pointer'
                                                    key={index}
                                                    style={{ background: `url(${img})` }}
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                        setCurrentIndex(index);
                                                    }}
                                                ></div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isOpen && (
                <Viewer
                    visible={isOpen}
                    activeIndex={currentIndex}
                    drag={false}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    images={viewerImgs}
                />
            )}
        </>
    );
}

export default HeroSlide;
