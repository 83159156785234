import React from 'react';

const OrderContext = React.createContext({
    orderContract: null,
    orderStatsContract: null,
    orderContractAbi: null,
    orderStatsContractAbi: null,
    fetchingAllOrders: false,
    allOrders: [],
    allDisputes: [],
    loadOrderContract: () => {},
    loadStatsOrderContract: () => {},
    loadAllOrders: () => {},
    loadAllDisputes: () => {},
    getOrderContractAbi: () => {},
    getOrderStatsContractAbi: () => {},
});

export default OrderContext;
