import React, { useEffect } from 'react';
import { appSettings } from '../../helpers/settings';

// HOOKS
import useShop from '../../hooks/useShop';

// COMPONENTS
import HeroSlider from './HeroSlider';
import NewArrivals from './NewArrivals';
import TopRatedProducts from './TopRatedProducts';
import TrustedShops from './TrustedShops';

function HomePage() {
    const { allProducts } = useShop();

    useEffect(() => {
        document.title = `${appSettings.brandName} | ${appSettings.brandDescription}`;
    }, []);

    return (
        <>
            <section>
                <HeroSlider data={allProducts} />
            </section>

            <NewArrivals />

            <TrustedShops />

            <TopRatedProducts />
        </>
    );
}

export default HomePage;
