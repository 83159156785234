import React, { useMemo } from 'react';
import PageBanner from '../../components/general/PageBanner';
import { Link, useParams } from 'react-router-dom';
import CreateProductForm from './CreateProductForm';
import useShop from '../../hooks/useShop';
import NotFound from '../../components/NotFound';
import useWeb3 from '../../hooks/useWeb3';

function CreateProductPage() {
    const { slug } = useParams();
    const { account } = useWeb3();
    const { allShops } = useShop();
    const targetShop = useMemo(() => {
        return allShops?.filter((shop) => shop?.shopSlug === slug)[0];
    }, [slug, allShops]);

    const isShopOwner = useMemo(() => {
        return Boolean(account === targetShop?.shopOwner);
    }, [account, targetShop]);

    return isShopOwner ? (
        <>
            {targetShop ? (
                <>
                    <PageBanner
                        heading='Create a New Product'
                        text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium eaque eveniet'
                    />

                    <section className='pb-5'>
                        <div className='container pb-5'>
                            <div className='row'>
                                <div className='col-lg-8 mx-auto'>
                                    <div className='card'>
                                        <div className='card-body p-lg-5'>
                                            <CreateProductForm targetShop={targetShop?.shopId} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            ) : (
                <NotFound
                    customMsg={
                        <>
                            <h1 data-aos='fade-up' data-aos-delay='100' className='text-xl'>
                                Hmmmm, The shop not found.
                            </h1>
                            <p className='text-muted lead' data-aos='fade-up' data-aos-delay='200'>
                                You're trying to upload to a shop that doesn't exist.
                            </p>
                            <Link className='btn btn-primary py-2' to='/account'>
                                Return to Account Page
                            </Link>
                        </>
                    }
                />
            )}
        </>
    ) : (
        <NotFound />
    );
}

export default CreateProductPage;
