import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import FetchingDataLoader from '../../components/general/FetchingDataLoader';
import NotFound from '../../components/NotFound';
import useShop from '../../hooks/useShop';
import ShopBanner from './ShopBanner';
import ShopProducts from './ShopProducts';

function ShopSinglePage() {
    const { allShops, allProducts, fetchingAllShops } = useShop();
    const { slug } = useParams();

    const currentShop = useMemo(() => {
        return allShops?.filter((shop) => shop?.shopSlug === slug)[0];
    }, [slug, allShops]);

    const currentShopId = useMemo(() => {
        return allShops?.filter((shop) => shop?.shopSlug === slug)[0]?.shopId;
    }, [slug, allShops]);

    const shopProducts = useMemo(() => {
        return allProducts?.filter((product) => product?.productShopId === currentShopId);
    }, [allProducts, currentShopId]);

    return (
        <>
            {fetchingAllShops ? (
                <FetchingDataLoader />
            ) : currentShop ? (
                <>
                    <ShopBanner {...currentShop} />
                    {shopProducts && <ShopProducts allProducts={shopProducts} />}
                </>
            ) : (
                <NotFound />
            )}
        </>
    );
}

export default ShopSinglePage;
