import React from 'react';

const AppContext = React.createContext({
    contract: null,
    owner: '',
    themeMode: 'light',
    activities: [],
    mintUploadProgress: 0,
    transactionLoading: false,
    uploadingProgress: false,
    abi: null,
    getContractAbi: () => {},
    loadContract: () => {},
    loadAppOwner: () => {},
    setTransactionLoading: () => {},
    loadMintUploadProgress: () => {},
    setUploadingProgress: () => {},
    loadActivities: () => {},
    switchMode: () => {},
});

export default AppContext;
