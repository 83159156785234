import React from 'react';
import { HiUsers } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

// HOOKS
import useUser from '../../hooks/useUser';
import useShop from '../../hooks/useShop';
import ReadOnlyRating from '../../components/general/ReadOnlyRating';
import { appSettings } from '../../helpers/settings';

function AllUsersTable() {
    const { usersList } = useUser();
    const { allShops } = useShop();

    /* -------------------------------------------------- 
          GET THE CONNECTED USER SHOPS FROM ALL SHOPS
    --------------------------------------------------- */
    function getUserShops(address) {
        if (address) {
            return allShops?.filter((shop) => shop?.shopOwner === address)?.length;
        }
    }

    /*** ------------------------------------------------ */
    //      ALL PENDING VIDEOS TABLE COLUMNS
    /*** ------------------------------------------------ */
    const columns = [
        {
            name: 'Seller',
            minWidth: '300px',
            selector: (row) => row?.address,
            cell: (row) => (
                <div row={row}>
                    <Link to={`/users/${row?.user_slug}`} className='text-reset'>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundImage: `url(${row?.profile_picture})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {row?.full_name}
                                </h6>
                                <p className='text-muted small mb-0'>{row?.email_address}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: 'Phone Number',
            minWidth: '200px',
            selector: (row) => row?.phone_number,
            cell: (row) => (
                <div row={row}>
                    <span className='text-sm'>{row?.phone_number}</span>
                </div>
            ),
        },
        {
            name: 'Rating',
            minWidth: '300px',
            selector: (row) => row?.rating,
            cell: (row) => (
                <div row={row}>
                    <ReadOnlyRating rating={row?.rating} />
                </div>
            ),
        },
        {
            name: 'Shops',
            minWidth: '80px',
            selector: (row) => row?.address,
            cell: (row) => <div row={row}>{getUserShops(row?.address)}</div>,
        },
        {
            name: 'Profits',
            minWidth: '120px',
            selector: (row) => row?.profits,
            cell: (row) => (
                <div row={row}>
                    {row?.profits} {appSettings.currency}
                </div>
            ),
        },
        {
            name: 'Location',
            minWidth: '200px',
            selector: (row) => row?.location,
            cell: (row) => <div row={row}>{row?.location}</div>,
        },
    ];

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex mb-5'>
                    <div className='stats-icon solid-green'>
                        <HiUsers size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>All Sellers</h2>
                        <p className='text-muted fw-normal mb-0'>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                        </p>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={usersList?.filter((user) => user?.is_vendor)}
                    pagination={usersList?.filter((user) => user?.is_vendor).length >= 1 && true}
                    responsive
                    theme='solarized'
                />
            </div>
        </div>
    );
}

export default AllUsersTable;
