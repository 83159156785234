import React from 'react';
import PageBanner from '../../components/general/PageBanner';
import CreateShopForm from './CreateShopForm';

function CreateShopPage() {
    return (
        <>
            <PageBanner
                heading='Create a New Shop'
                text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium eaque eveniet'
            />

            <section className='pb-5'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-8 mx-auto'>
                            <div className='card'>
                                <div className='card-body p-lg-5'>
                                    <CreateShopForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CreateShopPage;
