import React, { useMemo } from 'react';
import useShop from '../../hooks/useShop';
import ProductCard from '../../components/general/ProductCard';

function NewArrivals() {
    const { allProducts } = useShop();

    const newArrivals = useMemo(() => {
        return allProducts?.sort((a, b) => b.productId - a.productId)?.slice(0, 6);
    }, [allProducts]);

    return (
        <>
            {newArrivals && newArrivals?.length > 0 && (
                <section className='py-5'>
                    <div className='container py-5'>
                        <header className='text-center mb-5'>
                            <div className='row'>
                                <div className='col-lg-6 mx-auto'>
                                    <h2 className='text-xl' data-aos='fade-up'>
                                        New Arrivals
                                    </h2>
                                    <p className='text-muted' data-aos='fade-up' data-aos-delay='100'>
                                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero quod repellat
                                        quas ducimus voluptatem
                                    </p>
                                </div>
                            </div>
                        </header>

                        <div className='row justify-content-center g-3'>
                            {newArrivals?.map((product, index) => {
                                return (
                                    <div
                                        className='col-xxl-3 col-xl-4 col-lg-6'
                                        data-aos='fade-up'
                                        data-aos-delay={index * 50}
                                        key={index}
                                    >
                                        <ProductCard {...product} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default NewArrivals;
