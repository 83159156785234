import React, { useMemo } from 'react';
import { RiShoppingBagFill } from 'react-icons/ri';
import { BsFillCircleFill } from 'react-icons/bs';
import { formatDate, truncateStart } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

// HOOKS
import useOrder from '../../hooks/useOrder';
import useUser from '../../hooks/useUser';
import { appSettings } from '../../helpers/settings';
import useShop from '../../hooks/useShop';

function UserDisputes() {
    const { allDisputes } = useOrder();
    const { allProducts } = useShop();
    const { userInfo } = useUser();

    const userDisputes = useMemo(() => {
        return allDisputes?.filter((dispute) => dispute?.disputeOpenerAddress === userInfo?.address);
    }, [allDisputes, userInfo]);

    const disputesAgainstUser = useMemo(() => {
        return allDisputes.filter((dispute) => dispute?.disputeDefendantAddress === userInfo?.address);
    }, [allDisputes, userInfo]);

    function getDisputeProduct(id) {
        return allProducts?.filter((product) => product?.productId === id)[0];
    }

    /*** ------------------------------------------------ */
    //      ALL PENDING VIDEOS TABLE COLUMNS
    /*** ------------------------------------------------ */
    const columns = [
        {
            name: 'Product',
            selector: (row) => row?.productId,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <Link className='text-reset' to={`/products/${getDisputeProduct(row?.productId)?.productSlug}`}>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    background: `url(${getDisputeProduct(row?.productId)?.productGallery[0]})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1'>
                                    {truncateStart(getDisputeProduct(row?.productId)?.productName, 30)}
                                </h6>
                                <p className='text-muted small mb-0'>
                                    {getDisputeProduct(row?.productId)?.unitPrice} {appSettings.currency}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: 'Defendant User',
            selector: (row) => row?.disputeId,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <Link to={`/users/${row?.disputeDefendantSlug}`} className='text-reset'>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundImage: `url(${row?.disputeDefendantProfile})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {row?.disputeDefendantName}
                                </h6>
                                <p className='text-muted small mb-0'>{row?.disputeDefendantEmail}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: 'Complainant User',
            selector: (row) => row?.disputeId,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <Link to={`/users/${row?.disputeOpenerSlug}`} className='text-reset'>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundImage: `url(${row?.disputeOpenerProfile})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {row?.disputeOpenerName}
                                </h6>
                                <p className='text-muted small mb-0'>{row?.disputeOpenerEmail}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: 'Dispute Reason',
            selector: (row) => row?.disputeId,
            minWidth: '300px',
            cell: (row) => <div row={row}>{row?.disputeReason}</div>,
        },
        {
            name: 'Dispute Description',
            selector: (row) => row?.disputeId,
            minWidth: '300px',
            cell: (row) => <div row={row}>{truncateStart(row?.disputeDescription, 20)}</div>,
        },
        {
            name: 'Order Holding Amount',
            selector: (row) => row?.disputeId,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    {row?.pendingAmount} {appSettings.currency}
                </div>
            ),
        },
        {
            name: 'Created At',
            selector: (row) => row?.disputeId,
            minWidth: '300px',
            cell: (row) => <div row={row}>{formatDate(row?.createdAt)}</div>,
        },
        {
            name: 'Status',
            selector: (row) => row?.disputeId,
            minWidth: '200px',
            cell: (row) => (
                <div row={row}>
                    <span className={`order-status ${row?.disputeStatus === 'open' ? 'pending' : 'delivered'}`}>
                        <BsFillCircleFill className='me-2 mb-1' size={10} />
                        {row?.disputeStatus}
                    </span>
                </div>
            ),
        },
        {
            name: 'Resolving Comment',
            selector: (row) => row?.disputeId,
            minWidth: '200px',
            cell: (row) => (
                <div row={row}>{row?.disputeStatus === 'resolved' ? row?.resolvingReason : 'Not resolved yet'}</div>
            ),
        },
    ];

    return (
        <>
            <div className='card shadow-lg mb-4' data-aos='fade-up' data-aos-delay='200'>
                <div className='card-body p-lg-5'>
                    <div className='d-flex mb-5'>
                        <div className='stats-icon solid-cyan'>
                            <RiShoppingBagFill size='1.4rem' />
                        </div>
                        <div className='ms-3'>
                            <h2 className='mb-0 h4'>Disputes I've Opened</h2>
                            <p className='text-muted fw-normal mb-0'>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                            </p>
                        </div>
                    </div>

                    {userDisputes && userDisputes?.length > 0 ? (
                        <DataTable
                            columns={columns}
                            data={userDisputes}
                            pagination={userDisputes.length >= 1 && true}
                            responsive
                            theme='solarized'
                        />
                    ) : (
                        <p className='mb-0'>There're no disputes at the moment</p>
                    )}
                </div>
            </div>
            <div className='card shadow-lg mb-4' data-aos='fade-up' data-aos-delay='200'>
                <div className='card-body p-lg-5'>
                    <div className='d-flex mb-5'>
                        <div className='stats-icon solid-cyan'>
                            <RiShoppingBagFill size='1.4rem' />
                        </div>
                        <div className='ms-3'>
                            <h2 className='mb-0 h4'>Disputes Against Me</h2>
                            <p className='text-muted fw-normal mb-0'>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                            </p>
                        </div>
                    </div>

                    {disputesAgainstUser && disputesAgainstUser?.length > 0 ? (
                        <DataTable
                            columns={columns}
                            data={disputesAgainstUser}
                            pagination={disputesAgainstUser.length >= 1 && true}
                            responsive
                            theme='solarized'
                        />
                    ) : (
                        <p className='mb-0'>There're no disputes at the moment</p>
                    )}
                </div>
            </div>
        </>
    );
}

export default UserDisputes;
