import React, { useEffect, useState } from 'react';
import useShop from '../../hooks/useShop';
import useApp from '../../hooks/useApp';
import useWeb3 from '../../hooks/useWeb3';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { appSettings } from '../../helpers/settings';
import { useForm } from 'react-hook-form';
import { BsCreditCard2FrontFill } from 'react-icons/bs';
import BigNumber from 'bignumber.js';
import useOrder from '../../hooks/useOrder';
import useUser from '../../hooks/useUser';
import { useContractWrite } from 'wagmi';
import { waitForTransaction } from '@wagmi/core';
import TokenAbi from '../../integration/TokenAbi.json';

function MakeOrder({ productId, amount, shippingPrice, unitPrice, closeModal, refreshBuyers, resetQuantity }) {
    const { setTransactionLoading } = useApp();
    const { userContract, loadUserInfo, loadUsersList } = useUser();
    const { shopContract, shopContractAbi, paymentToken, loadAllProducts, loadAllShops } = useShop();
    const { orderStatsContract, loadAllOrders } = useOrder();
    const { account } = useWeb3();
    const [approvedTx, setApprovedTx] = useState(null);
    const [approveTxData, setApproveTxData] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
              MAKE ORDER FUNCTION
     --------------------------------------------- */
    const { write: web3MakeOrder } = useContractWrite({
        address: shopContract?.address,
        abi: shopContractAbi,
        functionName: 'makeOrder',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                toast.success('Great! Your order has been created');
                loadAllProducts(shopContract);
                loadAllShops(shopContract);
                loadAllOrders(orderStatsContract);
                loadUserInfo(shopContract, account);
                loadUsersList(userContract);
                closeModal(false);
                refreshBuyers();
                resetQuantity();
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
              APPROVE SENDING TOKEN
     --------------------------------------------- */
    const { write: web3ApproveTransfer, data: txData } = useContractWrite({
        address: paymentToken,
        abi: TokenAbi,
        functionName: 'approve',
        onSuccess() {
            setTransactionLoading(true);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    useEffect(() => {
        if (txData) {
            async function getTe() {
                const waitFrTx = await waitForTransaction({
                    hash: txData?.hash,
                });
                setApprovedTx(waitFrTx);
            }

            getTe();
        }
    }, [txData]);

    useEffect(() => {
        if (approvedTx) {
            web3MakeOrder({
                recklesslySetUnpreparedArgs: [...approveTxData],
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvedTx, approveTxData]);

    /* --------------------------------------------- 
          BUY PRODUCT FUNCTION
    --------------------------------------------- */
    async function handleMakeOrderForm(data) {
        const totalOrderPrice = new BigNumber(amount).times(new BigNumber(unitPrice));
        const totalWithShippingPrice = totalOrderPrice.plus(new BigNumber(shippingPrice));
        const formattedOrderPrice = Web3.utils.toWei(totalWithShippingPrice.toString(), 'ether');
        setApproveTxData([productId, amount, data?.shipping_address, data?.shipping_notes, formattedOrderPrice]);
        web3ApproveTransfer({
            recklesslySetUnpreparedArgs: [shopContract.address, formattedOrderPrice],
        });
    }

    return (
        <>
            <header className='text-center mb-4'>
                <h3>Make an Order</h3>
                <p className='text-muted'>
                    The total value for your order is{' '}
                    <strong className='fw-bold'>{amount * unitPrice + shippingPrice}</strong> {appSettings.currency}
                </p>
            </header>

            <form className='row g-3' onSubmit={handleSubmit(handleMakeOrderForm)}>
                <div className='col-12'>
                    <label className='form-label'>Shipping address</label>
                    <input
                        type='text'
                        placeholder='Add your shipping address'
                        name='shipping_address'
                        className={`form-control ${errors.shipping_address ? 'is-invalid' : ''}`}
                        {...register('shipping_address', {
                            required: {
                                value: true,
                                message: 'Please enter your shipping address',
                            },
                        })}
                    />
                    {errors.shipping_address && (
                        <span className='invalid-feedback'>{errors.shipping_address.message}</span>
                    )}
                </div>

                <div className='col-12'>
                    <label className='form-label'>Shipping notes</label>
                    <textarea
                        rows='8'
                        placeholder='If you have some notes, let the product owner knows'
                        name='shipping_notes'
                        className={`form-control ${errors.shipping_notes ? 'is-invalid' : ''}`}
                        {...register('shipping_notes', {
                            required: {
                                value: false,
                            },
                        })}
                    ></textarea>
                </div>

                <div className='col-12'>
                    <div className='form-check d-flex align-items-center'>
                        <input
                            id='agree_policy'
                            type='checkbox'
                            className='form-check-input'
                            name='agree_policy'
                            {...register('agree_policy', {
                                required: {
                                    value: true,
                                    message: 'Please accept the return policy terms',
                                },
                            })}
                        />

                        <label htmlFor='agree_policy' className='form-check-label ms-2'>
                            I've read and accept the return policy terms
                        </label>
                    </div>
                    {errors.agree_policy && <span className='invalid-feedback'>{errors.agree_policy.message}</span>}
                </div>

                <div className='col-12'>
                    <button className='btn btn-primary w-100' type='submit'>
                        <BsCreditCard2FrontFill className='me-2 mb-1' size={20} />
                        Complete your Order
                    </button>
                </div>
            </form>
        </>
    );
}

export default MakeOrder;
