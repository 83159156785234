import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { appSettings } from '../helpers/settings';
import { ToastContainer, Flip } from 'react-toastify';
import AOS from 'aos';

// 3RD-PARTY STYLES
import 'react-toastify/dist/ReactToastify.css';
import 'react-h5-audio-player/lib/styles.css';
import 'react-tippy/dist/tippy.css';
import 'react-range-slider-input/dist/style.css';

// HOOKS
import useWeb3 from '../hooks/useWeb3';
import useOrder from '../hooks/useOrder';

// CONTRACT ABIs
import MarketplaceAbi from '../contracts/Marketplace.json';
import TokenAbi from '../integration/TokenAbi.json';
import OrdersStatsAbi from '../contracts/OrdersStatistics.json';

// COMPONENTS
import Header from '../components/general/Header';
import Footer from '../components/general/Footer';
import ScrollToTop from '../components/general/ScrollToTop';
import MetaMaskLoader from '../components/general/MetaMaskLoader';
import useApp from '../hooks/useApp';
import useShop from '../hooks/useShop';
import useUser from '../hooks/useUser';
import RegisterPopup from '../components/general/RegisterPopup';
import VerifyPopup from '../components/general/EmailVerify';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig, useNetwork } from 'wagmi';
import { celoAlfajores } from 'wagmi/chains';
import { getAccount, watchAccount, getContract } from '@wagmi/core';
import ViewOnlyAlert from '../components/general/ViewOnlyAlert';

const chains = [celoAlfajores];
const projectId = appSettings.wcProjectId;

const { provider, webSocketProvider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, version: 1, chains }),
    provider,
    webSocketProvider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

function Layout() {
    const { account, loadAccount } = useWeb3();
    const { loadContract, contract, transactionLoading, loadActivities, loadAppOwner, getContractAbi, abi } = useApp();
    const {
        shopContract,
        paymentToken,
        loadShopContract,
        loadAllShops,
        loadAllProducts,
        loadUserShops,
        loadPaymentTokenAddress,
        loadPaymentTokenContract,
        loadPaymentTokenBalance,
        getShopContractAbi,
    } = useShop();
    const {
        orderStatsContract,
        orderStatsContractAbi,
        loadOrderContract,
        loadStatsOrderContract,
        loadAllOrders,
        loadAllDisputes,
        getOrderContractAbi,
        getOrderStatsContractAbi,
    } = useOrder();
    const {
        registerIndicator,
        userInfo,
        userContract,
        loadUserContract,
        loadUserInfo,
        loadUsersList,
        getUserContractAbi,
    } = useUser();
    const { chain } = useNetwork();

    /*** ------------------------------------------------ */
    //      LOAD INITIAL APP DATA
    /*** ------------------------------------------------ */
    useEffect(() => {
        if (orderStatsContract) {
            loadActivities(orderStatsContract, orderStatsContractAbi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatsContract]);

    useEffect(() => {
        if (contract) {
            loadAppOwner(contract);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract]);

    /* ---------------------------------------------
          LOAD USER INFORMATION
    --------------------------------------------- */
    useEffect(() => {
        if (userContract) {
            account && loadUserInfo(userContract, account);
            loadUsersList(userContract);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, userContract]);

    /* ---------------------------------------------
          LOAD SHOP INFORMATION
    --------------------------------------------- */
    useEffect(() => {
        if (shopContract) {
            loadAllShops(shopContract);
            loadAllProducts(shopContract);
            account && loadUserShops(shopContract, account);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, shopContract]);

    // /* ---------------------------------------------
    //       LOAD ORDER INFORMATION
    // --------------------------------------------- */
    useEffect(() => {
        if (orderStatsContract) {
            loadAllOrders(orderStatsContract);
            loadAllDisputes(orderStatsContract);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatsContract]);

    /*** -------------------------------------------- */
    //      GET PAYMENT TOKEN CONTRACT
    /*** -------------------------------------------- */
    useEffect(() => {
        if (paymentToken && account) {
            loadPaymentTokenContract(paymentToken, TokenAbi);
            loadPaymentTokenBalance(paymentToken, TokenAbi, account);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentToken, account]);

    /*** -------------------------------------------- */
    //      GET PAYMENT TOKEN ADDRESS
    /*** -------------------------------------------- */
    useEffect(() => {
        if (shopContract) {
            loadPaymentTokenAddress(shopContract);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopContract]);

    /* -------------------------------------------------- */
    //      AOS ANIMATION
    /* -------------------------------------------------- */
    useEffect(() => {
        AOS.init({
            duration: 700,
            once: true,
        });
    }, []);

    /*** -------------------------------------------- */
    //      GET BLOCKCHAIN DATA
    /*** -------------------------------------------- */
    useEffect(() => {
        const calclateInitialSettings = async () => {
            const acc = getAccount();
            loadAccount(acc?.address);
            getContractAbi(MarketplaceAbi.abi);
            getUserContractAbi(MarketplaceAbi.abi);
            getShopContractAbi(MarketplaceAbi.abi);
            getOrderContractAbi(MarketplaceAbi.abi);
            getOrderStatsContractAbi(OrdersStatsAbi.abi);

            const appDeployedNetwork = MarketplaceAbi.networks[appSettings?.networkId];
            const orderStatsDeployedNetwork = OrdersStatsAbi.networks[appSettings?.networkId];

            const mainContract =
                appDeployedNetwork &&
                getContract({
                    address: appDeployedNetwork.address,
                    abi: MarketplaceAbi.abi,
                });

            const statsContract =
                orderStatsDeployedNetwork &&
                getContract({
                    address: orderStatsDeployedNetwork.address,
                    abi: OrdersStatsAbi.abi,
                });

            loadUserContract(mainContract);
            loadShopContract(mainContract);
            loadContract(mainContract);
            loadOrderContract(mainContract);
            loadStatsOrderContract(statsContract);

            // eslint-disable-next-line no-unused-vars
            const unwatch = watchAccount((account) => {
                loadAccount(account?.address);
            });

            if (mainContract && abi) {
                loadAppOwner(mainContract);
            } else {
                return;
            }
        };
        calclateInitialSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <WagmiConfig client={wagmiClient}>
                <div className='app pb-0'>
                    {chain?.id !== appSettings.networkId && <ViewOnlyAlert />}
                    <Header />
                    <ScrollToTop />
                    <Outlet />
                    <Footer />
                </div>
                {userInfo?.full_name && !userInfo?.is_verified && <VerifyPopup />}
                <ToastContainer position='top-center' autoClose={1500} transition={Flip} />
                {registerIndicator && chain?.id === appSettings.networkId && <RegisterPopup />}
                {transactionLoading && <MetaMaskLoader />}
            </WagmiConfig>
            <Web3Modal
                projectId={projectId}
                ethereumClient={ethereumClient}
                themeVariables={{
                    '--w3m-z-index': '9999',
                    '--w3m-container-background-color': 'rgba(0,0,0,.7)',
                }}
            />
        </>
    );
}

export default Layout;
