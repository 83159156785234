import React, { useEffect, useMemo, useState } from 'react';
import { BiCategoryAlt } from 'react-icons/bi';
import { BsPlus } from 'react-icons/bs';
import { TbEdit } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { SiStarship } from 'react-icons/si';
import { HiMinusSm } from 'react-icons/hi';
import ReadOnlyRating from '../../components/general/ReadOnlyRating';
import ConnectWalletHandler from '../../components/general/ConnectWalletHandler';
import { appSettings } from '../../helpers/settings';
import ShopBlock from '../../components/general/ShopBlock';
import useWeb3 from '../../hooks/useWeb3';
import useUser from '../../hooks/useUser';
import MakeOrder from './MakeOrder';
import Popup from '../../components/general/Popup';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import useApp from '../../hooks/useApp';
import useShop from '../../hooks/useShop';

function ProductInfo({
    productName,
    productId,
    productShopId,
    productDescription,
    productCategory,
    productPrice,
    productRating,
    productAvailability,
    productOwner,
    productSlug,
    shippingPrice,
    refreshBuyers,
    isBuyer,
    setRatingRequested,
    isApproved,
    productReviews,
}) {
    const { account } = useWeb3();
    const { themeMode } = useApp();
    const { tokenBalance } = useShop();
    const [picked, setPicked] = useState(1);
    const { isRegistered, userInfo } = useUser();
    const [orderRequest, setOrderRequest] = useState(false);

    function handleIncreaseQuantity() {
        if (picked < Number(productAvailability)) {
            setPicked((prev) => prev + 1);
        }
    }
    function handleDeccreaseQuantity() {
        if (picked >= 2) {
            setPicked((prev) => prev - 1);
        }
    }
    const formattedTotalPrice = useMemo(() => {
        const totalOrderPrice = new BigNumber(picked).times(new BigNumber(Number(productPrice)));
        const totalWithShippingPrice = totalOrderPrice.plus(new BigNumber(shippingPrice));
        return Web3.utils.toWei(totalWithShippingPrice.toString(), 'ether');
    }, [picked, productPrice, shippingPrice]);

    const isRater = useMemo(() => {
        const matches = productReviews?.filter((rate) => rate?.raterAddress === account);
        return Boolean(matches?.length > 0);
    }, [account, productReviews]);

    useEffect(() => {
        if (account === productOwner) {
            setPicked(0);
        } else {
            setPicked(1);
        }
    }, [account, productOwner]);

    return (
        <>
            <p className='mb-2 d-flex align-items-center'>
                <BiCategoryAlt className='text-primary me-1' size={20} /> {productCategory}
                {Number(productAvailability) === 0 && <span className='badge bg-primary ms-3'>Sold Out</span>}
            </p>
            <h1 className='text-xl'>{productName}</h1>
            {!isApproved && (
                <div
                    className={`bg-danger mb-4 mt-2 px-3 py-2 rounded ${
                        themeMode === 'dark' ? 'text-white' : 'text-gray-100'
                    } small d-inline-block`}
                >
                    <strong className='fw-bold'>Pending! </strong> This product is waiting for admin approval
                </div>
            )}
            <div className='mb-4'>
                <ReadOnlyRating rating={productRating} />
            </div>
            <p className='text-gray-500 mb-4'>{productDescription}</p>
            <ul className='list-inline mb-4'>
                <li className='list-inline-item me-4'>
                    <p className='mb-0 text-muted h6 text-sm text-uppercase'>Product Price</p>
                    <p className='h1 mb-0'>
                        {productPrice} <span className='text-sm fw-normal text-primary'>{appSettings.currency}</span>
                    </p>
                </li>
                <li className='list-inline-item'>
                    <p className='mb-0 text-muted h6 text-sm text-uppercase'>Shipping Price</p>
                    <p className='h1 mb-0'>
                        {shippingPrice} <span className='text-sm fw-normal text-primary'>{appSettings.currency}</span>
                    </p>
                </li>
            </ul>
            <p className='mb-0 text-muted h6 text-sm text-uppercase'>Availability</p>
            {Number(productAvailability) > 0 ? (
                <p>
                    <span className='h4'>{productAvailability - picked} </span>
                    <span className='text-sm'>items in stock</span>
                </p>
            ) : (
                <p className='h4'>Sold out</p>
            )}
            {account === productOwner ? (
                <Link to={`/products/update/${productSlug}`} className='btn btn-primary px-4'>
                    <TbEdit className='me-1 mb-1' size={20} />
                    Edit Your Product
                </Link>
            ) : (
                <>
                    <div className='d-flex align-items-center flex-column flex-lg-row  mt-4'>
                        {Number(productAvailability) > 0 && (
                            <div className='quantity me-2'>
                                <button className='dec-btn' onClick={handleDeccreaseQuantity}>
                                    <HiMinusSm size={18} />
                                </button>
                                <div className='number h6 mb-0'>{picked}</div>
                                <button className='inc-btn' onClick={handleIncreaseQuantity}>
                                    <BsPlus size={18} />
                                </button>
                            </div>
                        )}
                        {isApproved && (
                            <div className='flex-shrin-0 d-flex flex-column flex-lg-row'>
                                {account ? (
                                    isRegistered ? (
                                        !userInfo?.is_vendor ? (
                                            <>
                                                {Number(productAvailability) > 0 &&
                                                    (tokenBalance >= formattedTotalPrice ? (
                                                        <button
                                                            className='btn btn-primary px-4 me-2 my-2 my-lg-0'
                                                            onClick={() => setOrderRequest(true)}
                                                        >
                                                            <RiMoneyDollarCircleFill className='me-1 mb-1' size={20} />
                                                            Buy Now
                                                        </button>
                                                    ) : (
                                                        <button className='btn btn-dark me-2 my-2 my-lg-0'>
                                                            <RiMoneyDollarCircleFill className='me-1 mb-1' size={20} />
                                                            No Enough Tokens
                                                        </button>
                                                    ))}
                                                {isBuyer && (
                                                    <button className='btn btn-info px-4' onClick={setRatingRequested}>
                                                        <SiStarship className='me-1 mb-1' size={20} />
                                                        {isRater ? 'Update your review' : 'Rate Product'}
                                                    </button>
                                                )}
                                            </>
                                        ) : (
                                            <button className='btn btn-dark px-4 my-2 my-lg-0' disabled={true}>
                                                <RiMoneyDollarCircleFill className='me-1 mb-1' size={20} />
                                                Vendors Cannot Buy
                                            </button>
                                        )
                                    ) : (
                                        <Link className='btn btn-primary px-4' to='/signup'>
                                            Signup
                                        </Link>
                                    )
                                ) : (
                                    <ConnectWalletHandler />
                                )}
                            </div>
                        )}
                    </div>
                    {Number(productAvailability) > 0 && (
                        <div
                            className={`bg-info mt-2 px-3 py-2 rounded ${
                                themeMode === 'dark' ? 'text-white' : 'text-gray-100'
                            } small d-inline-block`}
                        >
                            Total price for {picked} items will be{' '}
                            <strong className='text-decoration-underline'>
                                {Web3.utils.fromWei(formattedTotalPrice.toString(), 'ether')}
                            </strong>{' '}
                            {appSettings.currency} including shipping
                        </div>
                    )}
                </>
            )}
            <div>
                <div className='d-inline-block bg-opac-secondary rounded p-3 mt-4'>
                    <ShopBlock shopId={productShopId} productRating={productRating} />
                </div>
            </div>

            {orderRequest && (
                <Popup closeModal={setOrderRequest} containerClass='col-lg-6'>
                    <MakeOrder
                        productId={productId}
                        unitPrice={Number(productPrice)}
                        amount={picked}
                        shippingPrice={Number(shippingPrice)}
                        closeModal={setOrderRequest}
                        refreshBuyers={refreshBuyers}
                        resetQuantity={() => setPicked(1)}
                    />
                </Popup>
            )}
        </>
    );
}

export default ProductInfo;
