/* -----------------------------------------------------------------------------------------
    THSI FILE CONTAINS THE IMPORTANT WEB3/UI CONFIGURATIONS FOR THE APP
----------------------------------------------------------------------------------------- */
import React from 'react';
import { FaFighterJet, FaSadTear } from 'react-icons/fa';
import { RiHeart2Fill } from 'react-icons/ri';
import { BiMoviePlay } from 'react-icons/bi';
import { GiMadScientist } from 'react-icons/gi';

export const appSettings = {
    /* APP LOGO - LOGO FOR THE DARK VERSION  */
    logo: '/final_company_logo.png',

    /* APP LOGO - LOGO FOR THE LIGHT VERSION  */
    logoLight: '/final_company_logo.png',

    /* APP MAIN LOGO WIDTH - CHANGE IT ACCORDING TO YOUR OWN LOGO */
    logoWidth: '200',

    /* APP BRAND NAME - USED IN ALL PAGES & META TAGE - CHANGE TO YOUR OWN BRAND NAME*/
    brandName: 'Biznart.com',

    /* APP BRAND DESCRIPTION - USED IN HOME PAGE & META TAGS - PUT A SENTENCE THAT DESCRIBE YOUR APP*/
    brandDescription: 'Biznart.com - Blockchain Web3 Marketplace HBIT',

    /* APP AUTHOR - CHANGE IT IF YOU HAVE A LICENSE KEY OR PURCHASE CODE */
    marketplaceAuthor: 'HBIT Dev',

    /* COPYRIGHTS BRAND NAME */
    copyrightsBrand: 'HashBit Blockchain',

    /* TEXT IDENTIFIER FOR THE NETWORK WHERE THE APP IS UP AND RUNNING, YOU CAN WRITE WHATEVER YOU WANT */
    activeNetworkName: 'HashBit Mainnet',

    /* COPYRIGHTS LINK - REPLACE IT IF YOU HAVE A LICENSE KEY OR A PURCHASE CODE */
    copyrightsLink: 'https://hashbit.org',

    /* THE NETWORK RPC URL WHERE YOUR CONTRACTS ARE DEPOLYED ON, CHECK THE EXACT RPC URL FROM HERE https://chainlist.org */
    rpcUrl: 'https://rpc.hashbit.org',

    /* THE CHAIN ID of NETWORK WHERE YOUR CONTRACTS ARE DEPOLYED ON, GET IT FROM HERE https://chainlist.org */
    networkId: 11119,

    /* THE BLOCK SCAN EXPLORER WHRE YOU CAN TRACK THE TRANSACTIONS */
    blockExplorerUrls: 'https://explorer.hashbit.org',

    /* THE CURRENT APP CURRENCY THAT YOUR APP WILL APPEAR BESIDES EVERY PRICE */
    currency: 'BUSD',

    /* THE NATIVE CURRENCY THAT YOUR APP WILL USE FOR GAS FEES */
    nativeCurrency: 'HBIT',

    /* IPFS PROJECT ID */
    IPFSProjectID: '2OrmxuNCaepnebszxDHvtmeaTqn',

    /* IPFS PRJECT SECRET KEY */
    IPFSSecret: 'd018217f0451ec07d5a5a06065a6c5f6',

    /* IPFS DEDICATED GATEWAY NAME */
    IPFSGatewaySubdomain: 'biznart',

    /* ENDPOINT FOR ASK FOR A FEATURE FORM */
    newsletterFormId: 'moqzjqva',

    /* CONTACT US FORM */
    contactFormId: 'moqzjqva',

    /* MAILJS SERVICE KEY */
    mailJServiceKey: 'service_btez9uh',

    /* MAILJS PUBLIC KEY */
    mailJSPublicKey: 'RGw0fai8-gNovwtUE',

    /* MAILJS TEMPLATE ID KEY */
    mailJSTemplateID: 'template_0gh2gfa',

    /* MAILJS VERIFY TEMPLATE ID */
    mailJSVerifyTemplateID: 'template_0gh2gfa',

    /* SHOP VERIFY REQUESTS EMAIL */
    shopVerifyMail: 'support@biznart.com',

    /* WALLET CONNECT PROJECT ID */
    wcProjectId: 'b686d70965190a56ea3ed070f440b4c8',

    /* ADSEND AD CLIENT */
    adsenseAdClient: 'ca-pub-2678760727259403',

    /* ADSEND AD SLOT */
    adsenseAdSlot: '8055539015',

    /* COORDINATES FOR CONTACT US PAGE MAP */
    mapCenter: [40.68363937743894, -74.48798168552386],

    /* GENERS LIST - ADD OR DELETE FROM THEM AS YOU NEED */
    genresOptions: [
        { label: 'General', value: 'General', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Adventure', value: 'Adventure', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Animation', value: 'Animation', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Comedy', value: 'Comedy', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Horror', value: 'Horror', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Western', value: 'Western', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'War', value: 'War', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Game shows', value: 'Game shows', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Thriller', value: 'Thriller', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Documentary', value: 'Documentary', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Action', value: 'Action', icon: <FaFighterJet size='3.5rem' className='text-primary' /> },
        { label: 'Romance', value: 'Romance', icon: <RiHeart2Fill size='3.5rem' className='text-primary' /> },
        { label: 'Sci-Fi', value: 'Sci-Fi', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
        { label: 'Drama', value: 'Drama', icon: <FaSadTear size='3.5rem' className='text-primary' /> },
    ],

    /* CHANNEL CATEGORIES LIST - ADD OR DELETE FROM THEM AS YOU NEED */
    productCategories: [
        {
            label: 'Fashion',
            value: 'Fashion',
            icon: <BiMoviePlay size='3.5rem' className='text-primary' />,
        },
        { label: 'Electronics', value: 'Electronics', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Health', value: 'Health', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        {
            label: 'Sports and fitness',
            value: 'Sports and fitness',
            icon: <BiMoviePlay size='3.5rem' className='text-primary' />,
        },
        { label: 'Toys', value: 'Toys', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Books', value: 'Books', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        {
            label: 'Jewelry and watches',
            value: 'Jewelry and watches',
            icon: <BiMoviePlay size='3.5rem' className='text-primary' />,
        },
        { label: 'Furniture', value: 'Furniture', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Antique', value: 'Antique', icon: <FaFighterJet size='3.5rem' className='text-primary' /> },
        { label: 'News', value: 'News', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
    ],

    /* SHOP CATEGORIES LIST - ADD OR DELETE FROM THEM AS YOU NEED */
    shopCategories: [
        {
            label: 'Fashion',
            value: 'Fashion',
            icon: <BiMoviePlay size='3.5rem' className='text-primary' />,
        },
        { label: 'Electronics', value: 'Electronics', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Health', value: 'Health', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        {
            label: 'Sports and fitness',
            value: 'Sports and fitness',
            icon: <BiMoviePlay size='3.5rem' className='text-primary' />,
        },
        { label: 'Toys', value: 'Toys', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Books', value: 'Books', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        {
            label: 'Jewelry and watches',
            value: 'Jewelry and watches',
            icon: <BiMoviePlay size='3.5rem' className='text-primary' />,
        },
        { label: 'Furniture', value: 'Furniture', icon: <BiMoviePlay size='3.5rem' className='text-primary' /> },
        { label: 'Antique', value: 'Antique', icon: <FaFighterJet size='3.5rem' className='text-primary' /> },
        { label: 'News', value: 'News', icon: <GiMadScientist size='3.5rem' className='text-primary' /> },
    ],

    conditionOptions: [
        { label: 'New', value: 'New' },
        { label: 'Used', value: 'Used' },
        { label: 'Refurbished', value: 'Refurbished' },
    ],
};
