import React from 'react';
import { Web3Button } from '@web3modal/react';

function ConnectWalletHander() {
    return (
        <>
            <Web3Button />
        </>
    );
}

export default ConnectWalletHander;
