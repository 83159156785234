import React, { useState } from 'react';
import Popup from '../../components/general/Popup';
import { appSettings } from '../../helpers/settings';
import useOrder from '../../hooks/useOrder';
import useApp from '../../hooks/useApp';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';

function ResolveDisputeModal({ resolveRequested, setResolveRequested, disputeInfo }) {
    const { orderStatsContract, orderStatsContractAbi } = useOrder();
    const { setTransactionLoading } = useApp();
    const [defendantResolveComment, setDefendantResolveComment] = useState('');
    const [complainantResolveComment, setComplainantResolveComment] = useState('');
    const [genericComment, setGenericComment] = useState('');

    /* --------------------------------------------- 
              RESOLVE DISPUTE FUNCTION
     --------------------------------------------- */
    const { write: web3ResolveHandler } = useContractWrite({
        address: orderStatsContract?.address,
        abi: orderStatsContractAbi,
        functionName: 'disputeAction',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                setResolveRequested(false);
                toast.success('Dispute has been resolved');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
          RESOLVE DISPUTE HANDLER
    --------------------------------------------- */
    function handleResolveDispute(address, id, comment) {
        web3ResolveHandler({
            recklesslySetUnpreparedArgs: [id - 1, address, comment],
        });
    }

    return (
        <>
            {resolveRequested && (
                <Popup closeModal={setResolveRequested}>
                    {disputeInfo?.pendingAmount !== '0' ? (
                        <header className='mb-4 text-center'>
                            <h2 className='h1'>Resolve Dispute</h2>
                            <p className='text-muted mb-4'>
                                The order that the disputed was opened for it has some pending credits
                            </p>

                            <p className='mb-0 text-sm'>Pending Credit</p>
                            <h4>
                                {disputeInfo?.pendingAmount} {appSettings.currency}
                            </h4>
                        </header>
                    ) : (
                        <header className='mb-4 text-center'>
                            <h2 className='h1'>Resolve Dispute</h2>
                            <p className='text-muted mb-4'>
                                The order that the disputed was opened for it hasm't any pending credits, so you can
                                take an action manually
                            </p>
                        </header>
                    )}

                    {disputeInfo?.pendingAmount !== '0' ? (
                        <div className='row g-4'>
                            <div className='col-lg-6 d-flex align-items-center flex-column'>
                                <div className='p-3 rounded bg-gray-850 w-100'>
                                    <div className='d-flex align-items-center flex-shrink-0'>
                                        <div
                                            className='flex-shrink-0 bg-cover bg-center'
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                backgroundImage: `url(${disputeInfo?.disputeDefendantProfile})`,
                                                borderRadius: '0.5rem',
                                            }}
                                        ></div>
                                        <div className='ms-3'>
                                            <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                                {disputeInfo?.disputeDefendantName}
                                            </h6>
                                            <p className='text-muted small mb-0'>
                                                {disputeInfo?.disputeDefendantEmail}
                                            </p>
                                        </div>
                                    </div>
                                    <textarea
                                        row='7'
                                        className='form-control mt-3'
                                        value={defendantResolveComment}
                                        onChange={(e) => setDefendantResolveComment(e.target.value)}
                                        placeholder='Give a comment for resolving this dispute'
                                    ></textarea>
                                    <button
                                        className='btn btn-primary w-100 mt-3 btn-sm'
                                        onClick={() => {
                                            handleResolveDispute(
                                                disputeInfo?.disputeDefendantAddress,
                                                disputeInfo?.disputeId,
                                                defendantResolveComment
                                            );
                                        }}
                                    >
                                        Resolve for Defendant
                                    </button>
                                </div>
                            </div>
                            <div className='col-lg-6 d-flex align-items-center flex-column'>
                                <div className='p-3 rounded bg-gray-850 w-100'>
                                    <div className='d-flex align-items-center flex-shrink-0'>
                                        <div
                                            className='flex-shrink-0 bg-cover bg-center'
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                backgroundImage: `url(${disputeInfo?.disputeOpenerProfile})`,
                                                borderRadius: '0.5rem',
                                            }}
                                        ></div>
                                        <div className='ms-3'>
                                            <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                                {disputeInfo?.disputeOpenerName}
                                            </h6>
                                            <p className='text-muted small mb-0'>{disputeInfo?.disputeOpenerEmail}</p>
                                        </div>
                                    </div>
                                    <textarea
                                        row='7'
                                        className='form-control mt-3'
                                        value={complainantResolveComment}
                                        onChange={(e) => setComplainantResolveComment(e.target.value)}
                                        placeholder='Give a comment for resolving this dispute'
                                    ></textarea>
                                    <button
                                        className='btn btn-primary w-100 mt-3 btn-sm'
                                        onClick={() => {
                                            handleResolveDispute(
                                                disputeInfo?.disputeOpenerAddress,
                                                disputeInfo?.disputeId - 1,
                                                complainantResolveComment
                                            );
                                        }}
                                    >
                                        Resolve for Complainant
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='row g-4'>
                            <div className='col-12'>
                                <label className='form-label'>Leave a comment</label>
                                <textarea
                                    rows='7'
                                    className='form-control'
                                    value={genericComment}
                                    onChange={(e) => setGenericComment(e.target.value)}
                                    placeholder='Leave a comment for resolving this dispute'
                                ></textarea>
                            </div>
                            <div className='col-lg-6'>
                                <button
                                    className='btn btn-primary w-100'
                                    onClick={() => {
                                        handleResolveDispute(
                                            disputeInfo?.disputeDefendantAddress,
                                            disputeInfo?.disputeId,
                                            genericComment
                                        );
                                    }}
                                >
                                    Resolve for defendant
                                </button>
                            </div>
                            <div className='col-lg-6'>
                                <button
                                    className='btn btn-primary w-100'
                                    onClick={() => {
                                        handleResolveDispute(
                                            disputeInfo?.disputeOpenerAddress,
                                            disputeInfo?.disputeId,
                                            genericComment
                                        );
                                    }}
                                >
                                    Resolve for complainant
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
            )}
        </>
    );
}

export default ResolveDisputeModal;
