import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ReadOnlyRating from '../../components/general/ReadOnlyRating';
import useShop from '../../hooks/useShop';

function ShopBlock({ shopId, productRating }) {
    const { allShops } = useShop();

    const productShop = useMemo(() => {
        return allShops?.filter((shop) => shop.shopId === shopId)[0];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopId, productRating]);

    return (
        <>
            {productShop && (
                <Link to={`/shops/${productShop.shopSlug}`} className='d-flex align-items-center text-reset'>
                    <div className='user-avatar-pic-outer'>
                        <div
                            className='user-avatar-pic'
                            style={{ backgroundImage: `url(${productShop.shopLogo})` }}
                        ></div>
                    </div>
                    <div className='ms-2 text-start'>
                        <h6 className='mb-0'>{productShop?.shopTitle}</h6>
                        {<ReadOnlyRating rating={productShop.shopRating} />}
                    </div>
                </Link>
            )}
        </>
    );
}

export default ShopBlock;
