import React from 'react';
import { IoWallet } from 'react-icons/io5';
import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import useApp from '../../hooks/useApp';
import useWeb3 from '../../hooks/useWeb3';
import useUser from '../../hooks/useUser';
import { useContractWrite } from 'wagmi';

function UserProfits({ profits }) {
    const { contract, setTransactionLoading } = useApp();
    const { userContract, loadUsersList, loadUserInfo, userContractAbi } = useUser();
    const { account } = useWeb3();
    const { userInfo } = useUser();

    /* --------------------------------------------- 
              CLAIM PROFITS HANDLER
     --------------------------------------------- */
    const { write: web3ClaimProfits } = useContractWrite({
        address: contract?.address,
        abi: userContractAbi,
        functionName: 'claimFunds',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadUsersList(userContract);
                loadUserInfo(contract, account);
                toast.success('Great! Your earnings has been successfully claimed');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
          CLAIM EARNINGS HANDLER
    --------------------------------------------- */
    function claimEarningsHandler() {
        web3ClaimProfits();
    }

    return (
        <>
            <div className='row'>
                <div className='col-lg-6'>
                    {profits !== '0' ? (
                        <div>
                            {userInfo?.is_vendor ? (
                                <h2>You've earned some money</h2>
                            ) : (
                                <h2>You have some returned credit </h2>
                            )}
                            <div className='text-muted mb-3'>
                                Your {userInfo?.is_vendor ? 'current earnings' : 'returned credit'} are
                                <p className='h5 text-white mb-0 mx-2'>
                                    {profits} {appSettings?.currency}
                                </p>
                            </div>
                            <button className='btn btn-primary px-4' type='button' onClick={claimEarningsHandler}>
                                <IoWallet size={20} className='me-2 mb-1' />
                                Claim your earnings
                            </button>
                        </div>
                    ) : (
                        <div>
                            {userInfo?.is_vendor ? <h2>Try to sell something</h2> : <h2>No returned credit</h2>}
                            <p className='text-muted'>
                                There're no {userInfo?.is_vendor ? 'earnings' : 'returned credit'} at the moment.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default UserProfits;
