import React, { useState } from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useWeb3 from '../../hooks/useWeb3';
import UploadProgress from '../../components/general/UploadProgress';
import useApp from '../../hooks/useApp';
import useShop from '../../hooks/useShop';
import { createSlug } from '../../helpers/utils';
import ReactQuill from 'react-quill';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useContractWrite } from 'wagmi';
import 'react-quill/dist/quill.snow.css';

import Web3 from 'web3';
import { BigNumber } from 'ethers';

// IPFS CREATE HOST
const auth = 'Basic ' + Buffer.from(appSettings.IPFSProjectID + ':' + appSettings.IPFSSecret).toString('base64');
const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

function UpdateProductForm({ productData }) {
    const { account } = useWeb3();
    const { setTransactionLoading, setUploadingProgress, uploadingProgress } = useApp();
    const { shopContract, allShops, shopContractAbi, loadUserShops, loadAllShops, loadAllProducts } = useShop();
    const [productInfo, setProductInfo] = useState(JSON.parse(productData?.productInfo));
    const [productGallery, setProductGallery] = useState(
        productData?.productGallery?.map((img, index) => ({ src: img, id: index }))
    );
    const [redirectLink, setRedirectLink] = useState('/');

    const navigate = useNavigate();
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
              UPDATE PRODUCT HANDLER
     --------------------------------------------- */
    const { write: web3UpdateProduct } = useContractWrite({
        address: shopContract?.address,
        abi: shopContractAbi,
        functionName: 'updateProduct',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllShops(shopContract);
                loadAllProducts(shopContract);
                loadUserShops(shopContract, account);
            }, 5000);
            setTimeout(() => {
                navigate(`/products/${createSlug(redirectLink.trim())}`);
            }, 6000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
            setUploadingProgress(false);
        },
    });

    /* --------------------------------------------- 
          VALIDATE PROFILE IMAGE FILE TYPE
    --------------------------------------------- */
    const validateImageFileType = (file) => {
        if (file && file.length > 0) {
            const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!validImageTypes.includes(file[0].type)) {
                return 'Invalid file type. Please upload a PNG, JPEG, or JPG image.';
            }
        }
    };

    /* --------------------------------------------- 
          VALIDATE UNIQUE EMAIL ADDRESS
    --------------------------------------------- */
    const validateUniqueTitle = (title) => {
        if (allShops?.map((shop) => shop?.shopTitle)?.includes(title?.trim())) {
            return 'This shop title is already used before';
        }
    };

    /* --------------------------------------------- 
          HANDLE GALLERY IMAGES
    --------------------------------------------- */
    function handleGalleryItem(id) {
        setProductGallery((prev) => prev.filter((img) => img?.id !== id));
    }

    /* --------------------------------------------- 
          MARKDW
    --------------------------------------------- */
    function handleProductInfo(newValue) {
        setProductInfo(newValue);
    }
    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link'],
            ['clean'],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
    ];

    /* --------------------------------------------- 
          CREATE A SHOP FUNCTION
    --------------------------------------------- */
    async function handleCreateShop(data) {
        if (data?.gallery?.length > 0) {
            setUploadingProgress(true);
        }

        let ipfsScreenshots = [];
        let ipfsScreenshotHashes = [];
        for (let i = 0; i < data?.gallery.length; i++) {
            const ipfsFile = data?.gallery[i];
            const results = await ipfs.add(ipfsFile);

            ipfsScreenshots.push(results?.path);
        }
        ipfsScreenshotHashes =
            data?.gallery?.length > 0
                ? [
                      ...productGallery?.map((img) => img.src),
                      ...ipfsScreenshots?.map(
                          (hash) => `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${hash}`
                      ),
                  ]
                : productGallery?.map((img) => img.src);

        if (ipfsScreenshotHashes.length > 0) {
            setUploadingProgress(false);
            setTransactionLoading(true);
            setRedirectLink(data?.product_name);

            web3UpdateProduct({
                recklesslySetUnpreparedArgs: [
                    [
                        data?.product_name.trim(),
                        data?.product_description,
                        data?.category?.label ? data?.category?.label : productData?.productCategory,
                        ipfsScreenshotHashes,
                        JSON.stringify(productInfo),
                        Web3.utils.toWei(data?.price.toString(), 'ether'),
                        data?.weight !== '' ? Web3.utils.toWei(data?.weight.toString(), 'ether') : '0',
                        data?.dimensions,
                        BigNumber.from(data?.availability),
                        data?.return_policy,
                        data?.condition?.label ? data?.condition?.label : productData?.productCondition,
                    ],
                    Number(productData?.productShopId),
                    Number(productData?.productId),
                    Web3.utils.toWei(data?.shipping_price.toString(), 'ether'),
                ],
            });
        }
    }

    return (
        <>
            {uploadingProgress && <UploadProgress forShop={true} multi={true} />}
            <form onSubmit={handleSubmit(handleCreateShop)}>
                <div className='row g-4'>
                    {/* SHOP TITLE */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='productName'>
                            Product Name
                        </label>
                        <input
                            type='text'
                            className={`form-control ${errors.product_name ? 'is-invalid' : ''}`}
                            id='productName'
                            placeholder='Enter your product name'
                            name='product_name'
                            {...register('product_name', {
                                value: productData?.productName,
                                required: {
                                    value: true,
                                    message: 'Please enter your product name',
                                },
                                minLength: {
                                    value: 3,
                                    message: 'Product name must be at least 3 characters',
                                },
                                validate: validateUniqueTitle,
                            })}
                        />
                        {errors.product_name && (
                            <span className='invalid-feedback'>{errors.product_name?.message}</span>
                        )}
                    </div>

                    {/* PRODUCT DESCRIPTION */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='productDescription'>
                            Product Description
                        </label>
                        <textarea
                            rows='7'
                            className={`form-control ${errors.product_description ? 'is-invalid' : ''}`}
                            id='productDescription'
                            placeholder='Enter your product description'
                            name='product_description'
                            {...register('product_description', {
                                value: productData?.productDescription,
                                required: {
                                    value: true,
                                    message: 'Please enter your product description',
                                },
                                minLength: {
                                    value: 30,
                                    message: 'Product description must be at least 30 characters',
                                },
                                maxLength: {
                                    value: 300,
                                    message: 'Product description must be less than 300 characters',
                                },
                            })}
                        />
                        {errors.product_description && (
                            <span className='invalid-feedback'>{errors.product_description?.message}</span>
                        )}
                    </div>

                    {/* WEIGHT */}
                    <div className='col-lg-6'>
                        <label className='form-label' htmlFor='weight'>
                            Weight (KG)
                        </label>
                        <input
                            type='number'
                            className={`form-control ${errors.weight ? 'is-invalid' : ''}`}
                            id='weight'
                            placeholder='Product Weight (KG)'
                            min='0.00000001'
                            step='0.00000001'
                            name='weight'
                            {...register('weight', {
                                value: productData?.productWeight,
                                required: {
                                    value: false,
                                },
                            })}
                        />
                        {errors.weight && <span className='invalid-feedback'>{errors.weight?.message}</span>}
                    </div>

                    {/* DIMENSIONS */}
                    <div className='col-lg-6'>
                        <label className='form-label' htmlFor='dimensions'>
                            Dimensions (CM)
                        </label>
                        <input
                            type='text'
                            className={`form-control ${errors.dimensions ? 'is-invalid' : ''}`}
                            id='dimensions'
                            placeholder='Product dimensions hight-width-length'
                            name='dimensions'
                            {...register('dimensions', {
                                value: productData?.productDimensions,
                                required: {
                                    value: false,
                                },
                                pattern: {
                                    value: /^(\d+-?)+\d+$/,
                                    message: 'Please enter formate like this [width-height-length], i.e 10-20-3',
                                },
                            })}
                        />
                        {errors.dimensions && <span className='invalid-feedback'>{errors.dimensions?.message}</span>}
                    </div>

                    {/* CATEGORY */}
                    <div className='col-lg-6'>
                        <label className='form-label' htmlFor='category'>
                            Category
                        </label>
                        <Controller
                            name='category'
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <>
                                    <Select
                                        options={appSettings?.productCategories}
                                        id='category'
                                        className={`border-0 shadow-sm ${errors.category ? 'is-invalid' : ''}`}
                                        classNamePrefix='select'
                                        placeholder='Select'
                                        isSearchable={true}
                                        {...field}
                                        defaultValue={
                                            appSettings?.productCategories?.filter(
                                                (category) => category?.label === productData.productCategory
                                            )[0]
                                        }
                                    />
                                    {errors.category && (
                                        <span className='invalid-feedback'>{errors.category?.message}</span>
                                    )}
                                </>
                            )}
                        />
                    </div>

                    {/* CONDITION */}
                    <div className='col-lg-6'>
                        <label className='form-label' htmlFor='condition'>
                            condition
                        </label>
                        <Controller
                            name='condition'
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <>
                                    <Select
                                        options={appSettings.conditionOptions}
                                        id='condition'
                                        className={`border-0 shadow-sm ${errors.condition ? 'is-invalid' : ''}`}
                                        classNamePrefix='select'
                                        placeholder='Select'
                                        isSearchable={false}
                                        {...field}
                                        defaultValue={
                                            appSettings?.conditionOptions?.filter(
                                                (condition) => condition?.label === productData.productCondition
                                            )[0]
                                        }
                                    />
                                    {errors.condition && (
                                        <span className='invalid-feedback'>{errors.condition?.message}</span>
                                    )}
                                </>
                            )}
                        />
                    </div>

                    {/* AVAILLIBILTY */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='availability'>
                            Availability
                        </label>
                        <input
                            type='number'
                            className={`form-control ${errors.availability ? 'is-invalid' : ''}`}
                            id='availability'
                            placeholder='How many items in the stock'
                            name='availability'
                            min='1'
                            step='1'
                            {...register('availability', {
                                value: productData?.productAvailability,
                                required: {
                                    value: true,
                                    message: 'Please enter how many items in the stock',
                                },
                            })}
                        />
                        {errors.availability && (
                            <span className='invalid-feedback'>{errors.availability?.message}</span>
                        )}
                    </div>

                    {/* PRICE */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='price'>
                            Price
                        </label>
                        <input
                            type='number'
                            className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                            id='price'
                            placeholder='Enter your product price'
                            name='price'
                            min='0.00000001'
                            step='0.00000001'
                            {...register('price', {
                                value: productData?.productPrice,
                                required: {
                                    value: true,
                                    message: 'Please enter your product price',
                                },
                            })}
                        />
                        {errors.price && <span className='invalid-feedback'>{errors.price?.message}</span>}
                    </div>

                    {/* SHIPPING PRICE */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='shippingPrice'>
                            Shipping Price
                        </label>
                        <input
                            type='number'
                            className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                            id='shippingPrice'
                            placeholder='Enter your shipping price'
                            name='shipping_price'
                            min='0.00000001'
                            step='0.00000001'
                            {...register('shipping_price', {
                                value: productData?.shippingPrice,
                                required: {
                                    value: true,
                                    message: 'Please enter your shipping price',
                                },
                            })}
                        />
                        {errors.shipping_price && (
                            <span className='invalid-feedback'>{errors.shipping_price?.message}</span>
                        )}
                    </div>

                    {/* ADDItiONAl INFO */}
                    <div className='col-12'>
                        <label className='form-label'>Product Additional Description</label>
                        <ReactQuill
                            value={productInfo}
                            onChange={handleProductInfo}
                            modules={modules}
                            formats={formats}
                        />
                    </div>

                    {/* RETURN POLICY */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='returnPolicy'>
                            Return Policy
                        </label>
                        <textarea
                            rows='7'
                            className={`form-control ${errors.return_policy ? 'is-invalid' : ''}`}
                            id='returnPolicy'
                            name='return_policy'
                            placeholder='Enter your return policy, leave it if you do not have one'
                            {...register('return_policy', {
                                value: productData?.returnPolicy,
                                required: {
                                    value: false,
                                    message: 'Please enter how many items in the stock',
                                },
                            })}
                        ></textarea>
                        {errors.return_policy && (
                            <span className='invalid-feedback'>{errors.return_policy?.message}</span>
                        )}
                    </div>

                    {/* GALLERY */}

                    <div className='col-12'>
                        <label className='form-label' htmlFor='gallery'>
                            Product Gallery
                        </label>
                        <div className='row g-3 inline-form-gallery'>
                            {productGallery?.map((img, index) => {
                                return (
                                    <div className='col-lg-2 col-md-3 col-sm-5 col-6' key={index}>
                                        <div className='gallery-item'>
                                            <img className='img-fluid rounded' src={img?.src} alt='...' />
                                            <div
                                                className='gallery-item-btn'
                                                onClick={() => handleGalleryItem(img?.id)}
                                            >
                                                <AiFillCloseCircle className='text-white' size={30} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='gallery'>
                            Add new to Gallery
                        </label>
                        <input
                            type='file'
                            className={`form-control ${errors.gallery ? 'is-invalid' : ''}`}
                            id='gallery'
                            accept='.jpg, .png, .jpeg'
                            multiple={true}
                            name='gallery'
                            {...register('gallery', {
                                required: {
                                    value: productGallery?.length === 0,
                                    message: 'Please upload product gallery images',
                                },
                                validate: validateImageFileType,
                            })}
                        />
                        {errors.gallery && <span className='invalid-feedback'>{errors.gallery?.message}</span>}
                    </div>

                    {/* SUBMIT */}
                    <div className='col-12'>
                        <button className='btn btn-primary w-100' type='submit'>
                            Create your product
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default UpdateProductForm;
