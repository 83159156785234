import React from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { countries } from '../../helpers/constants';
import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import useWeb3 from '../../hooks/useWeb3';
import ConnectWalletHander from '../../components/general/ConnectWalletHandler';
import useApp from '../../hooks/useApp';
import useUser from '../../hooks/useUser';
import { useContractWrite } from 'wagmi';

// IPFS CREATE HOST
const auth = 'Basic ' + Buffer.from(appSettings.IPFSProjectID + ':' + appSettings.IPFSSecret).toString('base64');
const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

function UpdateInfo({ finishUpdate }) {
    const { account } = useWeb3();
    const { setTransactionLoading, themeMode } = useApp();
    const { userContract, loadUserInfo, userInfo, userContractAbi } = useUser();

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
              UPDATE INFO FUNCtiON
     --------------------------------------------- */

    const { write: web3UpdateInfo } = useContractWrite({
        address: userContract?.address,
        abi: userContractAbi,
        functionName: 'updateUser',
        mode: 'recklesslyUnprepared',
        onSuccess(data) {
            setTimeout(() => {
                setTransactionLoading(false);
                loadUserInfo(userContract, account);
                finishUpdate();
                toast.success('Great, your info is successfully updated');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
          HANDLE UPDATE FORM SUBMIT
    --------------------------------------------- */
    async function handleFormSubmit(data) {
        setTransactionLoading(true);

        const ipfsProfile = data?.profile?.length !== 0 ? await ipfs.add(data?.profile[0]) : userInfo?.profile_picture;

        if (ipfsProfile) {
            web3UpdateInfo({
                recklesslySetUnpreparedArgs: [
                    data?.full_name !== '' ? data?.full_name : userInfo?.full_name,
                    data?.location?.label ? data?.location?.label : userInfo?.location,
                    data?.profile?.length !== 0
                        ? `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsProfile.path}`
                        : userInfo?.profile_picture,
                    data?.mobile !== '' ? data?.mobile : userInfo?.phone_number,
                ],
            });
        }
    }

    /* --------------------------------------------- 
          VALIDATE PROFILE IMAGE FILE TYPE
    --------------------------------------------- */
    const validateImageFileType = (file) => {
        if (file?.length !== 0 && file) {
            const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!validImageTypes.includes(file[0].type)) {
                return 'Invalid file type. Please upload a PNG, JPEG, or JPG image.';
            }
        }
    };

    return (
        <div className='card' data-aos='fade-up'>
            <div className='card-body p-lg-5'>
                <header className='mb-4 text-center'>
                    <h3 className='mb-1'>Update your Information</h3>
                    <p className='text-muted'>Fill the form below to update your information</p>
                </header>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className='row g-4'>
                        {/* FULL NAME */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='fullName'>
                                Full Name
                            </label>
                            <input
                                type='text'
                                className={`form-control ${errors.full_name ? 'is-invalid' : ''}`}
                                id='fullName'
                                placeholder='Enter your full name'
                                name='full_name'
                                {...register('full_name', {
                                    value: userInfo?.full_name,
                                    required: {
                                        value: false,
                                        message: 'Please enter your full name',
                                    },
                                    minLength: {
                                        value: 8,
                                        message: 'Full name must be at least 8 characters',
                                    },
                                })}
                            />
                            {errors.full_name && <span className='invalid-feedback'>{errors.full_name?.message}</span>}
                        </div>

                        {/* EMAIL ADDRESS */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='email'>
                                Email Address
                            </label>
                            <input
                                type='email'
                                className='form-control disabled'
                                id='email'
                                disabled={true}
                                defaultValue={userInfo?.email_address}
                            />
                            <p className='form-text mb-0'>Verified email address cannot be changed</p>
                        </div>

                        {/* PHONE NUMBER */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='phoneNumber'>
                                Phone Number
                            </label>
                            <input
                                type='tel'
                                className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                                id='phoneNumber'
                                placeholder='Enter your phone number with country code'
                                name='mobile'
                                {...register('mobile', {
                                    value: userInfo?.phone_number,
                                    required: {
                                        value: false,
                                        message: 'Please enter your phone number',
                                    },
                                    pattern: {
                                        value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                        message: 'Invalide phone numberm e.g +44 1234 567890',
                                    },
                                })}
                            />
                            {errors.mobile && <span className='invalid-feedback'>{errors.mobile?.message}</span>}
                        </div>

                        {/* LOCATION */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='location'>
                                Location
                            </label>
                            <Controller
                                name='location'
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            options={countries}
                                            id='location'
                                            className={`border-0 shadow-sm ${errors.location ? 'is-invalid' : ''}`}
                                            classNamePrefix='select'
                                            placeholder='Select'
                                            isSearchable={true}
                                            defaultValue={
                                                countries?.filter((co) => co?.label === userInfo?.location)[0]
                                            }
                                            {...field}
                                        />
                                        {errors.location && (
                                            <span className='invalid-feedback'>{errors.location?.message}</span>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className='col-lg-12'>
                            <label className='form-label' htmlFor='profile'>
                                Profile Picture
                            </label>
                            <input
                                type='file'
                                className={`form-control ${errors.profile ? 'is-invalid' : ''}`}
                                id='profile'
                                accept='.jpg, .png, .jpeg'
                                name='profile'
                                {...register('profile', {
                                    required: {
                                        value: false,
                                        message: 'Please upload your profile image',
                                    },
                                    validate: validateImageFileType,
                                })}
                            />
                            {errors.profile && <span className='invalid-feedback'>{errors.profile?.message}</span>}
                        </div>
                        <div className='col-sm-6'>
                            {account ? (
                                <button className='btn btn-primary w-100 border-0' type='submit'>
                                    Update your information
                                </button>
                            ) : (
                                <ConnectWalletHander />
                            )}
                        </div>
                        <div className='col-sm-6'>
                            <button
                                className={`btn btn-secondary w-100 bg-gray-850 border-0 ${
                                    themeMode === 'light' ? 'text-dark' : ''
                                }`}
                                type='button'
                                onClick={finishUpdate}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UpdateInfo;
