import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { appSettings } from '../../helpers/settings';
import { configEtherScanUrl, fixNavbarToTop } from '../../helpers/utils';
import { HiUserCircle } from 'react-icons/hi';
import UserBlock from './UserBlock';
import { Web3Button } from '@web3modal/react';

// HOOKS
import useWeb3 from '../../hooks/useWeb3';
import useUser from '../../hooks/useUser';
import useApp from '../../hooks/useApp';

// COMPONENTS
import ConnectWalletHander from './ConnectWalletHandler';
import ToggleThemeHandler from './ToggleThemeHandler';

function Navbar() {
    const { account, networkId } = useWeb3();
    const { userInfo } = useUser();
    const { themeMode, owner } = useApp();

    /*** -------------------------------------------- */
    //      FIXING NAVBAR TO TOP
    /*** -------------------------------------------- */
    useEffect(() => {
        fixNavbarToTop();
    }, []);

    return (
        <header className='main-header fixed-top'>
            <div className='container'>
                <nav className='navbar w-100 navbar-expand-lg px-0 justify-content-between rounded-0 shadow-0'>
                    <Link className='navbar-brand' to='/'>
                        <img
                            src={themeMode === 'dark' ? appSettings.logo : appSettings.logoLight}
                            alt={appSettings.brandName}
                            width={appSettings.logoWidth}
                            className='h-auto'
                        />
                    </Link>

                    <button
                        className='navbar-toggler shadow-0 p-0 border-0'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#navbarSupportedContent'
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                    >
                        <span className='navbar-toggler-icon-el'>
                            <span className='btn-mobile--menu-icon'></span>
                        </span>
                    </button>

                    <div className='collapse navbar-collapse justify-content-lg-between' id='navbarSupportedContent'>
                        <ul className='navbar-nav mx-auto navbar-nav-custom'>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/' end>
                                    Home
                                </NavLink>
                            </li>
                            {/* <li className='nav-item'>
                                <NavLink className='nav-link' to='/products'>
                                    Products
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/shops'>
                                    Shops
                                </NavLink>
                            </li> */}
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/sellers'>
                                    Sellers
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/products'>
                                    Products
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/shops'>
                                    Shops
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/activities'>
                                    Activity
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/faqs'>
                                    FAQs
                                </NavLink>
                            </li>
                        </ul>

                        <div className='d-flex align-items-lg-center flex-column flex-lg-row'>
                            <div className='me-3 flex-shrink-0'>
                                <ToggleThemeHandler />
                            </div>
                            {account && (
                                <div className='me-3 flex-shrink-0'>
                                    <Web3Button />
                                </div>
                            )}
                            {account ? (
                                userInfo?.full_name ? (
                                    <div className='dropdown'>
                                        <Link
                                            className='px-0 nav-link dropdown-toggle text-capitalize no-caret d-flex align-items-center text-reset'
                                            id='accountDropdown'
                                            to='/'
                                            role='button'
                                            data-bs-toggle='dropdown'
                                            data-bs-target='#userDropdown'
                                            aria-expanded='false'
                                        >
                                            <UserBlock {...userInfo} />
                                        </Link>
                                        <ul className='dropdown-menu' id='userDropdown'>
                                            <li>
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href={configEtherScanUrl(networkId, account)}
                                                    className='dropdown-item rounded'
                                                >
                                                    Track Transactions
                                                </a>
                                            </li>
                                            <li>
                                                <NavLink
                                                    rel='noopener noreferrer'
                                                    to='/profile'
                                                    className='dropdown-item rounded'
                                                >
                                                    My Profile
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    rel='noopener noreferrer'
                                                    to='/account'
                                                    className='dropdown-item rounded'
                                                >
                                                    My Account
                                                </NavLink>
                                            </li>

                                            {owner === account ? (
                                                <li>
                                                    <NavLink
                                                        rel='noopener noreferrer'
                                                        to='/admin'
                                                        className='dropdown-item rounded'
                                                    >
                                                        Admin Panel
                                                    </NavLink>
                                                </li>
                                            ) : (
                                                <p className='dropdown-item rounded mb-0 disabled d-flex align-items-center'>
                                                    Admin Panel
                                                    <span className='badge bg-primary text-xxs p-1 fw-normal ms-2'>
                                                        Admin Only
                                                    </span>
                                                </p>
                                            )}

                                            {/* <li>
                                            <Link to='/account' className='dropdown-item rounded'>
                                                <span className='d-block lh-1 small text-muted'>
                                                    {owner === account ? 'App' : 'My Afilliate'} Profits
                                                </span>
                                                <strong className='lh-1'>
                                                    {appProfits} {appSettings?.currency}
                                                </strong>
                                            </Link>
                                        </li> */}
                                        </ul>
                                    </div>
                                ) : (
                                    <div className='d-inline-block mt-3 mt-lg-0'>
                                        <Link to='/signup' className='btn btn-primary btn-sm px-4'>
                                            <HiUserCircle className='me-1' />
                                            Signup
                                        </Link>
                                    </div>
                                )
                            ) : (
                                <ConnectWalletHander />
                            )}
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Navbar;
