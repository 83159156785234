import React from 'react';
import Popup from '../../components/general/Popup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FaShippingFast } from 'react-icons/fa';
import useOrder from '../../hooks/useOrder';
import useApp from '../../hooks/useApp';
import { useContractWrite } from 'wagmi';

function ShipOrderForm({ shippingRequested, setShippingRequested, orderId }) {
    const { setTransactionLoading, contract } = useApp();
    const { loadAllOrders, orderStatsContract, orderContractAbi } = useOrder();
    const {
        register,
        resetField,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
              SHIP ORDER FUNCTION
     --------------------------------------------- */
    const { write: web3ShipOrder } = useContractWrite({
        address: contract?.address,
        abi: orderContractAbi,
        functionName: 'shipping',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllOrders(orderStatsContract);
                toast.success('Great, your order shipping status has been updated');
                setShippingRequested(false);
                resetField('tracking_url');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
          HANDLING SHIPPING ORDER
    --------------------------------------------- */
    function handleShippingOrder(data) {
        web3ShipOrder({
            recklesslySetUnpreparedArgs: [orderId, data?.tracking_url],
        });
    }

    return (
        <>
            {shippingRequested && (
                <Popup closeModal={() => setShippingRequested(false)}>
                    <header className='mb-4 text-center'>
                        <h2 className='h1'>Ship Order to the Buyer</h2>
                        <p className='text-muted'>
                            Add the shipping tracking URL to the buyer to update shipping status.
                        </p>
                    </header>
                    <form onSubmit={handleSubmit(handleShippingOrder)}>
                        <div className='row g-4'>
                            <div className='col-12'>
                                <input
                                    type='text'
                                    className={`form-control ${errors.tracking_url ? 'is-invalid' : ''}`}
                                    name='tracking_url'
                                    placeholder='Add shipping tracking URL'
                                    {...register('tracking_url', {
                                        required: {
                                            value: true,
                                            message: 'Please enter tracking url',
                                        },
                                    })}
                                />
                                {errors.tracking_url && (
                                    <span className='invalid-feedback'>{errors.tracking_url?.message}</span>
                                )}
                            </div>
                            <div className='col-12'>
                                <button className='btn btn-primary w-100' type='submit'>
                                    <FaShippingFast className='me-2 mb-1' size={20} />
                                    Ship Now
                                </button>
                            </div>
                        </div>
                    </form>
                </Popup>
            )}
        </>
    );
}

export default ShipOrderForm;
