import React from 'react';
import { Link } from 'react-router-dom';
import useUser from '../../hooks/useUser';

function RegisterPopup() {
    const { setRegisterIndicator } = useUser();

    return (
        <div className='fullscreen-loader' data-aos='zoom-in-up' data-aos-duration='100'>
            <div className='fullscreen-loader-inner p-md-4'>
                <div className='container'>
                    <div className='row mt-4'>
                        <div className='col-lg-8 mx-auto text-center'>
                            <div className='card shadow position-relative'>
                                <div className='position-absolute m-3 top-0 end-0'>
                                    <button
                                        className='btn btn-dark btn-sm z-index-20'
                                        type='button'
                                        onClick={() => setRegisterIndicator(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                                <div className='card-body p-4 p-lg-5'>
                                    <h1 className='mb-3'>Not Registered Yet!</h1>
                                    <p className='text-muted mb-3'>
                                        We notice that you have not yet registered, please signup to be able to use our
                                        platform
                                    </p>
                                    <ul className='list-inline mb-0'>
                                        <li className='list-inline-item m-1'>
                                            <Link
                                                to='/signup'
                                                onClick={() => setRegisterIndicator(false)}
                                                className='btn btn-primary border-0'
                                            >
                                                Signup now
                                            </Link>
                                        </li>
                                        <li className='list-inline-item m-1'>
                                            <button
                                                type='button'
                                                onClick={() => setRegisterIndicator(false)}
                                                className='btn btn-dark bg-gray-850 border-0'
                                            >
                                                Signup Later
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterPopup;
