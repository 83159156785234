import React from 'react';
import ReadOnlyRating from '../../components/general/ReadOnlyRating';
import { Link } from 'react-router-dom';
import useWeb3 from '../../hooks/useWeb3';

function UpdateShopBanner({ shopTitle, shopOwner, shopCover, shopCategory, shopDescription, shopSlug }) {
    const { account } = useWeb3();

    return (
        <>
            <header className='page-banner with-bg' style={{ background: `url(${shopCover})` }}>
                <div className='container page-banner-content'>
                    <div className='row'>
                        <div className='col-lg-7 mx-auto text-center'>
                            <div className='badge bg-success mb-3'>{shopCategory}</div>
                            <h1 className='text-xl'>{shopTitle}</h1>
                            <p className='text-muted'>{shopDescription}</p>
                            <div className='d-flex justify-content-center'>
                                <ReadOnlyRating rating={0} />
                                {/* <Rating
                                    rating={rating}
                                    handleHover={handleHover}
                                    handleClick={handleClick}
                                    hover={hover}
                                /> */}
                            </div>
                            {account === shopOwner && (
                                <Link to={`/shops/${shopSlug}`} className='btn btn-primary mt-3'>
                                    Return to your Shop
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default UpdateShopBanner;
