import React, { useState, useEffect } from 'react';
import ReadOnlyRating from '../../components/general/ReadOnlyRating';
import { Link } from 'react-router-dom';
import useWeb3 from '../../hooks/useWeb3';
import { MdVerified } from 'react-icons/md';
import { appSettings } from '../../helpers/settings';
import Popup from '../../components/general/Popup';
import VerifyForm from './VerifyForm';
import UpdateDocsForm from './UpdateDocsForm';
import useApp from '../../hooks/useApp';

function ShopBanner({
    shopTitle,
    shopId,
    shopOwner,
    shopCover,
    shopCategory,
    shopDescription,
    shopSlug,
    shopRating,
    isVerified,
    shopDocs,
}) {
    const { account } = useWeb3();
    const { themeMode } = useApp();
    const [verifyRequested, setVerifyRequested] = useState(false);
    const [editRequested, setEditRequested] = useState(false);

    /* --------------------------------------------- 
          UPDATE PAGE TITLE
    --------------------------------------------- */
    useEffect(() => {
        document.title = `${appSettings.brandName} | ${shopTitle}`;
    }, [shopTitle]);

    return (
        <>
            <header className='page-banner with-bg pb-5' style={{ background: `url(${shopCover})` }}>
                <div className='container page-banner-content'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='badge bg-success mb-3'>{shopCategory}</div>
                            <h1 className='text-xl'>
                                {shopTitle}
                                {isVerified && (
                                    <MdVerified className='ms-2 mb-2' size={20} style={{ color: '#649bff' }} />
                                )}
                            </h1>
                            <p className='text-muted'>{shopDescription}</p>

                            {!isVerified && shopDocs?.certificate && (
                                <div className='bg-danger mb-4 mt-2 mb-3 px-3 py-2 rounded text-white small d-inline-block'>
                                    Verfication documents submitted!
                                    <button
                                        className='btn btn-link p-0 shadow-0 ms-2 text-white text-sm'
                                        onClick={() => setEditRequested(true)}
                                    >
                                        Edit Documents
                                    </button>
                                </div>
                            )}

                            <div className='mb-3'>
                                <ReadOnlyRating rating={shopRating} />
                            </div>
                            {account === shopOwner && (
                                <ul className='list-inline mb-0'>
                                    <li className='list-inline-item m-1'>
                                        <Link to={`/shops/update/${shopSlug}`} className='btn btn-primary mb-1'>
                                            Update your Shop
                                        </Link>
                                    </li>
                                    <li className='list-inline-item m-1'>
                                        <Link
                                            to={`/products/create/${shopSlug}`}
                                            className={`btn ${
                                                themeMode === 'dark' ? 'btn-light bg-white text-dark' : 'btn-dark'
                                            }   mb-1`}
                                        >
                                            Upload Product
                                        </Link>
                                    </li>
                                    {!isVerified && shopDocs?.length === 0 && (
                                        <li className='list-inline-item m-1'>
                                            <button
                                                type='button'
                                                className='btn btn-primary mb-1'
                                                onClick={() => setVerifyRequested(true)}
                                            >
                                                <MdVerified className='me-2 mb-1' size={20} />
                                                Verify your shop
                                            </button>
                                        </li>
                                    )}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </header>

            {verifyRequested && (
                <Popup closeModal={setVerifyRequested} containerClass='col-lg-6'>
                    <VerifyForm
                        shopId={shopId}
                        shopTitle={shopTitle}
                        shopCategory={shopCategory}
                        closeModal={() => setVerifyRequested(false)}
                    />
                </Popup>
            )}

            {editRequested && (
                <Popup closeModal={setEditRequested} containerClass='col-lg-6'>
                    <UpdateDocsForm
                        shopId={shopId}
                        shopTitle={shopTitle}
                        docs={shopDocs}
                        closeModal={() => setEditRequested(false)}
                    />
                </Popup>
            )}
        </>
    );
}

export default ShopBanner;
