import React, { useState, useEffect } from 'react';
import useMailer from './Mailer';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import { appSettings } from '../../helpers/settings';
import useWeb3 from '../../hooks/useWeb3';
import { toast } from 'react-toastify';
import useApp from '../../hooks/useApp';
import { useForm } from 'react-hook-form';
import { useContractWrite } from 'wagmi';

function VerifyPopup() {
    const { userInfo, userContract, userContractAbi, loadUserInfo, loadUsersList } = useUser();
    const { setTransactionLoading } = useApp();
    const { account } = useWeb3();
    const [code, setCode] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [sendCounter, setSendCOunter] = useState(60);
    const [startCounter, setStartCounter] = useState(false);
    const [requestMailUpdate, setRequestMailUpdate] = useState(false);
    const [userMail, setUserMail] = useState(null);
    const { sendEmail } = useMailer();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        resetField,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
          CHANGE EMAIL FUNCTION 
    --------------------------------------------- */
    const { write: changeMailHandler } = useContractWrite({
        address: userContract?.address,
        abi: userContractAbi,
        functionName: 'updateEmail',
        onSuccess() {
            loadUserInfo(userContract, account);
            loadUsersList(userContract);
            sendEmail(appSettings?.brandName, userInfo?.full_name, userInfo?.verification_code, userMail);
            setRequestMailUpdate(false);
            setTransactionLoading(false);
            toast.success('Great! your email has been changed');
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
          VERIFY EMAIL FUNCTION 
    --------------------------------------------- */
    const { write: verifyMailHandler } = useContractWrite({
        address: userContract?.address,
        abi: userContractAbi,
        functionName: 'verification',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadUserInfo(userContract, account);
                loadUsersList(userContract);
                toast.success('Great! your email has been successfully verified');
                navigate('/account');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
          RESET NEW EMAIL FORM FIELD ON SWITCHING
    --------------------------------------------- */
    useEffect(() => {
        resetField('new_mail');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestMailUpdate]);

    /* --------------------------------------------- 
          WAITING COUNTER FOR RESEND MAIL
    --------------------------------------------- */
    useEffect(() => {
        let counterInterval;
        if (startCounter && sendCounter !== 0) {
            counterInterval = setInterval(() => {
                setSendCOunter((prev) => prev - 1);
            }, 1000);
        }

        if (sendCounter === 0) {
            setStartCounter(false);
        }

        return () => clearInterval(counterInterval);
    }, [startCounter, sendCounter]);

    /* --------------------------------------------- 
          HANDLE MAIL VERIFICATION FUNCTION
    --------------------------------------------- */
    function handleVerifyCode(e) {
        e.preventDefault();
        setSubmitted(true);
        if (code === userInfo?.verification_code) {
            verifyMailHandler({
                recklesslySetUnpreparedOverrides: code,
            });
        }
    }

    /* --------------------------------------------- 
          VALIDATE OLD EMAIL ADDRESS
    --------------------------------------------- */
    const validateOldEmail = (email) => {
        if (email === userInfo?.email_address) {
            return 'This is your old email address';
        }
    };

    /* --------------------------------------------- 
          HANDLE SEND VERIFICATION MAIL FUNCTION
    --------------------------------------------- */
    function handleResendMail() {
        setStartCounter(true);
        sendEmail(appSettings?.brandName, userInfo?.full_name, userInfo?.verification_code, userInfo?.email_address);
    }

    /* --------------------------------------------- 
          HANDLE CHANGING USER EMAIL FUNCTION
    --------------------------------------------- */
    function handleChangeMail(data) {
        setUserMail(data.new_mail);

        changeMailHandler({
            recklesslySetUnpreparedOverrides: data.new_mail,
        });
    }

    return (
        <div className='fullscreen-loader' data-aos='zoom-in-up' data-aos-duration='100'>
            <div className='fullscreen-loader-inner p-md-4'>
                <div className='container'>
                    <div className='row mt-4'>
                        <div className='col-lg-6 mx-auto text-center'>
                            <div className='card shadow position-relative'>
                                <div className='card-body p-4 p-lg-5'>
                                    <h1 className='mb-3'>Verify your email address!</h1>
                                    <p className='text-muted mb-3'>
                                        We notice that you have not yet verified your email address, please check your
                                        email address and enter the verification code sent to you and enter it to the
                                        form below
                                    </p>

                                    <form onSubmit={handleSubmit(handleChangeMail)} noValidate>
                                        {requestMailUpdate && (
                                            <div className='row gy-3'>
                                                <div className='col-lg-12'>
                                                    <label className='form-label' htmlFor='newMail'>
                                                        New Email Address
                                                    </label>
                                                    <input
                                                        type='email'
                                                        className={`form-control ${
                                                            errors.new_mail ? 'is-invalid' : ''
                                                        }`}
                                                        id='newMail'
                                                        placeholder='Enter your new email'
                                                        name='new_mail'
                                                        {...register('new_mail', {
                                                            value: 'mdfdf',
                                                            required: {
                                                                value: true,
                                                                message: 'Please enter your email address',
                                                            },
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: 'Please enter a valid email format',
                                                            },
                                                            validate: validateOldEmail,
                                                        })}
                                                    />
                                                    {errors.new_mail && (
                                                        <span className='invalid-feedback'>
                                                            {errors.new_mail?.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className='col-12'>
                                                    <button className='btn btn-primary w-100' type='submit'>
                                                        Change your email
                                                    </button>
                                                    <p className='mt-4 mb-0'>
                                                        <button
                                                            className='btn btn-dark bg-gray-850 btn-sm border-0 px-4 py-2'
                                                            type='button'
                                                            onClick={() => setRequestMailUpdate(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </form>
                                    <form onSubmit={handleVerifyCode}>
                                        {!requestMailUpdate && (
                                            <div className='row gy-3'>
                                                <div className='col-12'>
                                                    <label
                                                        htmlFor='verificationCode'
                                                        className='form-label text-start d-block'
                                                    >
                                                        Verification Code
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${
                                                            submitted && code !== userInfo?.verification_code
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        id='verificationCode'
                                                        placeholder='Enter your verification code here'
                                                        value={code}
                                                        onChange={(e) => setCode(e.target.value)}
                                                    />
                                                    {submitted && code !== userInfo?.verification_code && (
                                                        <div className='invalid-feedback d-block'>
                                                            Invalid verification code
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='col-12'>
                                                    <button className='btn btn-primary w-100' type='submit'>
                                                        Verify your email
                                                    </button>
                                                </div>
                                                {!startCounter ? (
                                                    <div className='col-12 text-sm'>
                                                        Didn't receive the code{' '}
                                                        <button
                                                            className='btn btn-link text-sm text-decoration-none p-0 shadow-0'
                                                            type='button'
                                                            onClick={handleResendMail}
                                                        >
                                                            Resend verification email
                                                        </button>
                                                        <p className='mt-4 mb-0'>
                                                            <button
                                                                className='btn btn-dark bg-gray-850 btn-sm border-0 px-4 py-2'
                                                                type='button'
                                                                onClick={() => setRequestMailUpdate(true)}
                                                            >
                                                                change your email
                                                            </button>
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className='col-12 text-sm text-muted'>
                                                        Please wait{' '}
                                                        <strong className='fw-bold text-white'>{sendCounter}</strong>{' '}
                                                        seconds to be able to resend another mail
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerifyPopup;
