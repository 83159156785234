import React, { useMemo } from 'react';
import PageBanner from '../../components/general/PageBanner';
import UpdateProductForm from './UpdateProductForm';
import { useParams } from 'react-router-dom';
import useShop from '../../hooks/useShop';
import useWeb3 from '../../hooks/useWeb3';
import UnAuthorized from '../../components/UnAuthorized';

function UpdateProductPage() {
    const { allProducts } = useShop();
    const { account } = useWeb3();
    const { slug } = useParams();
    const currentProduct = useMemo(() => {
        return allProducts?.filter((product) => product?.productSlug === slug)[0];
    }, [allProducts, slug]);

    const isProductOwner = useMemo(() => {
        return Boolean(account === currentProduct?.productOwner);
    }, [account, currentProduct]);

    return isProductOwner ? (
        <>
            <PageBanner
                heading='Update your Product'
                text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium eaque eveniet'
            />

            <section className='pb-5'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-8 mx-auto'>
                            <div className='card'>
                                <div className='card-body p-lg-5'>
                                    {currentProduct && <UpdateProductForm productData={currentProduct} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    ) : (
        <UnAuthorized />
    );
}

export default UpdateProductPage;
