import React from 'react';

// COMPOENENTS
import PageBanner from '../../components/general/PageBanner';
import useApp from '../../hooks/useApp';

function AboutUsPage() {
    const { themeMode } = useApp();

    return (
        <>
            <PageBanner
                heading='About Us'
                text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, similique pariatur et corporis cum vero minus exercitationem veritatis.'
            ></PageBanner>

            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row gy-4 gx-5 justify-content-between'>
                                <div className='col-lg-4'>
                                    <h2>Some words about</h2>
                                    <p className='text-muted'>Working Since 1976</p>
                                </div>
                                <div className='col-lg-4'>
                                    <p>
                                        Mellentesque habitant morbi tristique senectus et netus et malesuada famesac
                                        turpis egestas. Ut non enim eleifend felis pretium feugiat.
                                    </p>
                                </div>
                                <div className='col-lg-4'>
                                    <p>
                                        Mellentesque habitant morbi tristique senectus et netus et malesuada famesac
                                        turpis egestas. Ut non enim eleifend felis pretium feugiat. Vivamus quis mi.
                                    </p>
                                    <h5 className='text-primary mb-0'>Susana Jacob</h5>
                                    <p className='text-muted'>Startegy Manager</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`py-5 ${themeMode === 'light' ? 'bg-light' : 'bg-dark'}`}>
                <div className='container py-5'>
                    <header className='mb-5 text-center'>
                        <h2 className='h1'>What We Do</h2>
                        <p className='text-muted'>Dummy text of the printing and typesetting industry</p>
                    </header>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row g-4 text-center'>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h4>Graphic Design</h4>
                                            <p className='text-muted mb-0'>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus,
                                                perspiciatis nemo.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h4>Web Development</h4>
                                            <p className='text-muted mb-0'>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus,
                                                perspiciatis nemo.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h4>Business Assistance</h4>
                                            <p className='text-muted mb-0'>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus,
                                                perspiciatis nemo.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5'>
                <div className='container text-center py-5'>
                    <div className='row'>
                        <div className='col-lg-6 mx-auto'>
                            <p className='h3 fw-light'>
                                "Game snake applies to the general game design original was not named snake"
                            </p>
                            <h5 className='text-primary'>Mero Newton</h5>
                            <p className='text-muted'>Creative Systems, Founder</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`py-5 ${themeMode === 'light' ? 'bg-light' : 'bg-dark'}`}>
                <div className='container text-center'>
                    <div className='row g-3 align-items-center'>
                        <div className='col-lg-2'>
                            <img src='/logo-1.jpg' alt='logo' width='80' className='img-fluid logo-img' />
                        </div>
                        <div className='col-lg-2'>
                            <img src='/logo-2.jpg' alt='logo' width='80' className='img-fluid logo-img' />
                        </div>
                        <div className='col-lg-2'>
                            <img src='/logo-3.jpg' alt='logo' width='80' className='img-fluid logo-img' />
                        </div>
                        <div className='col-lg-2'>
                            <img src='/logo-4.jpg' alt='logo' width='80' className='img-fluid logo-img' />
                        </div>
                        <div className='col-lg-2'>
                            <img src='/logo-3.jpg' alt='logo' width='80' className='img-fluid logo-img' />
                        </div>
                        <div className='col-lg-2'>
                            <img src='/logo-4.jpg' alt='logo' width='80' className='img-fluid logo-img' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUsPage;
