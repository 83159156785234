import React, { useState } from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useWeb3 from '../../hooks/useWeb3';
import UploadProgress from '../../components/general/UploadProgress';
import useApp from '../../hooks/useApp';
import useShop from '../../hooks/useShop';
import { createSlug } from '../../helpers/utils';
import { useContractWrite } from 'wagmi';

// IPFS CREATE HOST
const auth = 'Basic ' + Buffer.from(appSettings.IPFSProjectID + ':' + appSettings.IPFSSecret).toString('base64');
const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

function UpdateShopForm({ shopTitle, shopCover, shopLogo, shopDescription, shopEmail, shopId, shopCategory }) {
    const { account } = useWeb3();
    const { setTransactionLoading, setUploadingProgress, uploadingProgress } = useApp();
    const { shopContract, allShops, shopContractAbi, loadUserShops, loadAllShops } = useShop();
    const [logoUploadProgress, setLogoUploadProgress] = useState(0);
    const [coverUploadProgress, setCoverUploadProgress] = useState(0);
    const [redirectLink, setRedirectLink] = useState('/');
    const navigate = useNavigate();
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
              UPDATE SHOP FUNCTION
     --------------------------------------------- */
    const { write: web3UpdateShop } = useContractWrite({
        address: shopContract?.address,
        abi: shopContractAbi,
        functionName: 'updateShop',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                toast.success('Great! Your shop has been successfully updated');
                loadAllShops(shopContract);
                loadUserShops(shopContract, account);
            }, 5000);
            setTimeout(() => {
                navigate(`/shops/${createSlug(redirectLink.trim())}`);
            }, 6000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            setUploadingProgress(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
          VALIDATE PROFILE IMAGE FILE TYPE
    --------------------------------------------- */
    const validateImageFileType = (file) => {
        if (file?.length !== 0 && file) {
            const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!validImageTypes.includes(file[0].type)) {
                return 'Invalid file type. Please upload a PNG, JPEG, or JPG image.';
            }
        }
    };

    /* --------------------------------------------- 
          VALIDATE UNIQUE EMAIL ADDRESS
    --------------------------------------------- */
    const validateUniqueTitle = (title) => {
        if (allShops?.map((shop) => shop?.shopTitle)?.includes(title?.trim()) && title !== shopTitle) {
            return 'This shop title is already used before';
        }
    };

    /* --------------------------------------------- 
          UPDATE SHOP FUNCTION
    --------------------------------------------- */
    async function handleCreateShop(data) {
        setUploadingProgress(true);

        const logoProgress = function (progData) {
            if (data?.logo.length > 0) {
                let percent = Math.floor((Number(progData) * 100) / data?.logo[0]?.size);
                setLogoUploadProgress((prev) => percent);
            }
        };
        const coverProgress = function (progData) {
            if (data?.cover.length > 0) {
                let percent = Math.floor((Number(progData) * 100) / data?.cover[0]?.size);
                setCoverUploadProgress((prev) => percent);
            }
        };

        const ipfsLogo =
            data?.logo?.length !== 0 ? await ipfs.add(data?.logo[0], { progress: logoProgress }) : shopLogo;
        const ipfsCover =
            data?.cover?.length !== 0 ? await ipfs.add(data?.cover[0], { progress: coverProgress }) : shopCover;

        if (ipfsLogo && ipfsCover) {
            setUploadingProgress(false);
            setTransactionLoading(true);
            setRedirectLink(data?.shop_title);

            web3UpdateShop({
                recklesslySetUnpreparedArgs: [
                    shopId,
                    data?.shop_title.trim() !== '' ? data?.shop_title.trim() : shopTitle,
                    data?.shop_description !== '' ? data?.shop_description : shopDescription,
                    data?.category?.label ? data?.category?.label : shopCategory,
                    data?.shop_email !== '' ? data?.shop_email : shopEmail,
                    data?.cover?.length !== 0
                        ? `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsCover?.path}`
                        : shopCover,
                    data?.logo?.length !== 0
                        ? `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsLogo?.path}`
                        : shopLogo,
                ],
            });
        }
    }

    return (
        <>
            {uploadingProgress && (
                <UploadProgress forShop={true} logo={logoUploadProgress} cover={coverUploadProgress} />
            )}
            <form onSubmit={handleSubmit(handleCreateShop)}>
                <div className='row g-4'>
                    {/* SHOP TITLE */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='shopTitle'>
                            Shop Title
                        </label>
                        <input
                            type='text'
                            className={`form-control ${errors.shop_title ? 'is-invalid' : ''}`}
                            id='shopTitle'
                            placeholder='Enter your shop title'
                            name='shop_title'
                            {...register('shop_title', {
                                value: shopTitle,
                                required: {
                                    value: false,
                                    message: 'Please enter your shop title',
                                },
                                minLength: {
                                    value: 3,
                                    message: 'Shop title must be at least 3 characters',
                                },
                                validate: validateUniqueTitle,
                            })}
                        />
                        {errors.shop_title && <span className='invalid-feedback'>{errors.shop_title?.message}</span>}
                    </div>

                    {/* SHOP DESCRIPTION */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='shopDescription'>
                            Shop Description
                        </label>
                        <textarea
                            rows='7'
                            className={`form-control ${errors.shop_description ? 'is-invalid' : ''}`}
                            id='shopDescription'
                            placeholder='Enter your shop description'
                            name='shop_description'
                            {...register('shop_description', {
                                value: shopDescription,
                                required: {
                                    value: false,
                                    message: 'Please enter your shop description',
                                },
                                minLength: {
                                    value: 30,
                                    message: 'Shop description must be at least 30 characters',
                                },
                                maxLength: {
                                    value: 300,
                                    message: 'Shop description must be less than 300 characters',
                                },
                            })}
                        />
                        {errors.shop_description && (
                            <span className='invalid-feedback'>{errors.shop_description?.message}</span>
                        )}
                    </div>

                    {/* EMAIL ADDRESS */}
                    <div className='col-lg-6'>
                        <label className='form-label' htmlFor='shopEmail'>
                            Email Address
                        </label>
                        <input
                            type='email'
                            className={`form-control ${errors.shop_email ? 'is-invalid' : ''}`}
                            id='shopEmail'
                            placeholder='Enter your shop email address'
                            name='shop_email'
                            {...register('shop_email', {
                                value: shopEmail,
                                required: {
                                    value: false,
                                    message: 'Please enter your shop email address',
                                },
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Please enter a valid email format',
                                },
                            })}
                        />
                        {errors.shop_email && <span className='invalid-feedback'>{errors.shop_email?.message}</span>}
                    </div>

                    {/* CATEGORY */}
                    <div className='col-lg-6'>
                        <label className='form-label' htmlFor='category'>
                            Category
                        </label>
                        <Controller
                            name='category'
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <>
                                    <Select
                                        options={appSettings?.shopCategories}
                                        id='category'
                                        className={`border-0 shadow-sm ${errors.category ? 'is-invalid' : ''}`}
                                        classNamePrefix='select'
                                        placeholder='Select'
                                        isSearchable={true}
                                        {...field}
                                        defaultValue={
                                            appSettings?.shopCategories?.filter(
                                                (category) => category?.label === shopCategory
                                            )[0]
                                        }
                                    />
                                    {errors.category && (
                                        <span className='invalid-feedback'>{errors.category?.message}</span>
                                    )}
                                </>
                            )}
                        />
                    </div>

                    {/* SHOP AVATAR */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='logo'>
                            Shop Logo
                        </label>
                        <input
                            type='file'
                            className={`form-control ${errors.logo ? 'is-invalid' : ''}`}
                            id='logo'
                            accept='.jpg, .png, .jpeg'
                            name='logo'
                            {...register('logo', {
                                required: {
                                    value: false,
                                    message: 'Please upload your logo image',
                                },
                                validate: validateImageFileType,
                            })}
                        />
                        {errors.logo && <span className='invalid-feedback'>{errors.logo?.message}</span>}
                    </div>

                    {/* SHOP COVER */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='cover'>
                            Shop Cover Image
                        </label>
                        <input
                            type='file'
                            className={`form-control ${errors.cover ? 'is-invalid' : ''}`}
                            id='cover'
                            accept='.jpg, .png, .jpeg'
                            name='cover'
                            {...register('cover', {
                                required: {
                                    value: false,
                                    message: 'Please upload your cover image',
                                },
                                validate: validateImageFileType,
                            })}
                        />
                        {errors.cover && <span className='invalid-feedback'>{errors.cover?.message}</span>}
                    </div>

                    {/* SUBMIT */}
                    <div className='col-12'>
                        <button className='btn btn-primary w-100' type='submit'>
                            Save Changes
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default UpdateShopForm;
