import React from 'react';

const ShopContext = React.createContext({
    shopContract: null,
    shopContractAbi: null,
    paymentTokenContract: null,
    paymentToken: null,
    tokenBalance: null,
    allShops: [],
    allProducts: [],
    pendingProducts: [],
    productReviews: [],
    totalProducts: [],
    userShops: [],
    fetchingAllShops: true,
    fetchingAllProducts: true,
    loadShopContract: () => {},
    getShopContractAbi: () => {},
    loadAllShops: () => {},
    loadUserShops: () => {},
    loadAllProducts: () => {},
    loadUserProducts: () => {},
    loadProductReviews: () => {},
    loadPaymentTokenContract: () => {},
    loadPaymentTokenAddress: () => {},
    loadPaymentTokenBalance: () => {},
});

export default ShopContext;
