import React, { useRef, useEffect } from 'react';
import Scrollspy from 'react-scrollspy';

// COMPOENENTS
import PageBanner from '../../components/general/PageBanner';

function TermsPage() {
    const stickyRef = useRef();

    useEffect(() => {
        const bannerHeight = document.querySelector('.page-banner').offsetHeight;
        const headerHeight = document.querySelector('.main-header').offsetHeight;
        const footerOffset = document.querySelector('.main-footer').offsetTop;

        function fixingList() {
            if (stickyRef.current) {
                if (
                    window.scrollY > bannerHeight - headerHeight &&
                    window.scrollY < footerOffset - document.querySelector('.main-footer').offsetHeight - 150
                ) {
                    stickyRef.current.classList.add('sticky-list');
                    stickyRef.current.style.top = '150px';
                } else {
                    stickyRef.current.classList.remove('sticky-list');
                    stickyRef.current.style.top = 'auto';
                }
            }
        }

        window.addEventListener('scroll', fixingList);

        return () => {
            window.removeEventListener('scroll', fixingList);
        };
    }, []);

    return (
        <>
            <PageBanner
                heading='Terms & Conditions'
                text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, similique pariatur et corporis cum vero minus exercitationem veritatis.'
            ></PageBanner>

            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row g-5'>
                                <div className='col-lg-8 order-2 order-lg-1 content'>
                                    <div className='mb-5' id='term1'>
                                        <h2 className='mb-4'>Overview</h2>
                                        <p>
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit
                                            accusantium deleniti consectetur dolores, blanditiis nostrum aliquid amet
                                            sunt, ipsum ratione harum tempore cum nesciunt saepe quasi eligendi
                                            repellendus mollitia ut?
                                        </p>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad quo atque
                                            perferendis reiciendis nulla natus, quidem ut excepturi quasi quisquam minus
                                            reprehenderit provident ab assumenda tempore fugiat tenetur molestiae
                                            officiis? Atque reprehenderit impedit soluta possimus sunt deserunt,
                                            voluptatibus cum, ut molestiae aperiam magnam? Rerum animi nemo qui, et esse
                                            commodi cumque, saepe impedit accusamus ad dolores fuga aliquam culpa
                                            consequatur.
                                        </p>
                                        <p>
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit
                                            accusantium deleniti consectetur dolores, blanditiis nostrum aliquid amet
                                            sunt, ipsum ratione harum tempore cum nesciunt saepe quasi eligendi
                                            repellendus mollitia ut?
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term2'>
                                        <h2 className='mb-4'>Online Store Terms</h2>
                                        <p>
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut quidem
                                            corrupti nobis illo quasi, porro itaque reprehenderit sit, cumque nam
                                            ratione quo, doloribus alias vitae dolorem vel similique placeat deleniti.
                                        </p>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis,
                                            architecto quibusdam quod odit esse laboriosam totam modi nam fugit mollitia
                                            itaque dolorum officia cumque. Autem ullam odit impedit non ipsa! Libero
                                            eius fuga enim repellat voluptatum facere sequi soluta temporibus doloremque
                                            dicta maiores architecto, illum non id explicabo itaque nam dolores velit
                                            quam reiciendis amet voluptas! Voluptatum iure laudantium sequi! Nostrum
                                            eius eaque inventore non. Iusto non excepturi labore provident libero dicta
                                            eligendi alias repellat fugiat porro? Eveniet laudantium nobis nulla dolor
                                            perferendis, provident quos iure, ex optio numquam corporis.
                                        </p>
                                        <p>
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut quidem
                                            corrupti nobis illo quasi, porro itaque reprehenderit sit, cumque nam
                                            ratione quo, doloribus alias vitae dolorem vel similique placeat deleniti.
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term3'>
                                        <h2 className='mb-4'>General Conditions</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, officia
                                            numquam suscipit consequatur dicta reiciendis soluta earum molestiae dolore,
                                            magni assumenda rerum tenetur obcaecati, impedit itaque quisquam sequi
                                            voluptatem possimus? Ad enim libero vitae quae numquam aut obcaecati!
                                            Commodi tempore earum illo in quasi itaque veritatis iusto magni ut
                                            consectetur suscipit molestiae enim accusantium id, numquam sint nobis unde
                                            fuga.
                                        </p>
                                        <p>
                                            Architecto, vitae. Vero, quam nesciunt quibusdam architecto fuga provident
                                            dolorum consequuntur dolorem adipisci, cum, voluptatum eligendi rem
                                            consequatur sint. Explicabo quibusdam labore maiores, id molestias obcaecati
                                            adipisci enim numquam blanditiis!
                                        </p>
                                        <p>
                                            Minus beatae ipsam quisquam qui aut laboriosam optio voluptatibus sequi
                                            consequatur impedit explicabo cumque, earum deleniti ea suscipit nulla harum
                                            recusandae, dolorum incidunt exercitationem voluptatem eligendi, sit iste!
                                            Ratione, accusamus!
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term4'>
                                        <h2 className='mb-4'>Accuracy of Billing</h2>
                                        <p>
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reiciendis
                                            blanditiis, quaerat voluptatem optio necessitatibus, nesciunt magni
                                            voluptates doloribus expedita est eveniet asperiores fugiat distinctio error
                                            ipsam dolorum. Dolorum, maiores dolores! Quo dolorem possimus perspiciatis
                                            itaque optio aspernatur minima doloremque quis, accusantium adipisci
                                            incidunt natus exercitationem a facere voluptatibus quam veritatis, eum,
                                            corrupti dolorum! Vero voluptates, dolorem perspiciatis aliquid repellat
                                            culpa?
                                        </p>
                                        <p>
                                            Error at sequi unde temporibus impedit, deserunt accusantium tenetur
                                            corporis id molestias consequatur commodi consequuntur ab. Quidem sapiente
                                            voluptatem eveniet ipsa corporis possimus recusandae magnam! Repellat quo
                                            pariatur alias magnam!
                                        </p>
                                        <p>
                                            Vitae dignissimos quod excepturi aspernatur ipsa consequatur, exercitationem
                                            deserunt quibusdam maiores sunt? Repellendus sed, explicabo, cumque autem
                                            nulla ad tempore obcaecati qui libero maxime, eveniet asperiores ex minima
                                            dicta perferendis.
                                        </p>
                                        <p>
                                            Quos ad similique dolores aliquam ipsa, facilis magni quidem voluptatibus
                                            eligendi totam! Sapiente quasi consequuntur id quia exercitationem non
                                            natus, expedita dolorem aut et rerum cum dolores? Fugit, odit! Perferendis!
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term5'>
                                        <h2 className='mb-4'>Optional Tools</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum iusto
                                            repellat distinctio molestiae nam consequuntur tempore! Expedita numquam
                                            molestias sapiente reiciendis delectus, quibusdam tempore accusamus
                                            excepturi, quaerat eius repellendus accusantium! .
                                        </p>
                                        <p>
                                            Blanditiis dicta unde ad. Optio obcaecati eligendi temporibus aliquid
                                            officiis illo fuga, animi eaque magni ipsum quibusdam, tempora distinctio
                                            tenetur dicta. Amet numquam eos esse itaque debitis natus. Perferendis,
                                            asperiores. Aliquam velit aliquid illum, ullam in, expedita magni officia
                                            obcaecati accusamus vitae dolore dolores beatae consequatur dolorem? Nisi
                                            libero dolor odio voluptate, qui sequi voluptatum beatae neque nihil soluta
                                            nemo!
                                        </p>
                                        <p>
                                            Consequatur dicta asperiores sunt dolore eos laborum molestias, officiis
                                            tempore et quaerat magnam recusandae odio aliquid corporis fugit natus quod,
                                            fugiat, obcaecati nihil tempora! Facere quibusdam odio perspiciatis alias
                                            aspernatur. Adipisci impedit numquam totam id temporibus. Nostrum reiciendis
                                            libero odit dolor commodi. Quo fugiat sapiente deleniti doloremque optio
                                            voluptatum, magni dolorem quibusdam debitis! Minima expedita incidunt veniam
                                            totam amet suscipit. Pariatur animi esse illo inventore velit temporibus
                                            nostrum necessitatibus, tenetur consectetur magni nulla minus voluptate.
                                            Voluptate asperiores delectus quis, esse tenetur cumque reiciendis, nihil,
                                            beatae voluptatibus laborum dolores soluta! Explicabo.
                                        </p>
                                        <p>
                                            Consequuntur minima quos corporis aperiam quod deserunt tempora ipsum
                                            temporibus eligendi, cum vero facilis dolorum, quisquam eum numquam rem
                                            voluptatem tempore voluptatum magni voluptate nemo quo! Minima fugit quia
                                            veniam
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term6'>
                                        <h2 className='mb-4'>Personal Information</h2>
                                        <p>
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit
                                            accusantium deleniti consectetur dolores, blanditiis nostrum aliquid amet
                                            sunt, ipsum ratione harum tempore cum nesciunt saepe quasi eligendi
                                            repellendus mollitia ut?
                                        </p>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad quo atque
                                            perferendis reiciendis nulla natus, quidem ut excepturi quasi quisquam minus
                                            reprehenderit provident ab assumenda tempore fugiat tenetur molestiae
                                            officiis? Atque reprehenderit impedit soluta possimus sunt deserunt,
                                            voluptatibus cum, ut molestiae aperiam magnam? Rerum animi nemo qui, et esse
                                            commodi cumque, saepe impedit accusamus ad dolores fuga aliquam culpa
                                            consequatur.
                                        </p>
                                        <p>
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit
                                            accusantium deleniti consectetur dolores, blanditiis nostrum aliquid amet
                                            sunt, ipsum ratione harum tempore cum nesciunt saepe quasi eligendi
                                            repellendus mollitia ut?
                                        </p>
                                    </div>
                                </div>
                                <div className='col-lg-4 position-relative order-1 order-lg-2 terms-list'>
                                    <div ref={stickyRef}>
                                        <h4>Table of Content</h4>
                                        <Scrollspy
                                            items={['term1', 'term2', 'term3', 'term4', 'term5', 'term6']}
                                            currentClassName='active'
                                            style={{ listStyle: 'none' }}
                                            offset={-150}
                                        >
                                            <li className='mb-2'>
                                                <a href='#term1' className='terms-link'>
                                                    Overview
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term2' className='terms-link'>
                                                    Online Store Terms
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term3' className='terms-link'>
                                                    General Conditions
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term4' className='terms-link'>
                                                    Accuracy of Billing
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term5' className='terms-link'>
                                                    Optional Tools
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term6' className='terms-link'>
                                                    Personal Information
                                                </a>
                                            </li>
                                        </Scrollspy>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TermsPage;
