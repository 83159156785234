import React, { useState } from 'react';
import Rating from '../../components/general/Rating';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useApp from '../../hooks/useApp';
import { SiStarship } from 'react-icons/si';
import useShop from '../../hooks/useShop';
import useUser from '../../hooks/useUser';
import { useContractWrite } from 'wagmi';

function ProductRatingForm({ productId, closeModal }) {
    const { contract, setTransactionLoading } = useApp();
    const { shopContractAbi, loadAllShops, loadAllProducts } = useShop();
    const { userContract, loadUsersList } = useUser();
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [submitted, setSubmitted] = useState(false);

    const handleClick = (value) => {
        setRating(value);
    };

    const handleHover = (value) => {
        setHover(value);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
              SUBMIT REVIEW FUNCTION
     --------------------------------------------- */
    const { write: web3SubmitReview } = useContractWrite({
        address: contract?.address,
        abi: shopContractAbi,
        functionName: 'rateProduct',
        onSuccess() {
            setTimeout(() => {
                closeModal();
                setTransactionLoading(false);
                loadAllShops(contract);
                loadAllProducts(contract);
                loadUsersList(userContract);
                toast.success('Great! your review has been sumitted');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
          SUBMIT PRODUCT REVIEW FUNCTION
    --------------------------------------------- */
    function handleRatingSubmit(data) {
        web3SubmitReview({
            recklesslySetUnpreparedArgs: [rating * 10, productId, data?.rating_reason, data?.rating_msg],
        });
    }

    return (
        <>
            <header className='text-center mb-4'>
                <h3>Submit a Review</h3>
                <p className='text-muted'>
                    Give a review for this product based on your experience, your review could be edited later
                </p>
            </header>

            <form className='row g-3' onSubmit={handleSubmit(handleRatingSubmit)}>
                <div className='col-12'>
                    <label className='form-label'>How Many Stars</label>
                    <Rating rating={rating} handleHover={handleHover} handleClick={handleClick} hover={hover} />
                    {submitted && rating === 0 && (
                        <span className='invalid-feedback'>Please provide at least one star</span>
                    )}
                </div>
                <div className='col-12'>
                    <label className='form-label'>Reason of Review</label>
                    <input
                        type='text'
                        placeholder='i.e Good Material Quality'
                        name='rating_reason'
                        className={`form-control ${errors.rating_reason ? 'is-invalid' : ''}`}
                        {...register('rating_reason', {
                            required: {
                                value: true,
                                message: 'Please enter reason of rating',
                            },
                        })}
                    />
                    {errors.rating_reason && <span className='invalid-feedback'>{errors.rating_reason.message}</span>}
                </div>
                <div className='col-12'>
                    <label className='form-label'>Review Message</label>
                    <textarea
                        rows='5'
                        placeholder='Please provide a small message about your review'
                        name='rating_msg'
                        className={`form-control ${errors.rating_msg ? 'is-invalid' : ''}`}
                        {...register('rating_msg', {
                            required: {
                                value: true,
                                message: 'Please enter reason of rating',
                            },
                        })}
                    ></textarea>
                    {errors.rating_msg && <span className='invalid-feedback'>{errors.rating_msg?.message}</span>}
                </div>
                <div className='col-12'>
                    <button className='btn btn-primary' type='submit' onClick={() => setSubmitted(true)}>
                        <SiStarship className='me-2 mb-1' size={20} />
                        Submit your Review
                    </button>
                </div>
            </form>
        </>
    );
}

export default ProductRatingForm;
