import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import NotFound from '../../components/NotFound';
import useUser from '../../hooks/useUser';
import UserBanner from '../../components/general/UserBanner';
import InfoGrid from './InfoGrid';

function UserSinglePage() {
    const { usersList } = useUser();
    const { slug } = useParams();

    const targetUser = useMemo(() => {
        return usersList?.filter((user) => user?.user_slug === slug)[0];
    }, [slug, usersList]);

    return (
        <>
            {targetUser ? (
                <>
                    <UserBanner targetUser={targetUser} noTable={true} />
                    <InfoGrid targetUser={targetUser} />
                </>
            ) : (
                <NotFound />
            )}
        </>
    );
}

export default UserSinglePage;
