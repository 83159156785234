import React, { useReducer } from 'react';
import { createSlug } from '../helpers/utils';
import { readContract } from '@wagmi/core';
import Web3 from 'web3';

import UserContext from './user-context';

const defaultUserState = {
    userContract: null,
    userContractAbi: null,
    registerIndicator: false,
    isRegistered: false,
    userInfo: {},
    usersList: [],
};

const userReducer = (state, action) => {
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            userContract: action.userContract,
        };
    }

    if (action.type === 'GET_ABI') {
        return {
            ...state,
            userContractAbi: action.userContractAbi,
        };
    }

    if (action.type === 'USER_INFO') {
        return {
            ...state,
            isRegistered: action?.userInfo[1][0] ? true : false,
            userInfo:
                action?.userInfo[0] !== '0x0000000000000000000000000000000000000000'
                    ? {
                          address: action?.userInfo[0],
                          full_name: action?.userInfo[1][0],
                          phone_number: action?.userInfo[1][4],
                          email_address: action?.userInfo[1][1],
                          location: action?.userInfo[1][2],
                          user_slug: `${createSlug(action?.userInfo[1][0])}${action?.userInfo[0].slice(-5)}`,
                          is_vendor: action?.userInfo[3] === 1 ? false : true,
                          profile_picture: action?.userInfo[1][3],
                          verification_code: action?.userInfo[2],
                          is_verified: action?.userInfo[4],
                          profits: Web3.utils.fromWei(action?.userInfo[5].toString(), 'ether'),
                          rating: Number(action?.userInfo[6]) / 10,
                      }
                    : {},
        };
    }

    if (action.type === 'USERS_LIST') {
        return {
            ...state,
            usersList: action.usersList?.map((user) => {
                return {
                    address: user[0],
                    full_name: user[1][0],
                    phone_number: user[1][4],
                    email_address: user[1][1],
                    location: user[1][2],
                    user_slug: `${createSlug(user[1][0])}${user[0].slice(-5)}`,
                    is_vendor: user[3] === 1 ? false : true,
                    profile_picture: user[1][3],
                    verification_code: user[2],
                    is_verified: user[4],
                    profits: Web3.utils.fromWei(user[5].toString(), 'ether'),
                    rating: Number(user[6]) / 10,
                };
            }),
        };
    }

    if (action.type === 'SET_REGISTER_INDICATOR') {
        return {
            ...state,
            registerIndicator: action.registerIndicator,
        };
    }

    return defaultUserState;
};

const UserProvider = (props) => {
    const [userState, dispatchUserAction] = useReducer(userReducer, defaultUserState);

    const loadContractHandler = (userContract) => {
        dispatchUserAction({ type: 'CONTRACT', userContract: userContract });
        return userContract;
    };

    const getUserContractAbiHandler = (abi) => {
        dispatchUserAction({ type: 'GET_ABI', userContractAbi: abi });
    };

    const loadUserInfoHandler = async (contract, address) => {
        const userInfo = await readContract({
            address: contract.address,
            abi: userState.userContractAbi,
            functionName: 'getUser',
            overrides: address,
        });
        dispatchUserAction({ type: 'USER_INFO', userInfo: userInfo });

        return userInfo;
    };

    const loadUsersListHandler = async (contract) => {
        const usersList = await readContract({
            address: contract.address,
            abi: userState.userContractAbi,
            functionName: 'getAllUsers',
        });
        dispatchUserAction({ type: 'USERS_LIST', usersList: usersList });
        return usersList;
    };

    const setRegisterIndicatorHandler = async (state) => {
        dispatchUserAction({ type: 'SET_REGISTER_INDICATOR', registerIndicator: state });
    };

    const userContext = {
        userContract: userState.userContract,
        userInfo: userState.userInfo,
        usersList: userState.usersList,
        registerIndicator: userState.registerIndicator,
        isRegistered: userState.isRegistered,
        userContractAbi: userState.userContractAbi,
        loadUserContract: loadContractHandler,
        getUserContractAbi: getUserContractAbiHandler,
        loadUserInfo: loadUserInfoHandler,
        loadUsersList: loadUsersListHandler,
        setRegisterIndicator: setRegisterIndicatorHandler,
    };

    return <UserContext.Provider value={userContext}>{props.children}</UserContext.Provider>;
};

export default UserProvider;
