import React from 'react';
import UserBlock from '../../components/general/UserBlock';
import { appSettings } from '../../helpers/settings';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { AiFillShop } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function TopSellers({ sellers }) {
    return (
        <>
            <section className='pb-4'>
                <div className='container pb-4'>
                    <header className='mb-4'>
                        <h2 data-aos='fade-up'>Our Top Sellers</h2>
                        <p className='text-muted' data-aos='fade-up' data-aos-delay='100'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum odit iste unde sapiente ullam
                            porro
                        </p>
                    </header>

                    <div className='row g-4'>
                        {sellers?.map((vendor, index) => {
                            return (
                                <div
                                    className='col-xl-4 col-lg-6'
                                    key={index}
                                    data-aos='fade-up'
                                    data-aos-delay={index * 100}
                                >
                                    <Link className='text-reset' to={`/sellers/${vendor?.user_slug}`}>
                                        <div className='card card-hover'>
                                            <div className='card-body'>
                                                <UserBlock
                                                    {...vendor}
                                                    isPrivate={true}
                                                    vendorRating={0}
                                                    hasRating={true}
                                                />
                                                {/* <ReadOnlyRating rating={0} /> */}
                                                <div className='p-3 bg-gray-850 rounded mt-3'>
                                                    <ul className='list-inline mb-0 text-muted small'>
                                                        <li className='list-inline-item'>
                                                            <RiMoneyDollarCircleFill
                                                                className='text-primary me-1 mb-1'
                                                                size={20}
                                                            />
                                                            <strong className='fw-bold text-white me-1'>
                                                                {vendor?.profits} {appSettings?.currency}
                                                            </strong>{' '}
                                                            Total Earnings
                                                        </li>
                                                        <li className='list-inline-item'> | </li>
                                                        <li className='list-inline-item'>
                                                            <AiFillShop className='text-primary me-1 mb-1' size={20} />
                                                            <strong className='fw-bold text-white me-1'>
                                                                {vendor?.shops?.length}
                                                            </strong>{' '}
                                                            Shops
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    );
}

export default TopSellers;
