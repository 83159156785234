import React, { useState } from 'react';
import PageBanner from '../../components/general/PageBanner';
import InfoGrid from './InfoGrid';
import UpdateInfo from './UpdateInfo';

function UserProfilePage() {
    const [updateVisible, setUpdateVisible] = useState(false);
    return (
        <>
            <PageBanner
                heading='My Profile'
                text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid voluptas asperiores.'
            ></PageBanner>
            <section className='pb-5'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row g-4'>
                                {updateVisible ? (
                                    <UpdateInfo finishUpdate={() => setUpdateVisible(false)} />
                                ) : (
                                    <InfoGrid requestUpdate={() => setUpdateVisible(true)} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default UserProfilePage;
