import React from 'react';
import { appSettings } from '../../helpers/settings';
import ConnectWalletHander from './ConnectWalletHandler';
import useApp from '../../hooks/useApp';
import { switchNetwork } from '@wagmi/core';
import useWeb3 from '../../hooks/useWeb3';

function ViewOnlyAlert() {
    const { themeMode } = useApp();
    const { account } = useWeb3();

    /* --------------------------------------------- 
          CHAHNGE NETWORK HANDLER
    --------------------------------------------- */
    async function changChain() {
        // eslint-disable-next-line no-unused-vars
        const network = await switchNetwork({
            chainId: appSettings.networkId,
        });
    }

    return (
        <div className='viewonly-mode'>
            <div className='px-4 text-end'>
                <div className='d-inline-block'>
                    <div className={`card ${themeMode === 'light' ? 'bg-white shadow-lg' : 'bg-gray-850'} mb-0`}>
                        <div className={`card-body text-center`}>
                            {/* <img
                                src='/metamask.png'
                                alt='Kovan Test Network'
                                className='mb-4 flex-shrink-0'
                                width='30'
                            /> */}
                            <h2 className='h6 mb-0 ms-2'>
                                This Demo is set to run on{' '}
                                <span className='text-orange orange text-backline'>
                                    {appSettings.activeNetworkName}
                                </span>
                            </h2>
                            <p className='text-muted text-sm'>Please Switch your network</p>
                            {account ? (
                                <button className='btn btn-primary switch-btn' onClick={changChain}>
                                    <img src='/wcbrand.svg' alt='wc' className='me-2' width='20' />
                                    SwitchNetwork
                                </button>
                            ) : (
                                <ConnectWalletHander />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewOnlyAlert;
