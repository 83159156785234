import React, { useState, useMemo } from 'react';
import FetchingDataLoader from '../../components/general/FetchingDataLoader';
import PageBanner from '../../components/general/PageBanner';
import ShopCard from '../../components/general/ShopCard';
import { BiRightArrowAlt, BiLeftArrowAlt, BiCategoryAlt, BiSearchAlt, BiReset } from 'react-icons/bi';
import { BsFillCalendar2DateFill } from 'react-icons/bs';
import { FaStarHalfAlt } from 'react-icons/fa';
import { appSettings } from '../../helpers/settings';
import useShop from '../../hooks/useShop';
import ReactPaginate from 'react-paginate';

function ShopsPage() {
    const { allShops, fetchingAllShops } = useShop();

    // SEARCH VARIABLED -------------------------------------
    const [searchQuery, setSearchQuery] = useState('');
    const [searchSubmitted, setSearchSubmitted] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    // FILTERS VARIABLES ------------------------------------
    const [categoryFilter, setCategoryFilter] = useState({ label: 'All', value: 'All' });
    const [dateFilter, setDateFilter] = useState({ label: 'All', value: 'All' });
    const [ratingFilter, setRatingFilter] = useState({ label: 'All', value: 'All' });

    // FILTERED SHOPS ------------------------------------
    const filteredResult = useMemo(() => {
        if (searchResults?.length === 0) {
            if (categoryFilter?.value !== 'All') {
                return allShops.filter((shop) => shop.shopCategory === categoryFilter?.value);
            } else {
                return allShops;
            }
        } else {
            if (categoryFilter?.value !== 'All') {
                return searchResults.filter((shop) => shop.shopCategory === categoryFilter?.value);
            } else {
                return searchResults;
            }
        }
    }, [categoryFilter, allShops, searchResults]);

    // PAGINATION VARIABLES ---------------------------------
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 16;
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(allShops?.length / itemsPerPage);

    /* --------------------------------------------- 
          MOVE BETWEEN PAGES
    --------------------------------------------- */
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % allShops?.length;
        setItemOffset(newOffset);
    };

    /* --------------------------------------------- 
          FILTER SHOPS BY DATE
    --------------------------------------------- */
    function handleDateFilter(val) {
        setDateFilter(val);
        setRatingFilter({ label: 'All', value: 'All' });
        setSearchResults([]);
    }

    /* --------------------------------------------- 
        FILTER Shops BY RATINGS
    --------------------------------------------- */
    function handleRatingFilter(val) {
        setRatingFilter(val);
        setDateFilter({ label: 'All', value: 'All' });
        setSearchResults([]);
    }

    /* --------------------------------------------- 
          SEARCH Shops HANDLER
    --------------------------------------------- */
    function searchShopsHandler(e) {
        e.preventDefault();
        setSearchSubmitted(true);
        setSearchResults(
            allShops?.filter((shop) => {
                return shop?.shopTitle.toLowerCase().includes(searchQuery?.toLowerCase()?.replace(/\s/g, ''));
            })
        );
        setRatingFilter({ label: 'All', value: 'All' });
        setDateFilter({ label: 'All', value: 'All' });
    }

    /* --------------------------------------------- 
          RESET SEARCH
    --------------------------------------------- */
    function handleResetSearch() {
        setSearchQuery('');
        setSearchResults([]);
        setSearchSubmitted(false);
    }

    /* --------------------------------------------- 
          RESET ALL FILTERS
    --------------------------------------------- */
    function handleResetFilters() {
        setDateFilter({ label: 'All', value: 'All' });
        setRatingFilter({ label: 'All', value: 'All' });
        setCategoryFilter({ label: 'All', value: 'All' });
    }

    return (
        <>
            <PageBanner
                heading='Discover all Shops'
                text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint consequuntur libero rerum esse impedit'
            />
            {fetchingAllShops ? (
                <FetchingDataLoader />
            ) : (
                <section className='py-5'>
                    <div className='container py-5'>
                        <div className='row gy-5'>
                            <div className='col-lg-3'>
                                {/* CATEGORIES FILTER */}
                                <div className='mb-4 pb-2 border-bottom'>
                                    <h6 className='h5 mb-3'>
                                        <BiCategoryAlt className='mb-1 me-2 text-primary' size={20} />
                                        Categories
                                    </h6>
                                    <ul className='list-unstyled p-0'>
                                        {[{ label: 'All', value: 'All' }, ...appSettings.shopCategories]?.map(
                                            (category, index) => {
                                                return (
                                                    <li key={index}>
                                                        <button
                                                            className={`btn-filter mb-2 ${
                                                                categoryFilter?.value === category?.label
                                                                    ? 'active'
                                                                    : ''
                                                            }`}
                                                            onClick={() => {
                                                                setCategoryFilter(category);
                                                                setSearchResults([]);
                                                            }}
                                                        >
                                                            {category?.label}
                                                            <span className='small count text-muted ms-2'>
                                                                (
                                                                {category?.label !== 'All'
                                                                    ? allShops?.filter(
                                                                          (shop) =>
                                                                              shop?.shopCategory === category?.label
                                                                      )?.length
                                                                    : allShops?.length}
                                                                )
                                                            </span>
                                                        </button>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>

                                {/* DATE FILTER */}
                                <div className='mb-4 pb-2 border-bottom'>
                                    <h6 className='h5 mb-3'>
                                        <BsFillCalendar2DateFill className='mb-1 me-2 text-primary' size={20} />
                                        Sort By Date
                                    </h6>
                                    <ul className='list-unstyled p-0 mb-0'>
                                        {[
                                            { label: 'Newest First', value: 'newest' },
                                            { label: 'Oldest First', value: 'oldest' },
                                        ].map((date, index) => {
                                            return (
                                                <li className='mb-2' key={index} onClick={() => handleDateFilter(date)}>
                                                    <button
                                                        className={`btn-filter mb-2 ${
                                                            dateFilter?.value === date?.value ? 'active' : ''
                                                        }`}
                                                    >
                                                        {date?.label}
                                                    </button>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>

                                {/* RATING FILTER */}
                                <div className='mb-4 pb-2 border-bottom'>
                                    <h6 className='h5 mb-3'>
                                        <FaStarHalfAlt className='mb-1 me-2 text-primary' size={20} />
                                        Sort By Rating
                                    </h6>
                                    <ul className='list-unstyled p-0 mb-0'>
                                        {[
                                            { label: 'High Rates', value: 'high' },
                                            { label: 'Low Rates', value: 'low' },
                                        ].map((rating, index) => {
                                            return (
                                                <li
                                                    className='mb-2'
                                                    key={index}
                                                    onClick={() => handleRatingFilter(rating)}
                                                >
                                                    <button
                                                        className={`btn-filter mb-2 ${
                                                            ratingFilter?.value === rating?.value ? 'active' : ''
                                                        }`}
                                                    >
                                                        {rating?.label}
                                                    </button>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>

                                {/* RESET ALL FILTERS */}
                                <button className='btn btn-primary' onClick={handleResetFilters}>
                                    <BiReset className='me-1' size='1.3rem' style={{ transfrom: 'translateY(-3px)' }} />
                                    Reset All Filters
                                </button>
                            </div>

                            <div className='col-lg-9'>
                                {/* SEARCH SHOPS FORM */}
                                <form className='mb-4' onSubmit={searchShopsHandler} data-aos='fade-up'>
                                    <div className='form-floating position-relative'>
                                        <input
                                            id='searchInput'
                                            type='text'
                                            className='form-control'
                                            placeholder='Search for shops'
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            autoComplete='off'
                                        />
                                        <label htmlFor='searchInput'>Search for shops</label>

                                        <div className='position-absolute top-0 end-0 bottom-0 p-2 d-flex align-items-stretch'>
                                            <button
                                                className='btn btn-secondary w-100 me-2'
                                                type='button'
                                                style={{ maxWidth: '4rem' }}
                                                onClick={handleResetSearch}
                                            >
                                                <BiReset
                                                    className='me-1'
                                                    size='1.3rem'
                                                    style={{ transfrom: 'translateY(-3px)' }}
                                                />
                                            </button>
                                            <button
                                                className='btn btn-primary w-100'
                                                type='submit'
                                                style={{ maxWidth: '8rem' }}
                                                disabled={searchQuery.length < 3}
                                            >
                                                <BiSearchAlt
                                                    className='me-1'
                                                    size='1.3rem'
                                                    style={{ transfrom: 'translateY(-3px)' }}
                                                />{' '}
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                    {searchSubmitted && searchResults.length === 0 && (
                                        <div className='invalid-feedback d-block'>
                                            There's no shops match your search
                                        </div>
                                    )}
                                </form>

                                {/* SHOPS */}
                                <div className='row g-2'>
                                    {filteredResult?.length > 0 ? (
                                        filteredResult
                                            .sort((a, b) => {
                                                if (dateFilter.value === 'newest') {
                                                    return b.shopId - a.shopId;
                                                } else if (dateFilter.value === 'oldest') {
                                                    return a.shopId - b.shopId;
                                                }

                                                return 0;
                                            })
                                            .sort((a, b) => {
                                                if (ratingFilter.value === 'high') {
                                                    return b.shopRating - a.shopRating;
                                                } else if (ratingFilter.value === 'low') {
                                                    return a.shopRating - b.shopRating;
                                                }

                                                return 0;
                                            })
                                            ?.slice(itemOffset, endOffset)
                                            ?.map((shop, index) => {
                                                return (
                                                    <div
                                                        className='col-xxl-4 col-md-6'
                                                        data-aos='fade-up'
                                                        data-aos-delay={100 * index}
                                                        key={index}
                                                    >
                                                        <ShopCard {...shop} />
                                                    </div>
                                                );
                                            })
                                    ) : (
                                        <div className='text-center'>
                                            <h2>It's Empty Here</h2>
                                            <p className='text-muted'>There're no shops at the moment.</p>
                                        </div>
                                    )}
                                </div>

                                {/* PAGINATION */}
                                {filteredResult?.length > 0 && (
                                    <div className='react-pagination mt-4 justify-content-center'>
                                        <ReactPaginate
                                            breakLabel='...'
                                            nextLabel={<BiRightArrowAlt />}
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={pageCount}
                                            previousLabel={<BiLeftArrowAlt />}
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default ShopsPage;
