import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import emailjs from '@emailjs/browser';

function useMailer() {
    const sendEmail = (from, to, code, mail) => {
        emailjs
            .send(
                appSettings.mailJServiceKey,
                appSettings.mailJSTemplateID,
                { to_name: to, from_name: from, verify_code: code, reply_to: mail },
                appSettings.mailJSPublicKey
            )
            .then(
                (result) => {
                    toast.success('Mail has been sent');
                },
                (error) => {
                    console.log(error);
                    toast.error('Something went error');
                }
            );
    };

    return { sendEmail };
}

export default useMailer;
