import React from 'react';
import { AiFillShop } from 'react-icons/ai';
import { truncate } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

// HOOKS
import useShop from '../../hooks/useShop';

function ShopVerifyRequestsTable({ verify, handleViewDocs }) {
    const { allShops } = useShop();

    /*** ------------------------------------------------ */
    //      ALL PENDING VIDEOS TABLE COLUMNS
    /*** ------------------------------------------------ */
    const columns = [
        {
            name: 'Shop',
            minWidth: '200px',
            selector: (row) => row?.shopId,
            cell: (row) => (
                <div row={row}>
                    <Link to={`/shops/${row?.shopSlug}`} className='text-reset'>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundImage: `url(${row?.shopLogo})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {row?.shopTitle}
                                </h6>
                                <p className='text-muted small mb-0'>
                                    {row?.shopOwner && truncate(row?.shopOwner, 15, '.....')}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: 'Products',
            minWidth: '140px',
            selector: (row) => row?.shopProducts,
            cell: (row) => <div row={row}>{row?.shopProducts?.length}</div>,
        },
        {
            name: 'Documents',
            minWidth: '200px',
            selector: (row) => row?.shopDocs,
            cell: (row) => (
                <div row={row}>
                    <button className='btn btn-info btn-sm' type='button' onClick={() => handleViewDocs(row?.shopDocs)}>
                        View Documents
                    </button>
                </div>
            ),
        },
        {
            name: 'Action',
            minWidth: '140px',
            selector: (row) => row?.shopId,
            cell: (row) => (
                <div row={row}>
                    <button className='btn btn-sm btn-primary' type='button' onClick={() => verify(row?.shopId)}>
                        Verify
                    </button>
                </div>
            ),
        },
    ];

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex mb-5'>
                    <div className='stats-icon solid-turquoise'>
                        <AiFillShop size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>Shop Verification Requests</h2>
                        <p className='text-muted fw-normal mb-0'>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                        </p>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={allShops?.filter((shop) => shop?.shopDocs?.certificate && !shop?.isVerified)}
                    pagination={allShops?.filter((shop) => shop?.shopDocs?.companyName !== '').length >= 1 && true}
                    responsive
                    theme='solarized'
                />
            </div>
        </div>
    );
}

export default ShopVerifyRequestsTable;
