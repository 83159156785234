import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import UnAuthorized from '../../components/UnAuthorized';
import useShop from '../../hooks/useShop';
import useWeb3 from '../../hooks/useWeb3';
import UpdateShopBanner from './UpdateShopBanner';
import UpdateShopForm from './UpdateShopForm';

function UpdateShopPage() {
    const { account } = useWeb3();
    const { userShops } = useShop();
    const { slug } = useParams();

    const currentShop = useMemo(() => {
        return userShops?.filter((shop) => shop?.shopSlug === slug)[0];
    }, [slug, userShops]);

    return account === currentShop?.shopOwner ? (
        <>
            <UpdateShopBanner {...currentShop} />

            <section className='pb-5'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-8 mx-auto'>
                            <div className='card'>
                                <div className='card-body p-lg-5'>
                                    <header className='mb-4 text-center'>
                                        <h2>Update your shop info</h2>
                                    </header>
                                    {currentShop && <UpdateShopForm {...currentShop} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    ) : (
        <UnAuthorized />
    );
}

export default UpdateShopPage;
