import React from 'react';
import useApp from '../../hooks/useApp';
import useUser from '../../hooks/useUser';

function UserBanner({ targetUser, noTable }) {
    const { owner } = useApp();
    const { userInfo } = useUser();

    return (
        <>
            <div className='page-banner pb-5'>
                <div className='user-banner-info d-flex flex-column align-items-center text-center'>
                    <div
                        className='user-banner-profile mb-3'
                        data-aos='fade-up'
                        style={{ backgroundImage: `url(${targetUser?.profile_picture})` }}
                    ></div>
                    <div className='user-banner-details'>
                        <h2 data-aos='fade-up' data-aos-delay='100'>
                            {targetUser?.full_name}
                        </h2>
                        <p data-aos='fade-up' data-aos-delay='200' className='text-muted'>
                            {targetUser?.address}
                        </p>
                    </div>
                    {!noTable && (
                        <table className='table table-bordered text-start mb-5' data-aos='fade-up' data-aos-delay='300'>
                            <tbody>
                                <tr>
                                    <td className='px-3 bg-gray-900'>Email Address</td>
                                    <td className='px-3'>
                                        {owner === userInfo?.address ? targetUser?.email_address : 'confidential'}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='px-3 bg-gray-900'>Phone Number</td>
                                    <td className='px-3'>
                                        {owner === userInfo?.address ? targetUser?.phone_number : 'confidential'}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='px-3 bg-gray-900'>Country</td>
                                    <td className='px-3'>
                                        {owner === userInfo?.address ? targetUser?.location : 'confidential'}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
}

export default UserBanner;
