import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { countries } from '../../helpers/constants';
import { appSettings } from '../../helpers/settings';
import { Tooltip } from 'react-tippy';
import { toast } from 'react-toastify';
import { MdInfo } from 'react-icons/md';
import useWeb3 from '../../hooks/useWeb3';
import ConnectWalletHander from '../../components/general/ConnectWalletHandler';
import useApp from '../../hooks/useApp';
import useUser from '../../hooks/useUser';
import useMailer from '../../components/general/Mailer';

import { useContractWrite } from 'wagmi';

// IPFS CREATE HOST
const auth = 'Basic ' + Buffer.from(appSettings.IPFSProjectID + ':' + appSettings.IPFSSecret).toString('base64');
const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

function SignUpForm() {
    const { account } = useWeb3();
    const { sendEmail } = useMailer();
    const { setTransactionLoading } = useApp();
    const { userContract, userInfo, usersList, userContractAbi, loadUserInfo, loadUsersList } = useUser();
    const [submit, setSubmit] = useState(false);
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
          SEND VERIFICATION EMAIL ON USER REGISTER
    --------------------------------------------- */
    useEffect(() => {
        if (userInfo?.verification_code && submit) {
            sendEmail(
                appSettings?.brandName,
                userInfo?.full_name,
                userInfo?.verification_code,
                userInfo?.email_address
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo, submit]);

    const { write: web3RegisterHandler } = useContractWrite({
        address: userContract?.address,
        abi: userContractAbi,
        functionName: 'createUser',
        onMutate() {
            setTransactionLoading(true);
        },
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadUserInfo(userContract, account);
                loadUsersList(userContract);
                toast.success('Great, you have successfully registered');
                setSubmit(true);
            }, 5000);
        },
        onError() {
            setSubmit(false);
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
          HANDLE REGISTER FORM SUBMIT
    --------------------------------------------- */
    async function handleFormSubmit(data) {
        const ipfsProfile = await ipfs.add(data?.profile[0]);
        setTransactionLoading(true);
        setSubmit(true);

        if (ipfsProfile) {
            web3RegisterHandler({
                recklesslySetUnpreparedArgs: [
                    [
                        data?.full_name,
                        data?.email,
                        data?.location?.label,
                        `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsProfile.path}`,
                        data?.mobile,
                    ],
                    data?.isVendor ? 0 : 1,
                ],
            });
        }
    }

    /* --------------------------------------------- 
          VALIDATE PROFILE IMAGE FILE TYPE
    --------------------------------------------- */
    const validateImageFileType = (file) => {
        const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (!validImageTypes.includes(file[0].type)) {
            return 'Invalid file type. Please upload a PNG, JPEG, or JPG image.';
        }
    };

    /* --------------------------------------------- 
          VALIDATE UNIQUE EMAIL ADDRESS
    --------------------------------------------- */
    const validateUniqueEmail = (email) => {
        if (usersList?.map((user) => user?.email_address)?.includes(email)) {
            return 'This email is already registered';
        }
    };

    return (
        <>
            {!submit && (
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className='row g-4'>
                        {/* FULL NAME */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='fullName'>
                                Full Name
                            </label>
                            <input
                                type='text'
                                className={`form-control ${errors.full_name ? 'is-invalid' : ''}`}
                                id='fullName'
                                placeholder='Enter your full name'
                                name='full_name'
                                {...register('full_name', {
                                    required: {
                                        value: true,
                                        message: 'Please enter your full name',
                                    },
                                    minLength: {
                                        value: 8,
                                        message: 'Full name must be at least 8 characters',
                                    },
                                })}
                            />
                            {errors.full_name && <span className='invalid-feedback'>{errors.full_name?.message}</span>}
                        </div>

                        {/* EMAIL ADDRESS */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='email'>
                                Email Address
                            </label>
                            <input
                                type='email'
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                id='email'
                                placeholder='Enter your email addres'
                                name='email'
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: 'Please enter your email address',
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Please enter a valid email format',
                                    },
                                    validate: validateUniqueEmail,
                                })}
                            />
                            {errors.email && <span className='invalid-feedback'>{errors.email?.message}</span>}
                        </div>

                        {/* PHONE NUMBER */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='phoneNumber'>
                                Phone Number
                            </label>
                            <input
                                type='tel'
                                className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                                id='phoneNumber'
                                placeholder='Enter your phone number with country code'
                                name='mobile'
                                {...register('mobile', {
                                    required: {
                                        value: true,
                                        message: 'Please enter your phone number',
                                    },
                                    pattern: {
                                        value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                        message: 'Invalide phone numberm e.g +44 1234 567890',
                                    },
                                })}
                            />
                            {errors.mobile && <span className='invalid-feedback'>{errors.mobile?.message}</span>}
                        </div>

                        {/* LOCATION */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='location'>
                                Location
                            </label>
                            <Controller
                                name='location'
                                control={control}
                                rules={{ required: 'Please select an option' }}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            options={countries}
                                            id='location'
                                            className={`border-0 shadow-sm ${errors.location ? 'is-invalid' : ''}`}
                                            classNamePrefix='select'
                                            placeholder='Select'
                                            isSearchable={true}
                                            {...field}
                                        />
                                        {errors.location && (
                                            <span className='invalid-feedback'>{errors.location?.message}</span>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className='col-lg-12'>
                            <label className='form-label' htmlFor='profile'>
                                Profile Picture
                            </label>
                            <input
                                type='file'
                                className={`form-control ${errors.profile ? 'is-invalid' : ''}`}
                                id='profile'
                                accept='.jpg, .png, .jpeg'
                                name='profile'
                                {...register('profile', {
                                    required: {
                                        value: true,
                                        message: 'Please upload your profile image',
                                    },
                                    validate: validateImageFileType,
                                })}
                            />
                            {errors.profile && <span className='invalid-feedback'>{errors.profile?.message}</span>}
                        </div>
                        <div className='col-12 d-flex align-items-center'>
                            <div className='form-check d-flex align-items-center'>
                                <input
                                    id='isVendor'
                                    type='checkbox'
                                    className='form-check-input'
                                    name='isVendor'
                                    {...register('isVendor', {
                                        required: false,
                                    })}
                                />
                                <label htmlFor='isVendor' className='form-check-label ms-2'>
                                    Signup as a vendor
                                </label>
                            </div>
                            <Tooltip
                                title='Registering as a vendor allows you to create a shop and sell products, but please note that you will not be able to make purchases with this account. If you would like to make purchases, you will need to create a separate account.'
                                position='top'
                                trigger='click'
                            >
                                <MdInfo className='ms-2 cursor-pointer text-muted' size={18} />
                            </Tooltip>
                        </div>
                        <div className='col-12'>
                            {account ? (
                                <button className='btn btn-primary' type='submit'>
                                    Signup now
                                </button>
                            ) : (
                                <ConnectWalletHander />
                            )}
                        </div>
                    </div>
                </form>
            )}
        </>
    );
}

export default SignUpForm;
