import React from 'react';
import useUser from '../../hooks/useUser';

function SideTabs({ handleActiveTab, activeTab }) {
    const { userInfo } = useUser();
    return (
        <>
            <div className='card mb-0'>
                <div className='card-body p-3'>
                    {userInfo?.is_vendor ? (
                        <>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'shops' ? 'active' : ''}`}
                                id='shops'
                                onClick={handleActiveTab}
                            >
                                My Shops
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'products' ? 'active' : ''}`}
                                id='products'
                                onClick={handleActiveTab}
                            >
                                My Products
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'orders' ? 'active' : ''}`}
                                id='orders'
                                onClick={handleActiveTab}
                            >
                                My Orders
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'profits' ? 'active' : ''}`}
                                id='profits'
                                onClick={handleActiveTab}
                            >
                                My Profits
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'disputes' ? 'active' : ''}`}
                                id='disputes'
                                onClick={handleActiveTab}
                            >
                                Disputes
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'purchases' ? 'active' : ''}`}
                                id='purchases'
                                onClick={handleActiveTab}
                            >
                                My Purchases
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'orders' ? 'active' : ''}`}
                                id='orders'
                                onClick={handleActiveTab}
                            >
                                Order History
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'profits' ? 'active' : ''}`}
                                id='profits'
                                onClick={handleActiveTab}
                            >
                                My Profits
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'disputes' ? 'active' : ''}`}
                                id='disputes'
                                onClick={handleActiveTab}
                            >
                                Disputes
                            </button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default SideTabs;
