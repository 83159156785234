import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import FetchingDataLoader from '../../components/general/FetchingDataLoader';
import NotFound from '../../components/NotFound';
import Popup from '../../components/general/Popup';
import useShop from '../../hooks/useShop';
import ProductGallery from './ProductGallery';
import ProductInfo from './ProductInfo';
import ProductRatingForm from './ProductRatingForm';
import ProductSpecs from './ProductSpecs';
import SimilarProducts from './SimilarProducts';
import useUser from '../../hooks/useUser';
import ProductReviews from './ProductReviews';
import { appSettings } from '../../helpers/settings';
import useOrder from '../../hooks/useOrder';
import { readContract } from '@wagmi/core';

function ProductSinglePage() {
    const { shopContract, totalProducts, fetchingAllProducts, productReviews, shopContractAbi, loadProductReviews } =
        useShop();
    const { allOrders } = useOrder();
    const { userInfo } = useUser();
    const { slug } = useParams();
    const [ratingRequested, setRatingRequested] = useState(false);
    const [buyersAddresses, setBuyersAddresses] = useState([]);

    // GET TARGET PRODUCT ------------------------
    const currentProduct = useMemo(() => {
        return totalProducts?.filter((product) => product?.productSlug === slug)[0];
    }, [slug, totalProducts]);

    const isBuyer = useMemo(() => {
        const productOrderForUser = allOrders
            ?.filter(
                (order) =>
                    order?.orderBuyerAddress === userInfo?.address &&
                    order?.orderProductId === currentProduct?.productId
            )
            ?.map((order) => order?.orderStatus);

        return Boolean(productOrderForUser?.includes('delivered'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyersAddresses, userInfo, currentProduct]);

    /* --------------------------------------------- 
          CHANGE PAGE TAB TITLE
    --------------------------------------------- */
    useEffect(() => {
        document.title = `${appSettings?.brandName} | ${currentProduct?.productName}`;
    }, [currentProduct]);

    /* --------------------------------------------- 
          GET THE PRODUCT BUYERS
    --------------------------------------------- */
    async function getProductBuers() {
        if (currentProduct) {
            const productBuyers = await readContract({
                address: shopContract.address,
                abi: shopContractAbi,
                functionName: 'productBuyers',
                overrides: currentProduct?.productId,
            });

            setBuyersAddresses(productBuyers?.map((buyer) => buyer[0]));
        }
    }

    /* --------------------------------------------- 
          GET PRODUCT BUYERS
    --------------------------------------------- */
    useEffect(() => {
        let signal = true;
        signal && getProductBuers();
        return () => (signal = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProduct]);

    /* --------------------------------------------- 
          GET PRODUCT REVIEWS
    --------------------------------------------- */
    useEffect(() => {
        if (shopContract && currentProduct) {
            loadProductReviews(shopContract, currentProduct?.productId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopContract, currentProduct]);

    return (
        <>
            {fetchingAllProducts ? (
                <FetchingDataLoader />
            ) : currentProduct ? (
                <section className='py-5 mt-5'>
                    <div className='container py-5'>
                        <div className='row gy-5 mb-5'>
                            {/* PRODUCT GALLERY */}
                            <div className='col-lg-6'>
                                {currentProduct?.productGallery && (
                                    <div className='product-gallery-holder'>
                                        <ProductGallery gallery={currentProduct?.productGallery} />
                                    </div>
                                )}
                            </div>

                            {/* PRODUCT INFORMATION */}
                            <div className='col-lg-6'>
                                {currentProduct && (
                                    <ProductInfo
                                        refreshBuyers={() => getProductBuers(currentProduct?.productId)}
                                        setRatingRequested={() => setRatingRequested(true)}
                                        isBuyer={isBuyer}
                                        productReviews={productReviews}
                                        {...currentProduct}
                                    />
                                )}
                            </div>
                        </div>

                        {/* PRODUCT SPECIFICATIONS */}
                        <div className='row mb-5'>
                            <div className='col-lg-8'>
                                <h2 className='h1 mb-4'>Product Specs</h2>
                                {currentProduct && <ProductSpecs {...currentProduct} />}
                            </div>
                        </div>

                        {/* PRODUCT REVIEWS */}
                        <div className='row'>
                            <div className='col-lg-5'>
                                {currentProduct && <ProductReviews productReviews={productReviews} />}
                            </div>
                        </div>

                        {/* SIMILAR PRODUCTS */}
                        {currentProduct && (
                            <div className='mt-5'>
                                <SimilarProducts productCategory={currentProduct?.productCategory} />
                            </div>
                        )}

                        {/* RATE PRODUCT FORM */}
                        {currentProduct && ratingRequested && (
                            <Popup closeModal={setRatingRequested} containerClass='col-lg-6'>
                                <ProductRatingForm
                                    closeModal={() => setRatingRequested(false)}
                                    productId={currentProduct?.productId}
                                />
                            </Popup>
                        )}
                    </div>
                </section>
            ) : (
                <NotFound />
            )}
        </>
    );
}

export default ProductSinglePage;
