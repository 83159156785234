import React from 'react';
import { HiPresentationChartBar } from 'react-icons/hi';
import { configEtherScanUrl, formatDate } from '../../helpers/utils';
import DataTable from 'react-data-table-component';

// HOOKS
import useApp from '../../hooks/useApp';
import useWeb3 from '../../hooks/useWeb3';
import UserBlock from '../../components/general/UserBlock';

function UserApprovedVideosTable() {
    const { activities } = useApp();
    const { networkId } = useWeb3();

    /*** ------------------------------------------------- */
    //      ACTIVITIES TABLE COLUMNS
    /*** ------------------------------------------------- */
    const columns = [
        {
            name: 'User',
            selector: (row) => row?.userAddress,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <a
                        href={configEtherScanUrl(networkId, row?.userAddress)}
                        rel='noopener noreferrer'
                        className='text-reset'
                        target='_blank'
                    >
                        <UserBlock
                            isPrivate={true}
                            profile_picture={row?.userProfile}
                            address={row?.userAddress}
                            full_name={row?.userName}
                        />
                    </a>
                </div>
            ),
        },
        {
            name: 'Time',
            minWidth: '200px',
            selector: (row) => row?.time,
            cell: (row) => (
                <div row={row}>
                    <small>{formatDate(row?.time)}</small>
                </div>
            ),
        },
        {
            name: 'Action',
            selector: (row) => row?.action,
            cell: (row) => (
                <div row={row}>
                    {row?.action === 'Mint Video' ? (
                        <div className='btn btn-opac-success cursor-default px-3 py-0'>
                            <span className='small fw-light'>{row?.action}</span>
                        </div>
                    ) : row?.action === 'Approve minted video' ? (
                        <div className='btn btn-opac-success cursor-default px-3 py-0'>
                            <span className='small fw-light'>{row?.action}</span>
                        </div>
                    ) : row?.action === 'Play Video' ? (
                        <div className='btn btn-opac-info cursor-default px-3 py-0'>
                            <span className='small fw-light'>Subscribed to Video</span>
                        </div>
                    ) : row?.action === 'Added to Wish list' ? (
                        <div className='btn btn-opac-warning cursor-default px-3 py-0'>
                            <span className='small fw-light'>Added video to Watchlist</span>
                        </div>
                    ) : row?.action === 'New Subscriber' ? (
                        <div className='btn btn-opac-secondary cursor-default px-3 py-0'>
                            <span className='small fw-light'>{row?.action}</span>
                        </div>
                    ) : row?.action === 'New Channel Created' ? (
                        <div className='btn btn-opac-primary cursor-default px-3 py-0'>
                            <span className='small fw-light'>Created a channel</span>
                        </div>
                    ) : row?.action === 'New Video Uploded' ? (
                        <div className='btn btn-opac-secondary cursor-default px-3 py-0'>
                            <span className='small fw-light'>{row?.action}</span>
                        </div>
                    ) : row?.action === 'The uploaded video has been approved by the administrator' ? (
                        <div className='btn btn-opac-success cursor-default px-3 py-0'>
                            <span className='small fw-light'>Approved Video</span>
                        </div>
                    ) : row?.action === 'New subscriber' ? (
                        <div className='btn btn-opac-success cursor-default px-3 py-0'>
                            <span className='small fw-light'>New Subscription</span>
                        </div>
                    ) : row?.action === 'A new referral code has been added' ? (
                        <div className='btn btn-opac-info cursor-default px-3 py-0'>
                            <span className='small fw-light'>Generated Referral Code</span>
                        </div>
                    ) : (
                        <span className='small'>{row?.action}</span>
                    )}
                </div>
            ),
        },
    ];

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex a;ign-items-center mb-5'>
                    <div className='stats-icon solid-cyan'>
                        <HiPresentationChartBar size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>Latest Activities</h2>
                        <p className='text-muted fw-normal mb-0'>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                        </p>
                    </div>
                </div>

                {activities.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={activities?.sort((a, b) => b?.time - a?.time)}
                        pagination={activities.length >= 1 && true}
                        responsive
                        theme='solarized'
                    />
                ) : (
                    <p className='mb-0'>There're no activities yet to display</p>
                )}
            </div>
        </div>
    );
}

export default UserApprovedVideosTable;
