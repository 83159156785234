import React from 'react';
import { RiShoppingBagFill } from 'react-icons/ri';
import { truncate, truncateStart } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

// HOOKS
import useShop from '../../hooks/useShop';
import { appSettings } from '../../helpers/settings';

function AllProductsTable({ setIds }) {
    const { allProducts } = useShop();

    /*** ------------------------------------------------ */
    //      ALL PENDING VIDEOS TABLE COLUMNS
    /*** ------------------------------------------------ */
    const columns = [
        {
            name: 'Product',
            selector: (row) => row?.title,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <Link className='text-reset' to={`/products/${row.productSlug}`}>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    background: `url(${row?.productGallery[0]})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1'>{row?.productName}</h6>
                                <p className='text-muted small mb-0'>
                                    {truncateStart(row?.productDescription, 30, '...')}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: 'Shop',
            minWidth: '200px',
            selector: (row) => row?.productShopId,
            cell: (row) => (
                <div row={row}>
                    <Link to={`/shops/${row?.productShopSlug}`} className='text-reset'>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundImage: `url(${row?.productShopLogo})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {row?.productShopName}
                                </h6>
                                <p className='text-muted small mb-0'>
                                    {row?.productOwner && truncate(row?.productOwner, 15, '.....')}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },

        {
            name: 'Price',
            minWidth: '140px',
            selector: (row) => row?.productPrice,
            cell: (row) => (
                <div row={row}>
                    {row?.productPrice} {appSettings?.currency}
                </div>
            ),
        },
        {
            name: 'Action',
            minWidth: '140px',
            selector: (row) => row?.productId,
            cell: (row) => (
                <div row={row}>
                    <div className='form-check'>
                        <input
                            type='checkbox'
                            id={`approved_product_${row?.productId}`}
                            className='form-check-input'
                            onChange={(e) =>
                                e.target.checked
                                    ? setIds((prev) => [...prev, Number(row.productId)])
                                    : setIds((prev) => prev.filter((id) => id !== Number(row.productId)))
                            }
                        />
                        <label htmlFor={`approved_product_${row?.productId}`} className='form-check-label'>
                            Select
                        </label>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex mb-5'>
                    <div className='stats-icon solid-cyan'>
                        <RiShoppingBagFill size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>Approved Products</h2>
                        <p className='text-muted fw-normal mb-0'>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                        </p>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={allProducts}
                    pagination={allProducts.length >= 1 && true}
                    responsive
                    theme='solarized'
                />
            </div>
        </div>
    );
}

export default AllProductsTable;
