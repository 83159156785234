import React, { useReducer } from 'react';
import { readContract } from '@wagmi/core';

import AppContext from './app-context';

const defaultAppState = {
    contract: null,
    abi: null,
    owner: '',
    themeMode: 'light',
    activities: [],
    mintUploadProgress: 0,
    transactionLoading: false,
    uploadingProgress: false,
};

const appReducer = (state, action) => {
    if (action.type === 'SWITCHMODE') {
        return {
            ...state,
            themeMode: action.themeMode,
        };
    }
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            contract: action.contract,
        };
    }
    if (action.type === 'GET_ABI') {
        return {
            ...state,
            abi: action.abi,
        };
    }
    if (action.type === 'LOADING') {
        return {
            ...state,
            transactionLoading: action.loading,
        };
    }
    if (action.type === 'UPLOADING_PROGRESS') {
        return {
            ...state,
            uploadingProgress: action.loading,
        };
    }

    if (action.type === 'GET_UPLOAD_PROGRESS') {
        return {
            ...state,
            mintUploadProgress: action.progress,
        };
    }
    if (action.type === 'GET_OWNER') {
        return {
            ...state,
            owner: action.owner,
        };
    }
    if (action.type === 'LOAD_ACTIVITIES') {
        return {
            ...state,
            activities: action.activities.map((el) => {
                return {
                    userAddress: el[0],
                    userName: el[1][0],
                    userProfile: el[1][3],
                    time: Number(el[2]) * 1000,
                    action: el[3],
                };
            }),
        };
    }

    return defaultAppState;
};

const AppProvider = (props) => {
    const [appState, dispatchAppAction] = useReducer(appReducer, defaultAppState);

    const setTransactionLoadingHandler = (loading) => {
        dispatchAppAction({ type: 'LOADING', loading: loading });
    };

    const switchModeHandler = (themeMode) => {
        dispatchAppAction({ type: 'SWITCHMODE', themeMode: themeMode });
    };

    const setUploadingProgressHandler = (loading) => {
        dispatchAppAction({ type: 'UPLOADING_PROGRESS', loading: loading });
    };

    const getContractAbiHandler = (abi) => {
        dispatchAppAction({ type: 'GET_ABI', abi: abi });
    };

    const loadContractHandler = (contract) => {
        dispatchAppAction({ type: 'CONTRACT', contract: contract });
        return contract;
    };

    const loadAppOwnerHandler = async (contract) => {
        const owner = await readContract({
            address: contract.address,
            abi: appState.abi,
            functionName: 'owner',
        });
        dispatchAppAction({ type: 'GET_OWNER', owner: owner });
        return owner;
    };

    const loadActivitiesHandler = async (contract, abi) => {
        const activities = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'actvityLogs',
        });
        dispatchAppAction({ type: 'LOAD_ACTIVITIES', activities: activities });
        return activities;
    };

    const loadMintUploadProgressHandler = (progress) => {
        dispatchAppAction({ type: 'GET_MINT_PROGRESS', progress: progress });
        return progress;
    };

    const appContext = {
        contract: appState.contract,
        abi: appState.abi,
        themeMode: appState.themeMode,
        owner: appState.owner,
        mintUploadProgress: appState.mintUploadProgress,
        transactionLoading: appState.transactionLoading,
        uploadingProgress: appState.uploadingProgress,
        activities: appState.activities,
        loadContract: loadContractHandler,
        loadAppOwner: loadAppOwnerHandler,
        setTransactionLoading: setTransactionLoadingHandler,
        loadMintUploadProgress: loadMintUploadProgressHandler,
        setUploadingProgress: setUploadingProgressHandler,
        loadActivities: loadActivitiesHandler,
        switchMode: switchModeHandler,
        getContractAbi: getContractAbiHandler,
    };

    return <AppContext.Provider value={appContext}>{props.children}</AppContext.Provider>;
};

export default AppProvider;
