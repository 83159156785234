import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import useApp from '../../hooks/useApp';
import useWeb3 from '../../hooks/useWeb3';
import { toast } from 'react-toastify';
import { IoDocumentAttachSharp } from 'react-icons/io5';
import { appSettings } from '../../helpers/settings';
import emailjs from '@emailjs/browser';
import useShop from '../../hooks/useShop';
import { useContractWrite } from 'wagmi';

// IPFS CREATE HOST
const auth = 'Basic ' + Buffer.from(appSettings.IPFSProjectID + ':' + appSettings.IPFSSecret).toString('base64');
const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

function VerifyForm({ shopId, closeModal, shopTitle }) {
    const { contract, setTransactionLoading } = useApp();
    const { shopContractAbi, loadAllShops, loadAllProducts, loadUserShops } = useShop();
    const { account } = useWeb3();
    const [shopDocs, setShopDocs] = useState({});

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
              VERIFY SHOP FUNCTION
     --------------------------------------------- */
    const { write: web3VerifyShop } = useContractWrite({
        address: contract?.address,
        abi: shopContractAbi,
        functionName: 'addDocumentToShop',
        onSuccess() {
            setTimeout(() => {
                closeModal();
                setTransactionLoading(false);
                loadAllShops(contract);
                loadUserShops(contract, account);
                loadAllProducts(contract);
                toast.success('Great! your review has been submitted');
            }, 5000);
            emailjs
                .send(
                    appSettings.mailJServiceKey,
                    appSettings.mailJSVerifyTemplateID,
                    {
                        ...shopDocs,
                    },
                    appSettings.mailJSPublicKey
                )
                .then(
                    (result) => {
                        toast.success('Mail has been sent');
                    },
                    (error) => {
                        console.log(error);
                        toast.error('Something went error');
                    }
                );
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
          VALIDATE TWITTER URL
    --------------------------------------------- */
    const validateTwitterUrl = (value) => {
        const regex = /^https?:\/\/(?:www\.)?twitter\.com\/(?:#!\/)?(\w+)\/?$/i;
        if (!regex.test(value)) {
            return 'Please enter a valid Twitter URL';
        }
    };

    /* --------------------------------------------- 
          VALIDATE PDF FILE
    --------------------------------------------- */
    const validatePdfFile = (file) => {
        if (file[0].type !== 'application/pdf') {
            return 'Only PDF files are allowed';
        }
    };

    /* --------------------------------------------- 
          SUBMIT PRODUCT REVIEW FUNCTION
    --------------------------------------------- */
    async function handleRatingSubmit(data) {
        setTransactionLoading(true);

        const ipfsCertificate = await ipfs.add(data?.certificate[0]);

        if (ipfsCertificate) {
            setShopDocs({
                from_name: appSettings?.brandName,
                shop_name: shopTitle,
                company_name: data?.company_name,
                company_address: data?.company_address,
                phone_number: data?.phone_number,
                email: data?.email,
                twitter_profile: data?.twitter_profile,
                certificate: `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsCertificate?.path}`,
                reply_to: appSettings?.shopVerifyMail,
            });

            web3VerifyShop({
                recklesslySetUnpreparedArgs: [
                    shopId,
                    [
                        data?.company_name,
                        data?.company_address,
                        data?.phone_number,
                        data?.email,
                        `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsCertificate?.path}`,
                        data?.twitter_profile,
                    ],
                ],
            });
        }
    }

    return (
        <>
            <header className='text-center mb-4'>
                <h3>Verify your shop</h3>
                <p className='text-muted'>Submit the below documents and our team will review it to verify your shop</p>
            </header>

            <form className='row g-3' onSubmit={handleSubmit(handleRatingSubmit)}>
                {/* COMPANY NAME */}
                <div className='col-12'>
                    <label className='form-label'>Company Name</label>
                    <input
                        type='text'
                        placeholder='Type your company name'
                        name='company_name'
                        className={`form-control ${errors.company_name ? 'is-invalid' : ''}`}
                        {...register('company_name', {
                            required: {
                                value: true,
                                message: 'Please add your company name',
                            },
                        })}
                    />
                    {errors.company_name && <span className='invalid-feedback'>{errors.company_name.message}</span>}
                </div>

                {/* COMPANY ADDRESS */}
                <div className='col-12'>
                    <label className='form-label'>Company Address</label>
                    <textarea
                        type='text'
                        placeholder='Type your company address'
                        name='company_address'
                        className={`form-control ${errors.company_address ? 'is-invalid' : ''}`}
                        {...register('company_address', {
                            required: {
                                value: true,
                                message: 'Please add your company address',
                            },
                        })}
                    ></textarea>
                    {errors.company_address && (
                        <span className='invalid-feedback'>{errors.company_address?.message}</span>
                    )}
                </div>

                {/* PHONE NUMBER */}
                <div className='col-lg-6'>
                    <label className='form-label' htmlFor='phoneNumber'>
                        Phone Number
                    </label>
                    <input
                        type='tel'
                        className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                        id='phoneNumber'
                        placeholder='Enter your phone number with country code'
                        name='phone_number'
                        {...register('phone_number', {
                            required: {
                                value: true,
                                message: 'Please enter your phone number',
                            },
                            pattern: {
                                value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                message: 'Invalide phone numberm e.g +44 1234 567890',
                            },
                        })}
                    />
                    {errors.phone_number && <span className='invalid-feedback'>{errors.phone_number?.message}</span>}
                </div>

                {/* EMAIL ADDRESS */}
                <div className='col-lg-6'>
                    <label className='form-label' htmlFor='email'>
                        Email Address
                    </label>
                    <input
                        type='email'
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        id='email'
                        placeholder='Enter your email addres'
                        name='email'
                        {...register('email', {
                            required: {
                                value: true,
                                message: 'Please enter your email address',
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Please enter a valid email format',
                            },
                        })}
                    />
                    {errors.email && <span className='invalid-feedback'>{errors.email?.message}</span>}
                </div>

                {/* TWITTER PROFILE */}
                <div className='col-12'>
                    <label className='form-label'>Twitter Profile</label>
                    <input
                        type='text'
                        placeholder='Type your twitter profile'
                        name='twitter_profile'
                        className={`form-control ${errors.twitter_profile ? 'is-invalid' : ''}`}
                        {...register('twitter_profile', {
                            required: {
                                value: true,
                                message: 'Please add your twitter profile',
                            },
                            validate: validateTwitterUrl,
                        })}
                    />
                    {errors.twitter_profile && (
                        <span className='invalid-feedback'>{errors.twitter_profile?.message}</span>
                    )}
                </div>

                <div className='col-lg-12'>
                    <label className='form-label' htmlFor='certificate'>
                        Company Issue Certificate
                    </label>
                    <input
                        type='file'
                        className={`form-control ${errors.certificate ? 'is-invalid' : ''}`}
                        id='certificate'
                        accept='.pdf'
                        name='certificate'
                        {...register('certificate', {
                            required: {
                                value: true,
                                message: 'Please upload your company issue certificate file',
                            },
                            validate: validatePdfFile,
                        })}
                    />
                    {errors.certificate && <span className='invalid-feedback'>{errors.certificate?.message}</span>}
                </div>

                <div className='col-12'>
                    <button className='btn btn-primary' type='submit'>
                        <IoDocumentAttachSharp className='me-2 mb-1' size={20} />
                        Submit your documents
                    </button>
                </div>
            </form>
        </>
    );
}

export default VerifyForm;
