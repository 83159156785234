import React, { useMemo } from 'react';
import parse from 'html-react-parser';

function ProductSpecs({ productWeight, productDimensions, productInfo, returnPolicy, productCondition }) {
    const formattedDims = useMemo(() => {
        if (productDimensions !== '') {
            return productDimensions.split('-');
        } else {
            return '';
        }
    }, [productDimensions]);

    return (
        <>
            <div className='row'>
                <div className='col-lg-7'>
                    <table className='table table-bordered mb-5'>
                        <tbody>
                            <tr>
                                <td className='px-3 bg-gray-900'>Condition</td>
                                <td className='px-3'>{productCondition}</td>
                            </tr>
                            <tr>
                                <td className='px-3 bg-gray-900'>Weight</td>
                                <td className='px-3'>{productWeight} KG</td>
                            </tr>
                            <tr>
                                <td className='px-3 bg-gray-900'>Width</td>
                                <td className='px-3'>
                                    {productDimensions !== '' ? `${formattedDims[0]} CM` : 'No data provided'}{' '}
                                </td>
                            </tr>
                            <tr>
                                <td className='px-3 bg-gray-900'>Height</td>
                                <td className='px-3'>
                                    {productDimensions !== '' ? `${formattedDims[1]} CM` : 'No data provided'}
                                </td>
                            </tr>
                            <tr>
                                <td className='px-3 bg-gray-900'>Length</td>
                                <td className='px-3'>
                                    {productDimensions !== '' ? `${formattedDims[2]} CM` : 'No data provided'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {returnPolicy !== '' && (
                        <div className='mb-5'>
                            <h2>Retrun Policy</h2>
                            <p className='text-gray-500'>{returnPolicy}</p>
                        </div>
                    )}
                    {parse(JSON.parse(productInfo))}
                </div>
            </div>
        </>
    );
}

export default ProductSpecs;
