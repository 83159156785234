import React, { useMemo, useState } from 'react';
import useShop from '../../hooks/useShop';
import ProductCard from '../../components/general/ProductCard';
import { Link } from 'react-router-dom';
import useWeb3 from '../../hooks/useWeb3';
import { BiRightArrowAlt, BiLeftArrowAlt } from 'react-icons/bi';
import ReactPaginate from 'react-paginate';

function UserProducts() {
    const { allProducts } = useShop();
    const { account } = useWeb3();
    const userProducts = useMemo(() => {
        return allProducts?.filter((product) => product?.productOwner === account);
    }, [account, allProducts]);

    // PAGINATION VARIABLES ---------------------------------
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 16;
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(userProducts?.length / itemsPerPage);

    /* --------------------------------------------- 
          MOVE BETWEEN PAGES
    --------------------------------------------- */
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % userProducts?.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            {userProducts?.length > 0 ? (
                <div className='row g-3'>
                    {userProducts
                        ?.filter((product) => !product?.shopIsBlocked)
                        ?.slice(itemOffset, endOffset)
                        ?.map((product, index) => {
                            return (
                                <div
                                    className='col-xxl-4 col-xl-6'
                                    data-aos='fade-up'
                                    data-aos-delay={index * 50}
                                    key={index}
                                >
                                    <ProductCard {...product} />
                                </div>
                            );
                        })}

                    {/* PAGINATION */}
                    {userProducts?.length > 0 && (
                        <div className='col-12'>
                            <div className='react-pagination mt-4 justify-content-start'>
                                <ReactPaginate
                                    breakLabel='...'
                                    nextLabel={<BiRightArrowAlt />}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel={<BiLeftArrowAlt />}
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div>
                                <h2>It's Empty Here</h2>
                                <p className='text-muted'>There're no products at the moment.</p>
                            </div>
                        </div>
                    </div>
                    You don't have any shops at the moment. <Link to='/shops/create'>Create New One</Link>
                </div>
            )}
        </>
    );
}

export default UserProducts;
