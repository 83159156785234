import React from 'react';
import PageBanner from '../../components/general/PageBanner';
// import UserBlock from '../../components/general/UserBlock';
import SignUpForm from './SignupForm';

function SignUpPage() {
    return (
        <>
            <PageBanner heading='Create an Account' text='Lorem ipsum dolor sit amet consectetur adipisicing elit.' />
            <section className='py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-9 mx-auto'>
                            <div className='card'>
                                <div className='card-body p-sm-5'>
                                    <h2 className='text-center mb-4'>Fill the form with your info</h2>
                                    <SignUpForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SignUpPage;
