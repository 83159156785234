import React, { useMemo } from 'react';
import useShop from '../../hooks/useShop';
import ProductCard from '../../components/general/ProductCard';

function SimilarProducts({ productCategory }) {
    const { allProducts } = useShop();

    const similarProducts = useMemo(() => {
        return allProducts.filter((product) => product?.productCategory !== productCategory);
    }, [productCategory, allProducts]);

    return (
        <>
            {similarProducts?.length > 0 && (
                <>
                    <h2 className='mb-4'>You might also like</h2>
                    <div className='row g-2'>
                        {similarProducts?.slice(0, 3).map((product, index) => {
                            return (
                                <div className='col-lg-4 col-md-6' key={index}>
                                    <ProductCard {...product} />
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
}

export default SimilarProducts;
