import React from 'react';
import Popup from '../../components/general/Popup';
import useOrder from '../../hooks/useOrder';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import useApp from '../../hooks/useApp';
import { useContractWrite } from 'wagmi';

function DisputeFormModal({ disputeRequested, setDisputeRequested, orderInfo }) {
    const { setTransactionLoading } = useApp();
    const { orderStatsContract, orderStatsContractAbi, loadAllOrders, loadAllDisputes } = useOrder();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
              MAKE DISPUTE FUNCTiON
     --------------------------------------------- */
    const { write: web3MakeDispute } = useContractWrite({
        address: orderStatsContract?.address,
        abi: orderStatsContractAbi,
        functionName: 'dispute',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllOrders(orderStatsContract);
                loadAllDisputes(orderStatsContract);
                setDisputeRequested();
                toast.success('Your dispute has been submitted');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
          MAKE A DISPUTE HANDLER
    --------------------------------------------- */
    function makeDisputeHandler(data) {
        web3MakeDispute({
            recklesslySetUnpreparedArgs: [data?.dispute_reason, data?.dispute_description, orderInfo?.orderId],
        });
    }

    return (
        <>
            {disputeRequested && (
                <Popup closeModal={setDisputeRequested}>
                    <header className='mb-4 text-center'>
                        <h2 className='h1'>Make a Dispute</h2>
                        {orderInfo?.orderId}
                    </header>

                    <form onSubmit={handleSubmit(makeDisputeHandler)}>
                        <div className='row g-4'>
                            <div className='col-12'>
                                <label className='form-label'>Reason for dispute</label>
                                <input
                                    type='text'
                                    name='dispute_reason'
                                    className={`form-control ${errors.dispute_reason ? 'is-invalid' : ''}`}
                                    placeholder='e.g. The product is broken'
                                    {...register('dispute_reason', {
                                        required: {
                                            value: true,
                                            message: 'Please enter your dispute reason',
                                        },
                                    })}
                                />
                                {errors.dispute_reason && (
                                    <span className='invalid-feedback'>{errors.dispute_reason?.message}</span>
                                )}
                            </div>
                            <div className='col-12'>
                                <label className='form-label'>What you're complaining about</label>
                                <textarea
                                    rows='7'
                                    className={`form-control ${errors.dispute_description ? 'is-invalid' : ''}`}
                                    placeholder='Please provide a breif about your dispute'
                                    name='dispute_description'
                                    {...register('dispute_description', {
                                        required: {
                                            value: true,
                                            message: 'Please provide a breif about your dispute',
                                        },
                                    })}
                                ></textarea>
                                {errors.dispute_description && (
                                    <span className='invalid-feedback'>{errors.dispute_description?.message}</span>
                                )}
                            </div>
                            <div className='col-12'>
                                <button className='btn btn-primary' type='submit'>
                                    Submit your dispute
                                </button>
                            </div>
                        </div>
                    </form>
                </Popup>
            )}
        </>
    );
}

export default DisputeFormModal;
