import React from 'react';

function UserPurchases() {
    return (
        <>
            <div className='row'>
                <div className='col-lg-6'>
                    <div>
                        <h2>It's Empty Here</h2>
                        <p className='text-muted'>There're no purchases at the moment.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserPurchases;
