import React, { useReducer } from 'react';
import { createSlug, calculateVendorRating } from '../helpers/utils';
import { readContract } from '@wagmi/core';
import { getContract } from '@wagmi/core';
import Web3 from 'web3';

import ShopContext from './shop-context';

const defaultShopState = {
    shopContract: null,
    shopContractAbi: null,
    paymentTokenContract: null,
    paymentToken: null,
    tokenBalance: 0,
    allShops: [],
    allProducts: [],
    productReviews: [],
    pendingProducts: [],
    totalProducts: [],
    userProducts: [],
    userShops: [],
    fetchingAllShops: true,
    fetchingAllProducts: true,
};

const shopReducer = (state, action) => {
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            shopContract: action.shopContract,
        };
    }
    if (action.type === 'GET_ABI') {
        return {
            ...state,
            shopContractAbi: action.shopContractAbi,
        };
    }
    if (action.type === 'GET_BALANCE') {
        return {
            ...state,
            tokenBalance: Number(action.tokenBalance),
        };
    }
    if (action.type === 'GET_ABI') {
        return {
            ...state,
            shopContractAbi: action.shopContractAbi,
        };
    }
    if (action.type === 'GETPAYMENTTOKEN') {
        return {
            ...state,
            paymentToken: action.paymentToken,
        };
    }

    if (action.type === 'GETPAYMENTTOKENCONTRACT') {
        return {
            ...state,
            paymentTokenContract: action.paymentTokenContract,
        };
    }
    if (action.type === 'LOAD_PRODUCT_REVIEWS') {
        return {
            ...state,
            productReviews: action.reviews?.map((rate) => {
                return {
                    raterName: rate[4][1][0],
                    raterProfile: rate[4][1][3],
                    raterAddress: rate[0],
                    rateReason: rate[2],
                    rateMsg: rate[3],
                    rate: Number(rate[1]) / 10,
                };
            }),
        };
    }
    if (action.type === 'LOAD_ALL_SHOPS') {
        return {
            ...state,
            fetchingAllShops: false,
            allShops: action.payload.allShops?.map((shop) => {
                return {
                    shopId: Number(shop[0]),
                    shopOwner: shop[1][0],
                    shopOwnerName: shop[1][1],
                    shopOwnerProfile: shop[1][2],
                    shopTitle: shop[2][0],
                    shopSlug: createSlug(shop[2][0]),
                    shopDescription: shop[2][1],
                    shopCategory: shop[2][2],
                    shopEmail: shop[2][3],
                    shopCover: shop[2][4],
                    shopLogo: shop[2][5],
                    shopIsBlocked: shop[3],
                    shopProducts: Number(shop[4]),
                    shopRating: calculateVendorRating(
                        action.payload?.allProducts?.map((product) => {
                            return {
                                productRating: Number(product[7]) / 10,
                            };
                        })
                    ),
                    shopDocs:
                        shop[5][0] !== ''
                            ? {
                                  companyName: shop[5][0],
                                  companyAddress: shop[5][1],
                                  phoneNumber: shop[5][2],
                                  email: shop[5][3],
                                  certificate: shop[5][4],
                                  twitterProfile: shop[5][5],
                              }
                            : [],

                    isVerified: shop[6],
                };
            }),
        };
    }
    if (action.type === 'LOAD_USER_SHOPS') {
        return {
            ...state,
            userShops: action.userShops?.map((shop) => {
                return {
                    shopId: Number(shop[0]),
                    shopOwner: shop[1][0],
                    shopOwnerName: shop[1][1],
                    shopOwnerProfile: shop[1][2],
                    shopTitle: shop[2][0],
                    shopSlug: createSlug(shop[2][0]),
                    shopDescription: shop[2][1],
                    shopCategory: shop[2][2],
                    shopEmail: shop[2][3],
                    shopCover: shop[2][4],
                    shopLogo: shop[2][5],
                    shopIsBlocked: shop[3],
                    shopProducts: Number(shop[4]),
                    shopRating: 0,
                    // shopRating: calculateVendorRating(
                    //     shop[4]?.map((product) => {
                    //         return {
                    //             productRating: Number(product[3]) / 10,
                    //         };
                    //     })
                    // ),
                    shopDocs:
                        shop[5][0] !== ''
                            ? {
                                  companyName: shop[5][0],
                                  companyAddress: shop[5][1],
                                  phoneNumber: shop[5][2],
                                  email: shop[5][3],
                                  certificate: shop[5][4],
                                  twitterProfile: shop[5][5],
                              }
                            : [],

                    isVerified: shop[6],
                };
            }),
        };
    }

    if (action.type === 'LOAD_ALL_PRODUCTS') {
        const products = action.allProducts?.map((product) => {
            return {
                productOwner: product[0],
                productId: Number(product[1]),
                productShopId: Number(product[2][0]),
                productShopName: product[2][1],
                productShopSlug: createSlug(product[2][1]),
                productShopLogo: product[2][2],
                productName: product[3][0],
                productSlug: createSlug(product[3][0]),
                productDescription: product[3][1],
                productCategory: product[3][2],
                productGallery: product[3][3],
                productInfo: product[3][4],
                productPrice: Web3.utils.fromWei(Number(product[3][5]).toString(), 'ether'),
                productWeight: Web3.utils.fromWei(Number(product[3][6]).toString(), 'ether'),
                productDimensions: product[3][7],
                productAvailability: Number(product[3][8]),
                returnPolicy: product[3][9],
                productCondition: product[3][10],
                productRating: Number(product[7]) / 10,
                isApproved: product[4],
                shippingPrice: Web3.utils.fromWei(product[5].toString(), 'ether'),
            };
        });
        return {
            ...state,
            allProducts: products.filter((product) => product?.isApproved),
            pendingProducts: products.filter((product) => !product?.isApproved),
            totalProducts: products,
            fetchingAllProducts: false,
        };
    }
    if (action.type === 'LOAD_USER_PRODUCTS') {
        return {
            ...state,
            userProducts: action.userProducts,
        };
    }

    return defaultShopState;
};

const ShopProvider = (props) => {
    const [shopState, dispatchShopAction] = useReducer(shopReducer, defaultShopState);

    const loadContractHandler = (shopContract) => {
        dispatchShopAction({ type: 'CONTRACT', shopContract: shopContract });
        return shopContract;
    };

    const getShopContractAbiHandler = (abi) => {
        dispatchShopAction({ type: 'GET_ABI', shopContractAbi: abi });
    };

    const loadPaymentTokenAddressHandler = async (contract) => {
        const paymentToken = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: '_token',
        });
        dispatchShopAction({ type: 'GETPAYMENTTOKEN', paymentToken: paymentToken });
        return paymentToken;
    };

    const loadPaymentTokenContractHandler = (token, abi) => {
        const paymentTokenContract = getContract({
            address: token,
            abi: abi,
        });

        dispatchShopAction({ type: 'GETPAYMENTTOKENCONTRACT', paymentTokenContract: paymentTokenContract });
        return paymentTokenContract;
    };

    const loadPaymentTokenBalanceHandler = async (token, abi, address) => {
        const tokenBalance = await readContract({
            address: token,
            abi: abi,
            functionName: 'balanceOf',
            overrides: address,
        });

        dispatchShopAction({ type: 'GET_BALANCE', tokenBalance: tokenBalance });
        return tokenBalance;
    };

    const loadAllShopsHandler = async (contract) => {
        const allShops = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: 'getAllShops',
        });

        const allProducts = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: 'getAllProducts',
        });

        dispatchShopAction({ type: 'LOAD_ALL_SHOPS', payload: { allShops, allProducts } });
        return { allShops, allProducts };
    };

    const loadAllProductsHandler = async (contract) => {
        const allProducts = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: 'getAllProducts',
        });

        dispatchShopAction({ type: 'LOAD_ALL_PRODUCTS', allProducts: allProducts });
        return allProducts;
    };

    const loadUserProductsHanlder = async (contract) => {
        const userProducts = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: 'getUserProducts',
        });

        dispatchShopAction({ type: 'LOAD_USER_PRODUCTS', userProducts: userProducts });
        return userProducts;
    };

    const loadUserShopsHandler = async (contract, address) => {
        const userShops = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: 'getShopsForUser',
            overrides: address,
        });
        dispatchShopAction({ type: 'LOAD_USER_SHOPS', userShops: userShops });
        return userShops;
    };

    const loadProductReviewsHandler = async (contract, productId) => {
        const reviews = await readContract({
            address: contract.address,
            abi: shopState.shopContractAbi,
            functionName: 'getProductRates',
            overrides: productId,
        });

        dispatchShopAction({ type: 'LOAD_PRODUCT_REVIEWS', reviews: reviews });
        return reviews;
    };

    const shopContext = {
        shopContract: shopState.shopContract,
        shopContractAbi: shopState.shopContractAbi,
        paymentTokenContract: shopState.paymentTokenContract,
        paymentToken: shopState.paymentToken,
        tokenBalance: shopState.tokenBalance,
        allShops: shopState.allShops,
        allProducts: shopState.allProducts,
        pendingProducts: shopState.pendingProducts,
        productReviews: shopState.productReviews,
        totalProducts: shopState.totalProducts,
        userShops: shopState.userShops,
        userProducts: shopState.userProducts,
        fetchingAllShops: shopState.fetchingAllShops,
        fetchingAllProducts: shopState.fetchingAllProducts,
        loadShopContract: loadContractHandler,
        getShopContractAbi: getShopContractAbiHandler,
        loadAllShops: loadAllShopsHandler,
        loadUserShops: loadUserShopsHandler,
        loadAllProducts: loadAllProductsHandler,
        loadUserProducts: loadUserProductsHanlder,
        loadProductReviews: loadProductReviewsHandler,
        loadPaymentTokenAddress: loadPaymentTokenAddressHandler,
        loadPaymentTokenContract: loadPaymentTokenContractHandler,
        loadPaymentTokenBalance: loadPaymentTokenBalanceHandler,
    };

    return <ShopContext.Provider value={shopContext}>{props.children}</ShopContext.Provider>;
};

export default ShopProvider;
