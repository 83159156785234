import React from 'react';
import { Link } from 'react-router-dom';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { BiCategoryAlt } from 'react-icons/bi';
import { BsFillFolderSymlinkFill } from 'react-icons/bs';
import ReadOnlyRating from './ReadOnlyRating';
import { appSettings } from '../../helpers/settings';
import { truncateStart } from '../../helpers/utils';

function ProductCard({
    productName,
    productSlug,
    productGallery,
    productCategory,
    productPrice,
    productRating,
    productAvailability,
}) {
    return (
        <>
            <div className={`card mb-0 shop-card ${Number(productAvailability) === 0 ? 'sold' : ''}`}>
                <div className='card-body p-3'>
                    <div className='product-image' style={{ background: `url(${productGallery[0]})` }}></div>
                    <h2 className='h4'>
                        <Link to={`/products/${productSlug}`} className='text-reset'>
                            {productName}
                        </Link>
                    </h2>
                    <div className='mt-2 mb-4'>
                        <ReadOnlyRating rating={productRating} />
                    </div>
                    <ul
                        className='d-flex align-items-center list-inline mb-3 text-sm text-muted p-3 bg-gray-850'
                        style={{ borderRadius: '0.3rem' }}
                    >
                        <li className='d-flex align-items-center me-3'>
                            <RiMoneyDollarCircleFill className='text-primary me-1' size='1.2rem' />
                            <strong className='fw-bold text-white me-1'>{productPrice}</strong> {appSettings.currency}
                        </li>
                        <li className='me-3'>|</li>
                        <li className='d-flex align-items-center me-3 text-nowrap'>
                            <BiCategoryAlt className='text-primary me-1' size='1.2rem' />
                            <strong className='fw-bold text-white me-1'>{truncateStart(productCategory, 12)}</strong>
                        </li>
                    </ul>

                    <Link to={`/products/${productSlug}`} className='btn btn-primary btn-sm w-100'>
                        <BsFillFolderSymlinkFill className='me-1 mb-1' size={20} />
                        View Details
                    </Link>
                </div>
            </div>
        </>
    );
}

export default ProductCard;
