import React, { useState } from 'react';
import { toast } from 'react-toastify';

// Hooks
import useShop from '../../hooks/useShop';
// import useWeb3 from '../../hooks/useWeb3';
import useApp from '../../hooks/useApp';

// COMPONENTS
import PageBanner from '../../components/general/PageBanner';
import PendingProductsTable from './PendingProductsTable';
import AllProductsTable from './AllProductsTable';
import AllShopsTable from './AllShopsTable';
import AllUsersTable from './AllUsersTable';
import AllSellersTable from './AllSellersTable';
import ShopVerifyRequestsTable from './ShopVerifyRequestsTable';
import Popup from '../../components/general/Popup';
import AllOrdersTable from './AllOrdersTable';
import AllOpenDisputes from './AllOpenDisputes';
import AllReslovedDisputes from './AllReslovedDisputes';
import { useContractWrite } from 'wagmi';
import TransferOwnership from './TransferOwnership';

function AdminPage() {
    const { contract, setTransactionLoading } = useApp();
    const { shopContractAbi, loadAllProducts, loadAllShops } = useShop();
    const [pendingProductsIds, setPendingProductsIds] = useState([]);
    const [blockedProductsIds, setBlockedProductsIds] = useState([]);
    const [blockedShopsIds, setBlockedShopsIds] = useState([]);

    const [docsRequested, setDocsRequested] = useState(false);
    const [targetDocs, setTargetDocs] = useState(null);

    function handleViewDocs(docs) {
        setDocsRequested(true);
        setTargetDocs(docs);
    }

    /* --------------------------------------------- 
              APRROVE PRODUCT FUNCTION
     --------------------------------------------- */
    const { write: web3ApproveProduct } = useContractWrite({
        address: contract?.address,
        abi: shopContractAbi,
        functionName: 'approveProducts',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllProducts(contract);
                setPendingProductsIds([]);
                toast.success('Great! You have approved selected products');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /*** --- APPROVE PRODUCT TRIGGER --- */
    function approveProductsHandler() {
        web3ApproveProduct({
            recklesslySetUnpreparedArgs: [pendingProductsIds],
        });
    }

    /* --------------------------------------------- 
              BLOCK PRODUCT FUNCTION
     --------------------------------------------- */
    const { write: web3BlockProduct } = useContractWrite({
        address: contract?.address,
        abi: shopContractAbi,
        functionName: 'blockProducts',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllProducts(contract);
                setBlockedProductsIds([]);
                toast.success('Great! You have blocked selected products');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /*** --- BLOCK PRODUCT TRIGGER --- */
    function blockProductsHandler() {
        web3BlockProduct({
            recklesslySetUnpreparedArgs: [blockedProductsIds],
        });
    }

    /* --------------------------------------------- 
              BLOCK SHOP FUNCTION
     --------------------------------------------- */
    const { write: web3BlockShop } = useContractWrite({
        address: contract?.address,
        abi: shopContractAbi,
        functionName: 'blockShop',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllShops(contract);
                setBlockedShopsIds([]);
                toast.success('Great! You have blocked selected shop');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /*** --- BLOCK SHOP TRIGGER ---- */
    function blockShopHandler() {
        web3BlockShop({
            recklesslySetUnpreparedArgs: [blockedShopsIds],
        });
    }

    /* --------------------------------------------- 
              VERIFY SHOP FUNCTION
     --------------------------------------------- */
    const { write: web3VerifyShop } = useContractWrite({
        address: contract?.address,
        abi: shopContractAbi,
        functionName: 'verifyShop',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllShops(contract);
                toast.success('Great! You have verified selected shop');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /*** ----- VERIFY SHOPS TRIGGER ---- */
    function verifyShopsHandler(id) {
        web3VerifyShop({
            recklesslySetUnpreparedArgs: [id],
        });
    }

    return (
        <>
            <PageBanner
                heading='Admin Panel'
                text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, similique pariatur et corporis cum vero minus exercitationem veritatis.'
            ></PageBanner>
            <section className='py-5'>
                <div className='container py-5'>
                    <div className='mb-5'>
                        <PendingProductsTable setIds={setPendingProductsIds} />
                        {pendingProductsIds.length > 0 && (
                            <button className='btn-primary btn w-100 mt-3' onClick={approveProductsHandler}>
                                Approve Selected
                            </button>
                        )}
                    </div>
                    <div className='mb-5'>
                        <TransferOwnership />
                    </div>
                    <div className='mb-5'>
                        <ShopVerifyRequestsTable verify={verifyShopsHandler} handleViewDocs={handleViewDocs} />
                    </div>
                    <div className='mb-5'>
                        <AllProductsTable setIds={setBlockedProductsIds} />
                        {blockedProductsIds.length > 0 && (
                            <button className='btn-primary btn w-100 mt-3' onClick={blockProductsHandler}>
                                Block Selected
                            </button>
                        )}
                    </div>
                    <div className='mb-5'>
                        <AllShopsTable setIds={setBlockedShopsIds} />
                        {blockedShopsIds.length > 0 && (
                            <button className='btn-primary btn w-100 mt-3' onClick={blockShopHandler}>
                                Block Selected
                            </button>
                        )}
                    </div>
                    <div className='mb-5'>
                        <AllOrdersTable />
                    </div>
                    <div className='mb-5'>
                        <AllUsersTable />
                    </div>
                    <div className='mb-5'>
                        <AllSellersTable />
                    </div>
                    <div className='mb-5'>
                        <AllOpenDisputes />
                    </div>
                    <div className='mb-5'>
                        <AllReslovedDisputes />
                    </div>
                </div>
            </section>

            {docsRequested && (
                <Popup closeModal={() => setDocsRequested(false)}>
                    <header className='mb-4 text-center'>
                        <h2 className='h1'>Shop Verification Documents</h2>
                    </header>
                    <div className='row g-2'>
                        <div className='col-lg-6'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-1'>Company Name</h6>
                                <p className='mb-0 text-sm'>{targetDocs?.companyName}</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-1'>Company Address</h6>
                                <p className='mb-0 text-sm'>{targetDocs?.companyAddress}</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-1'>Phone Number</h6>
                                <p className='mb-0 text-sm'>{targetDocs?.phoneNumber}</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-1'>Email Adderss</h6>
                                <p className='mb-0 text-sm'>{targetDocs?.email}</p>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-1'>Twitter Profile</h6>
                                <p className='mb-0 text-sm'>
                                    <a href={`${targetDocs?.twitterProfile}`} target='_blank' rel='noopener noreferrer'>
                                        {targetDocs?.twitterProfile}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-1'>Company Issue Certificate</h6>
                                <p className='mb-0 text-sm'>
                                    <a href={`${targetDocs?.certificate}`} target='_blank' rel='noopener noreferrer'>
                                        {targetDocs?.certificate}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </Popup>
            )}
        </>
    );
}

export default AdminPage;
